"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as UploadIcon } from "@/images/icons/Upload.svg";
import { ReactComponent as Note } from "@/images/side-bar-icons/MistakeOrange.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ImportIncomeDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/import-income-drawer.component";
import { PayrunButtons } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-buttons.component";
import { PayrunDeductionsTable } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-deductions-table.component";
import { PayrunEmployerCostTable } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-employer-cost-table.component";
import { PayrunIncomeTable } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-income-table.component";
import { PayrunSalaryTabs } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-salary-tabs.component";
import { UKPayrunSummaryTable } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-summary-table.component";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const PayrunSalaryTabsLabels = ["Summary", "Income", "Deductions", "Employer cost"];
export const UKPayrunSalariesSection = ({
  entries,
  previousEntries,
  hideButtons,
  hideTitle,
  localPayRun,
  onBack,
  onNext,
  refreshPayrun
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [tab, setTab] = useState("Summary");
  const [importIncomeDrawerOpen, setImportIncomeDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const payrunClosed = localPayRun.state !== PayrunStates.draft;
  const { data: payCodes } = useApiClient(PayrollLocalEndpoints.getUKPayrunPayCodes(localPayRun.id), {
    suspense: false
  });
  const { data: rawPayrollList } = useApiClient(PayrollLocalEndpoints.getUserPayrollMembershipList(), {
    suspense: false
  });
  const { data: attendanceEntriesWithoutPaycode } = useApiClient(
    PayrollLocalEndpoints.getUKPayrunAttendanceEntriesWithoutPaycode(localPayRun.id),
    {
      suspense: false
    }
  );
  const employeeList = useMemo(() => {
    var _a;
    return (_a = rawPayrollList == null ? void 0 : rawPayrollList.all) != null ? _a : [];
  }, [rawPayrollList == null ? void 0 : rawPayrollList.all]);
  const [filteredPayrunEntries, filteredPreviousPayrunEntries] = useMemo(() => {
    const query = searchInput.trim().toLocaleLowerCase();
    const filterFn = (entry) => {
      var _a, _b;
      return (_b = (_a = entry == null ? void 0 : entry.employee) == null ? void 0 : _a.name) == null ? void 0 : _b.toLowerCase().includes(query);
    };
    return query ? [entries.filter(filterFn), previousEntries.filter(filterFn)] : [entries, previousEntries];
  }, [entries, previousEntries, searchInput]);
  const saveIncomeUpdates = useCallback(
    (incomeUpdates) => __async(void 0, null, function* () {
      let success = false;
      try {
        const { payrollId, taxYear, payPeriod, period } = localPayRun;
        success = (yield PayrollExternalApi.updateIncome(payrollId, taxYear, payPeriod, period, incomeUpdates), true);
        yield refreshPayrun == null ? void 0 : refreshPayrun();
        showMessage("Payrun updated.", "success");
      } catch (error) {
        showMessage(`Payrun update failed. ${nestErrorMessage(error)}`, "error");
      }
      return success;
    }),
    [localPayRun, refreshPayrun, showMessage]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    !hideTitle && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mr: spacing.mr40, color: themeColors.DarkGrey }), children: "Review and edit pay for employees" }),
      /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", alignItems: "center", sx: { gap: spacing.g5, mt: spacing.m10, mr: spacing.mr40 }, children: [
        /* @__PURE__ */ jsx(Note, { style: { width: 14, height: 14 } }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: 'Please make sure all salaries are correct before clicking "Continue"' })
      ] }),
      attendanceEntriesWithoutPaycode && attendanceEntriesWithoutPaycode.length > 0 && /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", alignItems: "center", sx: { gap: spacing.g5, mt: spacing.m10, mr: spacing.mr40 }, children: [
        /* @__PURE__ */ jsx(Note, { style: { width: 14, height: 14 } }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: `This payrun contains ${attendanceEntriesWithoutPaycode.length} entries with attendance jobs without paycode` })
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      PayrunSalaryTabs,
      {
        tabs: PayrunSalaryTabsLabels,
        currentTab: tab,
        onChange: (tab2) => setTab(tab2),
        sx: { mt: spacing.mt40 }
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", pr: spacing.pr40, my: spacing.my20, justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => setSearchInput(e.target.value) }),
      (tab === "Income" || tab === "Deductions") && !payrunClosed && /* @__PURE__ */ jsx(
        Button,
        {
          sx: secondaryCTABtn,
          startIcon: /* @__PURE__ */ jsx(UploadIcon, __spreadValues({}, iconSize)),
          onClick: () => setImportIncomeDrawerOpen(true),
          children: "Import"
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { flex: 1, overflow: "auto" }, children: [
      tab === "Summary" && /* @__PURE__ */ jsx(
        UKPayrunSummaryTable,
        {
          localPayRun,
          entries: filteredPayrunEntries,
          previousEntries: filteredPreviousPayrunEntries
        }
      ),
      tab === "Income" && /* @__PURE__ */ jsx(
        PayrunIncomeTable,
        {
          payCodes,
          entries: filteredPayrunEntries,
          previousEntries: filteredPreviousPayrunEntries,
          payrunClosed,
          saveIncomeUpdates,
          employeeList
        }
      ),
      tab === "Deductions" && /* @__PURE__ */ jsx(
        PayrunDeductionsTable,
        {
          payCodes,
          entries: filteredPayrunEntries,
          previousEntries: filteredPreviousPayrunEntries,
          payrunClosed,
          saveIncomeUpdates
        }
      ),
      tab === "Employer cost" && /* @__PURE__ */ jsx(
        PayrunEmployerCostTable,
        {
          payCodes,
          entries: filteredPayrunEntries,
          previousEntries: filteredPreviousPayrunEntries,
          payrunClosed,
          saveIncomeUpdates
        }
      )
    ] }),
    !hideButtons && /* @__PURE__ */ jsx(
      PayrunButtons,
      {
        primaryButtonLabel: "Continue",
        showBack: true,
        onBackClick: onBack,
        onPrimaryClick: onNext,
        sx: { mt: spacing.m10, mx: spacing.mx40, mb: spacing.m30, justifyContent: "flex-end" }
      }
    ),
    /* @__PURE__ */ jsx(
      ImportIncomeDrawer,
      {
        isOpen: importIncomeDrawerOpen,
        setIsOpen: setImportIncomeDrawerOpen,
        onClose: () => setImportIncomeDrawerOpen(false),
        entries,
        payCodes,
        saveIncomeUpdates
      }
    )
  ] });
};
