"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext, useMemo } from "react";
import { Redirect, Switch, generatePath, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import {
  REVIEWS_TEAM_ONGOING_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_FORM_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_GENERAL_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_VISIBILITY_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { RCUpsertGeneralPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-general/rc-upsert-general.page";
import { RCUpsertLaunchPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/rc-upsert-launch.page";
import { RCUpsertParticipantsPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/rc-upsert-participants.page";
import { RCUpsertQuestionPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/rc-upsert-questions.page";
import { RCUpsertTimelinePage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/rc-upsert-timeline.page";
import { ReviewCycleCreationNotificationsPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-notifications/review-cycle-creation-notifications.page";
import { ReviewCycleCreationVisibilitySettingsPage } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-visibility-settings/review-cycle-creation-visibility-settings.page";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const getPageConfig = (cycleId, reviewCycle, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("CycleSettingsRouter.general"),
          stub: "general",
          path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, { cycleId })
        },
        {
          title: "Questions",
          stub: "question",
          path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_FORM_ROUTE, { cycleId })
        },
        {
          title: "Participants",
          stub: "participants",
          path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE, { cycleId })
        },
        {
          title: "Visibility",
          stub: "visibility",
          path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_VISIBILITY_ROUTE, { cycleId })
        },
        {
          title: "Timeline",
          stub: "timeline",
          path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE, { cycleId })
        },
        {
          title: "Notifications",
          stub: "notifications",
          path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE, { cycleId })
        },
        ...(reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Draft || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Scheduled ? [
          {
            title: "Start review cycle",
            stub: "launch",
            path: generatePath(REVIEWS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE, { cycleId })
          }
        ] : []
      ]
    }
  ];
};
export const ReviewCycleCreationTeamRouter = () => {
  const { polyglot } = usePolyglot();
  const params = useParams();
  const cycleId = params.cycleId;
  const { data: reviewCycle, mutate: refreshReviewCycle, isLoading: cycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, ReachType.Team),
    {
      suspense: false
    }
  );
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const settingPathVisibility = useMemo(
    () => reviewCycle && Boolean(isAdmin || isManager && reviewCycle.owner === user.userId),
    [reviewCycle, isManager, isAdmin, user]
  );
  const refresh = () => __async(void 0, null, function* () {
    yield refreshReviewCycle == null ? void 0 : refreshReviewCycle();
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Reviews",
        pageConfig: getPageConfig(cycleId, reviewCycle, polyglot),
        backPath: REVIEWS_TEAM_ONGOING_ROUTE,
        showBack: true,
        type: "Domain"
      }
    ),
    settingPathVisibility ? /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        RCUpsertGeneralPage,
        {
          reviewCycle,
          refresh,
          cycleLoading,
          reach: ReachType.Team
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_FORM_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertQuestionPage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertParticipantsPage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_VISIBILITY_ROUTE, children: /* @__PURE__ */ jsx(ReviewCycleCreationVisibilitySettingsPage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertTimelinePage, { cycleId, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE, children: /* @__PURE__ */ jsx(ReviewCycleCreationNotificationsPage, { reviewCycle, refresh, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews:manager"], path: REVIEWS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE, children: /* @__PURE__ */ jsx(RCUpsertLaunchPage, { cycleId, reviewCycle, reach: ReachType.Team }) }),
      /* @__PURE__ */ jsx(Redirect, { to: REVIEWS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, exact: true })
    ] }) : /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) })
  ] });
};
