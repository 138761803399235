"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as DeleteIcon } from "@/images/side-bar-icons/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrunUserHeader } from "@/v2/feature/payroll/components/payrun-user-header.component";
import { GlobalPayLineTypeMenu } from "@/v2/feature/payroll/features/payroll-global/components/global-payline-type-menu.component";
import { GlobalPayrollEndpoints } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from "@/v2/feature/payroll/payroll-external.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { setFocusToInput } from "@/v2/util/element.util";
const EditPayrunEntry = ({ user, payrunEntry, payrollId, payrunClosed, savePaylineUpdates }) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const [focusedTextEditId, setFocusedTextEditId] = useState("");
  const [orderedPaylines, setOrderedPaylines] = useState([]);
  const [paylinesToAdd, setPaylinesToAdd] = useState(/* @__PURE__ */ new Set());
  const [paylinesToOverride, setPaylinesToOverride] = useState(/* @__PURE__ */ new Set());
  const [paylinesToDelete, setPaylinesToDelete] = useState(/* @__PURE__ */ new Set());
  const { data: payrollPaycodes } = useApiClient(GlobalPayrollEndpoints.getPayrollPaycodes(payrollId), {
    suspense: false
  });
  const paycodesByCode = useMemo(() => {
    return new Map(payrollPaycodes == null ? void 0 : payrollPaycodes.paycodes.map((p) => [p.code, p]));
  }, [payrollPaycodes == null ? void 0 : payrollPaycodes.paycodes]);
  useEffect(() => {
    if (paycodesByCode.size === 0) return;
    const sortedPaylines = [...payrunEntry.paylines].sort(
      (a, b) => {
        var _a, _b, _c, _d;
        return ((_b = (_a = paycodesByCode.get(a.code)) == null ? void 0 : _a.order) != null ? _b : 0) - ((_d = (_c = paycodesByCode.get(b.code)) == null ? void 0 : _c.order) != null ? _d : 0);
      }
    );
    setOrderedPaylines(sortedPaylines);
    const paylinesToOverride2 = sortedPaylines.filter((p) => p.isOverriden).map((p) => p.id);
    setPaylinesToOverride((overriden) => /* @__PURE__ */ new Set([...overriden, ...paylinesToOverride2]));
  }, [paycodesByCode, payrunEntry.paylines]);
  useEffect(() => {
    if (!focusedTextEditId) return;
    setFocusToInput(focusedTextEditId);
  }, [focusedTextEditId]);
  const updatePayline = useCallback(
    (id, values) => {
      if (values.isOverridable) {
        setPaylinesToOverride((overriden) => /* @__PURE__ */ new Set([...overriden, id]));
      } else {
        setPaylinesToAdd((added) => /* @__PURE__ */ new Set([...added, id]));
      }
      setPaylinesToDelete((removed) => /* @__PURE__ */ new Set([...removed, id]));
      setOrderedPaylines((paylines) => {
        const idx = paylines.findIndex((payline) => payline.id === id);
        paylines[idx] = __spreadValues(__spreadValues({}, paylines[idx]), values);
        return [...paylines];
      });
    },
    []
  );
  const addPayline = useCallback(
    ({ code, name }) => {
      const id = uuidv4();
      setOrderedPaylines((paylines) => {
        let idx = paylines.findIndex((p, idx2, arr) => {
          var _a;
          return p.code !== code && ((_a = arr[idx2 - 1]) == null ? void 0 : _a.code) === code;
        });
        if (idx < 0) idx = paylines.length;
        const newPayline = {
          id,
          userId: user.userId,
          amount: 0,
          code,
          description: name,
          calculated: false
        };
        const result = [...paylines];
        result.splice(idx, 0, newPayline);
        return result;
      });
      setPaylinesToAdd((ids) => /* @__PURE__ */ new Set([...ids, id]));
      return id;
    },
    [user.userId]
  );
  const removePayline = useCallback(
    (id) => {
      const payline = orderedPaylines.find((p) => p.id === id);
      if (!payline) return;
      if (payline.calculated) return;
      setPaylinesToAdd((added) => new Set([...added].filter((id2) => id2 !== payline.id)));
      setPaylinesToDelete((removed) => /* @__PURE__ */ new Set([...removed, id]));
      setOrderedPaylines(orderedPaylines.filter((p) => p !== payline));
    },
    [orderedPaylines]
  );
  const savePaylines = useCallback(
    (keepOpen) => () => __async(void 0, null, function* () {
      setSavingUpdate(true);
      try {
        yield savePaylineUpdates(
          {
            added: orderedPaylines.filter((p) => paylinesToAdd.has(p.id)),
            deleted: [...paylinesToDelete],
            overriden: orderedPaylines.filter((p) => paylinesToOverride.has(p.id))
          },
          keepOpen
        );
      } catch (e) {
        setSavingUpdate(false);
      } finally {
        setSavingUpdate(false);
      }
    }),
    [orderedPaylines, paylinesToAdd, paylinesToDelete, paylinesToOverride, savePaylineUpdates]
  );
  const resetOverriden = useCallback(
    (idToRemove) => __async(void 0, null, function* () {
      setPaylinesToOverride((overriden) => new Set([...overriden].filter((id) => id !== idToRemove)));
      setSavingUpdate(true);
      yield savePaylineUpdates(
        {
          added: orderedPaylines.filter((p) => paylinesToAdd.has(p.id)),
          deleted: [...paylinesToDelete],
          overriden: orderedPaylines.filter((p) => paylinesToOverride.has(p.id) && p.id !== idToRemove)
        },
        true
      );
      setSavingUpdate(false);
    }),
    [paylinesToOverride, orderedPaylines, paylinesToAdd, paylinesToDelete, savePaylineUpdates]
  );
  const isRequiredPayline = useCallback(
    (payline) => {
      var _a;
      return !!((_a = paycodesByCode.get(payline.code)) == null ? void 0 : _a.required);
    },
    [paycodesByCode]
  );
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit pay" }),
    /* @__PURE__ */ jsx(PayrunUserHeader, { user, sx: { mt: spacing.m10 } }),
    /* @__PURE__ */ jsx(Stack, { flex: 1, sx: { gap: spacing.g20, mt: spacing.m30, overflowY: "auto" }, children: orderedPaylines.map((payline) => {
      const { id, code, description, amount } = payline;
      return /* @__PURE__ */ jsxs(React.Fragment, { children: [
        payline.calculated && !payline.isOverridable && /* @__PURE__ */ jsxs(Stack, { children: [
          /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("GlobalPayroll.calculatedFieldsCannotBeEdited"), children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: description }) }) }),
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              id,
              name: id,
              label: "",
              value: amount,
              disabled: true,
              readonly: true,
              emptyIsZero: true,
              allowNegative: true,
              sx: { mt: spacing.m5 }
            }
          )
        ] }),
        (!payline.calculated || payline.isOverridable) && /* @__PURE__ */ jsxs(Stack, { children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: code }),
            (!isRequiredPayline(payline) || !payline.calculated) && !payline.isOverridable && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => removePayline(id), children: /* @__PURE__ */ jsx(DeleteIcon, __spreadValues({}, iconSize)) }),
            payline.isOverriden && /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => resetOverriden(id), children: polyglot.t("General.reset") })
          ] }),
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              id,
              name: id,
              label: "Amount",
              value: amount,
              onChange: (amount2) => typeof amount2 === "number" && updatePayline(id, { amount: amount2, description, isOverridable: payline.isOverridable }),
              disabled: savingUpdate || payrunClosed,
              emptyIsZero: true,
              clearToZero: true,
              allowNegative: true,
              sx: { mt: spacing.m5 }
            }
          ),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: `${id}-description`,
              label: "Description",
              value: description,
              maxLength: MAX_PAYLINE_DESCRIPTION_LENGTH,
              onChange: (e) => updatePayline(id, { amount, description: e.target.value, isOverridable: payline.isOverridable }),
              clearText: () => updatePayline(id, { amount, description: "", isOverridable: payline.isOverridable }),
              disabled: savingUpdate || payrunClosed,
              sx: { mt: spacing.m5 }
            }
          )
        ] })
      ] }, id);
    }) }),
    payrunClosed && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m30 }, children: "Pay items cannot be changed because the payrun is closed." }),
    !payrunClosed && payrollPaycodes && /* @__PURE__ */ jsx(
      GlobalPayLineTypeMenu,
      {
        payCodes: payrollPaycodes.paycodes,
        disabled: savingUpdate,
        onMenuItemClick: (payCode) => {
          const newEntryId = addPayline(payCode);
          setFocusedTextEditId(newEntryId);
        },
        sx: { mt: spacing.m30 }
      }
    ),
    !payrunClosed && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        loading: savingUpdate,
        onClick: savePaylines(false),
        name: "Save",
        type: "button",
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: spacing.m30 }
      }
    ) })
  ] });
};
export const EditPayrunEntryDrawer = ({
  onClose = () => {
  },
  payrollId,
  payrunEntry,
  payrunClosed,
  savePaylineUpdates
}) => {
  const { getCachedUserById } = useCachedUsers();
  const user = payrunEntry && getCachedUserById(payrunEntry.userId);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: !!user, setIsOpen: () => {
  }, onClose, children: user && payrunEntry ? /* @__PURE__ */ jsx(
    EditPayrunEntry,
    {
      payrollId,
      user,
      payrunEntry,
      payrunClosed,
      savePaylineUpdates: (update, keepOpen) => __async(void 0, null, function* () {
        const result = yield savePaylineUpdates(update);
        if (result && !keepOpen) setImmediate(onClose);
        return result;
      })
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
