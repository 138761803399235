"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, LinearProgress } from "@mui/material";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ChartCategoryBarCount = ({
  title,
  subtitle,
  data,
  loading,
  emptyMessage,
  sx = {},
  titleVariant = "title3",
  subtitleVariant = "caption",
  badgeColorVariant = "Background",
  progressColorVariant = "ZeltYellow"
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        p: spacing.s2,
        backgroundColor: themeColors[badgeColorVariant],
        minHeight: "11.25rem",
        boxSizing: "border-box",
        borderRadius: radius.br12,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: spacing.s2
      }, sx),
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.sm
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: titleVariant, children: title }),
              /* @__PURE__ */ jsx(Typography, { variant: subtitleVariant, children: subtitle })
            ]
          }
        ),
        /* @__PURE__ */ jsx(Box, { children: loading ? /* @__PURE__ */ jsx(ChartLoader, {}) : data.length === 0 ? /* @__PURE__ */ jsx(EmptyState, { message: emptyMessage }) : /* @__PURE__ */ jsx(ChartDataList, { data, progressColorVariant }) })
      ]
    }
  );
};
const ChartLoader = () => {
  return /* @__PURE__ */ jsx(Fragment, { children: [0, 1, 2, 3].map((_, idx) => /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: spacing.s2,
        width: "100%",
        marginBottom: spacing.sm
      },
      children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: {
              flex: "0 0 145px",
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            },
            children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", sx: { background: themeColors.TableHover } })
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { flex: "1", display: "flex", alignItems: "center", gap: spacing.s2 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { flex: "0 0 3rem", textAlign: "right" }, children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", sx: { background: themeColors.TableHover } }) }),
          /* @__PURE__ */ jsx(
            LinearProgress,
            {
              variant: "indeterminate",
              sx: {
                flex: "1",
                height: "8px",
                backgroundColor: themeColors.TableHover,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: themeColors.ZeltYellow
                }
              }
            }
          )
        ] })
      ]
    },
    `chart-${idx}`
  )) });
};
const EmptyState = ({ message }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "6rem",
        color: themeColors.DarkGrey,
        textAlign: "center"
      },
      children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: message != null ? message : polyglot.t("ChartCategoryBarCount.emptyState") })
    }
  );
};
const ChartDataList = ({
  data,
  progressColorVariant
}) => {
  return /* @__PURE__ */ jsx(Fragment, { children: data.map((item, idx) => /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: spacing.s2,
        width: "100%",
        marginBottom: spacing.sm
      },
      children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: {
              flex: "0 0 145px",
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            },
            children: item.category
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { flex: "1", display: "flex", alignItems: "center", gap: spacing.s2 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { flex: "0 0 3rem", textAlign: "right" }, children: item.count }),
          /* @__PURE__ */ jsx(StyledTooltip, { title: `${item.progress}%`, children: /* @__PURE__ */ jsx(
            LinearProgress,
            {
              variant: "determinate",
              value: item.progress,
              sx: {
                flex: "1",
                height: "8px",
                backgroundColor: themeColors.white,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: themeColors[progressColorVariant]
                }
              }
            }
          ) })
        ] })
      ]
    },
    `chart-${idx}`
  )) });
};
