"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { sortDate, sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { AppIntegrationAPI } from "@v2/feature/app-integration/app-integration.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { TableColumn } from "@/v2/feature/super-admin/components/helper/table-helper";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SuperAdminAppsPage = () => {
  const [apps, setApps] = useState([]);
  const [isLoadingApps, setIsLoadingApps] = useState(false);
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const fetchApps = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setIsLoadingApps(true);
        const { apps: apps2, pagination: paginationInfo } = yield AppIntegrationAPI.getInstalledAppsAsSuperadmin(params);
        setApps(apps2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingApps(false);
      } catch (error) {
        showMessage(`Something went wrong fetching users. ${nestErrorMessage(error)}`, "error");
        setIsLoadingApps(false);
      }
    }),
    [showMessage]
  );
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield fetchApps({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery
      });
    }))();
  }, [pageIndex, searchQuery, fetchApps]);
  const appsColumn = useMemo(
    () => [
      new TableColumn().define({
        header: "Company ID",
        id: "companyId",
        size: 140,
        fieldName: "companyId",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.companyId)
      }),
      new TableColumn().define({
        header: "Company name",
        id: "company.name",
        size: 140,
        fieldName: "company.name",
        parseRow: (row) => {
          var _a, _b;
          return (_b = (_a = row.company) == null ? void 0 : _a.name) != null ? _b : "-";
        },
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.company) == null ? void 0 : _a.name;
        })
      }),
      new TableColumn().define({
        header: "App",
        id: "app.appId",
        size: 140,
        fieldName: "app.appId",
        parseRow: (row) => {
          var _a, _b;
          return (_b = (_a = row.app) == null ? void 0 : _a.name) != null ? _b : "-";
        },
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.app) == null ? void 0 : _a.name;
        })
      }),
      new TableColumn().define({
        header: "Date Created",
        id: "createdAt",
        size: 140,
        fieldName: "createdAt",
        parseRow: (row) => `${row.createdAt ? new Date(row.createdAt).toLocaleString().split(",")[0] : "-"}`,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt)
      }),
      new TableColumn().define({
        header: "App Owner",
        id: "appOwner.userId",
        size: 140,
        fieldName: "appOwner.userId",
        parseRow: (row) => `${(row == null ? void 0 : row.appOwner) && row.appOwner.firstName && row.appOwner.lastName ? `${row.appOwner.firstName} ${row.appOwner.lastName}` : "-"} (${row.appOwnerId})`,
        enableSorting: true,
        sortingFn: (a, b) => sortString(
          a,
          b,
          (item) => `${(item == null ? void 0 : item.appOwner) && item.appOwner.firstName && item.appOwner.lastName ? `${item.appOwner.firstName} ${item.appOwner.lastName}` : "-"} (${item.appOwnerId})`
        )
      }),
      new TableColumn().define({
        header: "Invalidated At",
        id: "invalidatedAt",
        size: 140,
        fieldName: "invalidatedAt",
        parseRow: (row) => `${row.invalidatedAt ? new Date(row.invalidatedAt).toLocaleString().split(",")[0] : "-"}`,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.invalidatedAt)
      })
    ],
    []
  );
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Apps" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoadingApps, children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({
            display: "flex",
            justifyContent: "flex-start",
            width: "100%"
          }, spacing.mb20), {
            gap: "5px",
            alignItems: "center"
          }),
          children: /* @__PURE__ */ jsx(
            TableSearch,
            {
              style: { width: "22em" },
              query: searchQuery,
              handleChange: (e) => {
                var _a, _b;
                setSearchQuery((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              },
              placeholder: "Enter company name, app or owner name..."
            }
          )
        }
      ),
      !isLoadingApps && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
        BasicServerTable,
        {
          rowData: apps,
          columnData: appsColumn,
          sorting,
          setSorting,
          pagination,
          setPagination,
          totalPages,
          totalItems
        }
      ) })
    ] }) })
  ] });
};
