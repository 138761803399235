"use strict";
export var PeopleAnalyticsChart = /* @__PURE__ */ ((PeopleAnalyticsChart2) => {
  PeopleAnalyticsChart2["TURNOVER"] = "turnover";
  PeopleAnalyticsChart2["INTERNAL_MOBILITY"] = "internal-mobility";
  PeopleAnalyticsChart2["RETENTION"] = "retention";
  PeopleAnalyticsChart2["TENURE"] = "tenure";
  PeopleAnalyticsChart2["WORKFORCE_METRICS"] = "workforce-metrics";
  return PeopleAnalyticsChart2;
})(PeopleAnalyticsChart || {});
export var PeopleAnalyticsType = /* @__PURE__ */ ((PeopleAnalyticsType2) => {
  PeopleAnalyticsType2["AVG"] = "avg";
  PeopleAnalyticsType2["OVER_TIME"] = "over-time";
  return PeopleAnalyticsType2;
})(PeopleAnalyticsType || {});
