"use strict";
import { themeColors } from "@/v2/styles/colors.styles";
export var Sentiments = /* @__PURE__ */ ((Sentiments2) => {
  Sentiments2["Negative"] = "Negative";
  Sentiments2["Neutral"] = "Neutral";
  Sentiments2["Positive"] = "Positive";
  Sentiments2["None"] = "None";
  return Sentiments2;
})(Sentiments || {});
export const SentimentsOptions = [
  { label: "Negative" /* Negative */, value: "Negative" /* Negative */ },
  { label: "Neutral" /* Neutral */, value: "Neutral" /* Neutral */ },
  { label: "Positive" /* Positive */, value: "Positive" /* Positive */ },
  { label: "None" /* None */, value: "None" /* None */ }
];
export const getSentimentColor = () => {
  return {
    ["Negative" /* Negative */]: themeColors.Orange,
    ["Neutral" /* Neutral */]: themeColors.LightYellow,
    ["Positive" /* Positive */]: themeColors.Green,
    ["None" /* None */]: themeColors.DarkGrey
  };
};
