"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import ReactApexChart from "react-apexcharts";
import { DEFAULT_CHART_FONT } from "@/v2/components/charts/chart.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import "@/v2/components/charts/styles/apex-charts.styles.css";
export function ChartRadial({ series, categories: labels, options: inputOptions }) {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const defaultOptions = {
    showLabelName: false,
    hollowSize: "80%"
  };
  const options = __spreadProps(__spreadValues(__spreadValues({}, defaultOptions), inputOptions), {
    hollowSize: (_a = inputOptions == null ? void 0 : inputOptions.hollowSize) != null ? _a : defaultOptions.hollowSize,
    colors: ((_b = inputOptions == null ? void 0 : inputOptions.colors) == null ? void 0 : _b.length) ? inputOptions.colors : [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    noDataTitle: (_c = inputOptions == null ? void 0 : inputOptions.noDataTitle) != null ? _c : polyglot.t("Charts.noData")
  });
  const chartOptions = {
    labels,
    chart: {
      fontFamily: DEFAULT_CHART_FONT,
      toolbar: {
        show: false
      }
    },
    grid: {
      padding: {
        top: -15,
        right: -15,
        bottom: -15,
        left: -15
      }
    },
    plotOptions: {
      radialBar: {
        align: "center",
        hollow: {
          size: options.hollowSize
        },
        track: {
          background: themeColors.Background
        },
        dataLabels: {
          show: true,
          name: {
            show: options.showLabelName
          },
          value: {
            fontSize: "32px",
            fontWeight: 300,
            color: themeColors.DarkGrey,
            offsetY: 12,
            show: true
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    },
    colors: options.colors,
    noData: { text: options.noDataTitle },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    }
  };
  return /* @__PURE__ */ jsx(
    ReactApexChart,
    {
      type: "radialBar",
      series,
      options: chartOptions,
      height: options.height,
      width: options.width
    }
  );
}
