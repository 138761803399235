"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { v4 } from "uuid";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ReviewQuestionBankAPI } from "@/v2/feature/growth/reviews/api-client/review-question-bank.api";
import { QuestionModalContent } from "@/v2/feature/growth/reviews/features/components/question-modal-content.component";
export const ReviewTemplateQuestionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  question,
  refresh,
  section,
  templateId
}) => {
  const onSubmit = (values) => __async(void 0, null, function* () {
    var _a;
    if (question == null ? void 0 : question.id) {
      const questionValues = __spreadProps(__spreadValues(__spreadValues({}, question), values), {
        templateId
      });
      yield ReviewQuestionBankAPI.updateReviewQuestionInBank(questionValues);
    } else {
      const questionValues = __spreadProps(__spreadValues({}, values), {
        id: v4(),
        sectionId: (_a = section == null ? void 0 : section.id) != null ? _a : null,
        templateId
      });
      yield ReviewQuestionBankAPI.createReviewQuestionInBank(questionValues);
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(QuestionModalContent, { onClose, question, refresh, onSubmit }) });
};
