"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/system";
import { keyBy } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DocumentBig } from "@/images/documents/DocumentBig.svg";
import { ReactComponent as Megaphone } from "@/images/documents/Megaphone.svg";
import { ReactComponent as Upload } from "@/images/fields/Upload.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { canAccessScopes } from "@/lib/scopes";
import { BigFilterButton } from "@/v2/components/big-filter-buttons/big-filter-button.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentCategoryFilters } from "@/v2/feature/document-new/components/new-document-category-filters.component";
import { NewDocumentFormModal } from "@/v2/feature/document-new/components/new-document-form-modal.component";
import { NewDocumentPreview } from "@/v2/feature/document-new/components/new-document-preview.component";
import { NewDocumentTable } from "@/v2/feature/document-new/components/new-document-table.component";
import { NewDocumentUploadModal } from "@/v2/feature/document-new/components/new-document-upload-modal.component";
import { NewDocumentViewModal } from "@/v2/feature/document-new/components/new-document-view-modal.component";
import { useDocumentActions } from "@/v2/feature/document-new/hooks/use-document-actions.hook";
import {
  FormTypeEnum,
  UserDocumentView,
  UserDocumentViewArray
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { ContractTemplateManager } from "@/v2/feature/templates/features/contract-template-manager/contract-template-manager.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { UserType } from "@/v2/interfaces/general.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const MemoizedTable = React.memo(NewDocumentTable, (before, after) => {
  return before.documentList === after.documentList && before.loading === after.loading && before.pagination === after.pagination;
});
export const DnUserListingPage = ({
  userId,
  reach,
  userReach,
  customRuleDataset
}) => {
  var _a, _b, _c, _d, _e;
  const [state, globalDipatch] = useContext(GlobalContext);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filterString, setFilterString] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [documentList, setDocumentList] = useState(void 0);
  const [documentTypes, setDocumentTypes] = useState(void 0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [formType, setFormType] = useState(FormTypeEnum.upload);
  const [error, setError] = useState(null);
  const [docData, setDocData] = useState(null);
  const fileURLRef = useRef(null);
  const [viewFilter, setViewFilter] = useState(
    (_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.document) == null ? void 0 : _b.me) == null ? void 0 : _c.selectedFilters) != null ? _d : UserDocumentView.Company
  );
  const [docCounts, setDocCounts] = useState({
    pinnedCount: 0,
    personalCount: 0,
    companyCount: 0
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [missingFields, setMissingFields] = useState();
  const [selectDocument, setSelectedDocument] = useState(void 0);
  const documentTypesLookup = useMemo(() => keyBy(documentTypes != null ? documentTypes : [], "id"), [documentTypes]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const isAdmin = canAccessScopes(state.user, ["documents:all"]);
  const setUserDocumentsRecords = useCallback(() => __async(void 0, null, function* () {
    try {
      const documentsResponse = yield DocumentNewAPI.getDocumentsByUserId(
        userId,
        pagination.pageIndex,
        pagination.pageSize,
        filterString,
        searchInput,
        viewFilter
      );
      const { paginatedDocs, pinnedCount, personalCount, companyCount, documentTypes: documentTypes2 } = documentsResponse;
      setDocumentList(paginatedDocs.items);
      setTotalPages(paginatedDocs.totalPages);
      setTotalItems(paginatedDocs.totalItems);
      setDocCounts({ pinnedCount, personalCount, companyCount });
      setDocumentTypes(documentTypes2);
    } catch (error2) {
      showMessage("Something went wrong", "error");
    } finally {
      setLoadingData(false);
    }
  }), [pagination, filterString, searchInput, viewFilter, userId, showMessage]);
  useEffect(() => {
    setLoadingData(true);
    setUserDocumentsRecords().finally(() => {
      setLoadingData(false);
    });
  }, [pagination, filterString, searchInput, viewFilter, userId, setUserDocumentsRecords]);
  const debouncedFilter = useDebouncedCallback((filterString2) => __async(void 0, null, function* () {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error2) {
      showMessage(polyglot.t("DocumentsCompanyPage.errorMessages.filter"), "error");
    }
  }), 500);
  const handleFilter = useCallback(
    (event) => {
      debouncedFilter.callback(event);
    },
    [debouncedFilter]
  );
  const viewOptions = useMemo(() => {
    const counts = {
      [UserDocumentView.Pinned]: docCounts.pinnedCount,
      [UserDocumentView.Personal]: docCounts.personalCount,
      [UserDocumentView.Company]: docCounts.companyCount
    };
    return UserDocumentViewArray.map((view) => /* @__PURE__ */ jsx(
      BigFilterButton,
      {
        title: view,
        action: () => __async(void 0, null, function* () {
          setViewFilter(view);
          setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
          const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("document", "me", "selectedFilters", view);
          globalDipatch({
            type: GlobalStateActions.UPDATE_USER,
            payload: updatedGlobalUser
          });
        }),
        subtitle: `${counts[view]} documents`,
        active: viewFilter === view,
        loading: !documentList
      }
    ));
  }, [docCounts, viewFilter, globalDipatch, documentList]);
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current);
      fileURLRef.current = null;
    }
  }, []);
  const handleRowClick = useCallback((row) => {
    setSelectedDocument(row.original);
    setIsViewOpen(true);
  }, []);
  const getDocumentActions = useCallback(() => {
    return [
      {
        handler: () => {
          setFormType(FormTypeEnum.upload);
          setIsEditOpen(true);
        },
        label: "Upload",
        icon: /* @__PURE__ */ jsx(Upload, __spreadValues({}, iconSize)),
        disabled: false
      },
      {
        handler: () => {
          setFormType(FormTypeEnum.request);
          setIsEditOpen(true);
        },
        label: "Request",
        icon: /* @__PURE__ */ jsx(Megaphone, __spreadValues({}, iconSize)),
        disabled: false
      },
      {
        handler: () => setOpenTemplateModal(true),
        label: "Template",
        icon: /* @__PURE__ */ jsx(DocumentBig, __spreadValues({}, iconSize)),
        disabled: false,
        hidden: !isAdmin
      }
    ];
  }, [isAdmin]);
  const actions = useDocumentActions({
    setLoadingData,
    setIsEditOpen,
    setIsUploadOpen,
    setIsViewOpen,
    setDocumentsRecordsFromServer: setUserDocumentsRecords,
    fileURLRef,
    setDocData,
    setIsPreviewOpen,
    setError,
    setMissingFields,
    setSelectedDocument,
    setOpenMissingFieldContractModal,
    loggedInUserId: state.user.userId
  });
  const primaryAction = useMemo(() => {
    if (userReach === UserType.App) {
      return /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          onClick: () => {
            setFormType(FormTypeEnum.upload);
            setIsEditOpen(true);
          },
          children: "New document"
        }
      );
    }
    return /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: getDocumentActions(),
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "Actions",
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    );
  }, [userReach, getDocumentActions]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", color: "DarkGrey", children: "Documents" }),
        showAction: true,
        actions: primaryAction
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: { overflow: "hidden" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: viewOptions }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, mt: spacing.s2 }, children: [
        /* @__PURE__ */ jsx(
          NewDocumentCategoryFilters,
          {
            documentTypes: documentTypes != null ? documentTypes : [],
            handleFilter,
            filterString,
            viewFilter
          }
        ),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              var _a2, _b2;
              setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
              setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
            },
            placeholder: polyglot.t("DocumentsCompanyPage.search")
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          MemoizedTable,
          {
            documentTypesLookup,
            documentList: documentList != null ? documentList : [],
            loading: loadingData,
            pagination,
            setPagination,
            totalPages,
            totalItems,
            handleRowClick,
            actions,
            customRuleDataset
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentFormModal,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            onClose: () => {
              setIsEditOpen(false);
              setIsViewOpen(false);
              setTimeout(() => {
                setSelectedDocument(void 0);
                setFormType(FormTypeEnum.upload);
              }, 300);
            },
            refresh: setUserDocumentsRecords,
            documentTypes,
            document: selectDocument,
            formType,
            userId,
            reach
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentUploadModal,
          {
            isOpen: isUploadOpen,
            setIsOpen: setIsUploadOpen,
            onClose: () => {
              setIsUploadOpen(false);
              setSelectedDocument(void 0);
            },
            selectedDocument: selectDocument,
            refresh: setUserDocumentsRecords
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentViewModal,
          {
            isOpen: Boolean(isViewOpen && selectDocument),
            setIsOpen: setIsViewOpen,
            onClose: () => {
              setIsViewOpen(false);
              setTimeout(() => {
                setSelectedDocument(void 0);
              }, 300);
            },
            actions,
            documentTypes,
            document: selectDocument
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentPreview,
          {
            docData,
            onClose: closePreview,
            isOpen: isPreviewOpen,
            error,
            title: (_e = docData == null ? void 0 : docData.fileName) != null ? _e : "Document"
          }
        ),
        /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
          ContractTemplateManager,
          {
            openTemplateModal,
            setOpenTemplateModal,
            openMissingFieldContractModal,
            setOpenMissingFieldContractModal,
            missingFields,
            setMissingFields
          }
        ) })
      ] })
    ] })
  ] });
};
