"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { lazy, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { debounce } from "lodash";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
const TaskFormModal = lazy(
  () => import("@/v2/feature/task/components/task-add-modal/task-form-modal.component").then((module) => ({
    default: module.TaskFormModal
  }))
);
const TaskViewModal = lazy(
  () => import("@/v2/feature/task/components/task-view/task-view-modal.component").then((module) => ({
    default: module.TaskViewModal
  }))
);
export const TaskActionItem = React.memo(
  ({
    selectedRow,
    refresh,
    afterClose
  }) => {
    return /* @__PURE__ */ jsx(TaskActionItemContent, { selectedRow, refresh, afterClose });
  }
);
const TaskActionItemContent = ({
  selectedRow,
  refresh,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [showMessage] = useMessage();
  const [selectedTask, setSelectedTask] = useState(null);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const getTaskById = useCallback(() => __async(void 0, null, function* () {
    if (!(selectedRow == null ? void 0 : selectedRow.id)) return;
    try {
      setLoading(true);
      const task = yield TaskAPI.getTaskById(Number(selectedRow.id));
      setSelectedTask(task);
    } catch (error) {
      console.error(error);
      showMessage(polyglot.t("TaskActionItem.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }), "error");
      setSelectedTask(null);
    } finally {
      setLoading(false);
    }
  }), [selectedRow == null ? void 0 : selectedRow.id, showMessage, polyglot]);
  const debouncedGetTaskById = useMemo(() => debounce(getTaskById, 300), [getTaskById]);
  useEffect(() => {
    if (selectedRow == null ? void 0 : selectedRow.id) {
      setLoading(true);
      setIsViewOpen(true);
      debouncedGetTaskById();
    }
    return () => debouncedGetTaskById.cancel();
  }, [selectedRow == null ? void 0 : selectedRow.id, debouncedGetTaskById]);
  const updateTaskStatus = useCallback(
    (taskId, assignedUserId, status) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
        showMessage(polyglot.t("TaskActionItem.successMessages.updateStatus"), "success");
        setIsViewOpen(false);
        setIsEditOpen(false);
        yield refresh();
        if (selectedTask) setSelectedTask(__spreadProps(__spreadValues({}, selectedTask), { status }));
      } catch (error) {
        showMessage(
          polyglot.t("TaskActionItem.errorMessages.updateStatus", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [selectedTask, showMessage, polyglot, refresh]
  );
  const deleteTaskAfterConfirmation = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedTask == null ? void 0 : selectedTask.id) yield TaskAPI.removeTasks([selectedTask == null ? void 0 : selectedTask.id]);
      showMessage(polyglot.t("TaskActionItem.successMessages.deleteMult"), "success");
      setIsViewOpen(false);
      setIsEditOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("TaskActionItem.errorMessages.removeMult", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [selectedTask, showMessage, polyglot, refresh]);
  const remindAboutTask = useCallback(
    (taskId) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.remindAboutTask(taskId);
        showMessage(polyglot.t("PersonalTask.successMessages.remind"), "success");
        setIsViewOpen(false);
        setIsEditOpen(false);
        yield refresh();
      } catch (error) {
        showMessage(
          polyglot.t("PersonalTask.errorMessages.remind", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot, refresh]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
      TaskFormModal,
      {
        isOpen: isEditOpen,
        setIsOpen: setIsEditOpen,
        afterClose: () => {
          setSelectedTask(null);
          afterClose();
        },
        userId: currentUser.userId,
        formData: selectedTask,
        refresh
      }
    ) }),
    /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
      TaskViewModal,
      {
        isOpen: isViewOpen,
        setIsOpen: setIsViewOpen,
        onClose: () => {
          setIsViewOpen(false);
        },
        afterClose: () => {
          setSelectedTask(null);
          afterClose();
        },
        task: selectedTask,
        action: updateTaskStatus,
        deleteTask: deleteTaskAfterConfirmation,
        remindAboutTask,
        editTask: () => {
          setIsEditOpen(true);
          setIsViewOpen(false);
        },
        isLoading
      }
    ) })
  ] });
};
