"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SingleAnswerQuestion = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
  showError
}) => {
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = (answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) || { answer: "", comment: "" };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);
  if (!question || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  const handleChoiceChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
  };
  const handleCommentChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(FormControl, { component: "fieldset", sx: { width: "100%", margin: 0 }, children: /* @__PURE__ */ jsx(
      RadioGroup,
      {
        "aria-labelledby": "demo-radio-buttons-group-label",
        name: "answer",
        onChange: (e) => {
          handleChoiceChange(e.target.value);
        },
        children: question && (question == null ? void 0 : question.choiceOptions) && Object.keys(question == null ? void 0 : question.choiceOptions).map((a) => /* @__PURE__ */ jsx(
          FormControlLabel,
          {
            labelPlacement: "end",
            value: a,
            checked: a === currentAnswer,
            control: /* @__PURE__ */ jsx(StyledRadio, {}),
            label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: question == null ? void 0 : question.choiceOptions[+a] }),
            sx: {
              py: spacing.p10,
              margin: 0,
              gap: spacing.g10,
              px: 0,
              "& span.MuiButtonBase-root.MuiRadio-root": {
                padding: 0
              }
            }
          },
          a
        ))
      }
    ) }),
    showError && !currentAnswer && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: showError ? themeColors.darkRed : themeColors.DarkGrey }, children: "This field is required" }),
    question.hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: question.isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(
            RichTextField,
            {
              value: currentComment,
              onChange: (value) => handleCommentChange(value),
              error: showError && question.hasComment && question.isCommentRequired,
              helperText: showError && question.hasComment && question.isCommentRequired && !isHTMLValid(currentComment) ? "This field is required" : ""
            },
            question.id
          )
        ]
      }
    )
  ] });
};
