"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import {
  SalaryBasisEnum
} from "@v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { UserLifecycleStatuses } from "@/models";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { UserPayrollStatusCell } from "@/v2/feature/payroll/components/user-payroll-status-cell.component";
import {
  formatPayPeriodIncome,
  formatSalaryBasis,
  getUserPayrollStatusSortOrder
} from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { PayrollUserActionMenu } from "@/v2/feature/payroll/features/payroll-global/global-payroll-payruns/payroll-user-action-menu.component";
import { EditPayrollRecordDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component";
import { EmployeeActionsCell } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/employee-actions-column.component";
import { PayrollMissingInformationDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/payroll-missing-information.component";
import { ViewUserPayrollDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/view-user-payroll-drawer.component";
import {
  canAddToPayroll,
  canRemoveFromPayroll
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/payroll-company-employees.util";
import {
  getUserStatusFromUserPayrollForTableEntry,
  PayrollStatusLabel
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { CurrencyWithDiff } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { getApiErrorMessage } from "@/v2/infrastructure/api-error/api-error.util";
import { isSameCountryCode } from "@/v2/infrastructure/country/country.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { filterByTextSearch } from "@/v2/util/array.util";
export const UpcomingPayrunTable = ({
  payroll,
  nextPayrun,
  searchQuery,
  setSearchQuery,
  sx,
  disabled,
  refreshPayroll,
  selectedUsers,
  setSelectedUsers,
  noCurrencySymbol,
  stickyHeader,
  stickyHeaderHeight,
  separateCurrencyColumn
}) => {
  const { polyglot } = usePolyglot();
  const [_globalState, dispatch] = useContext(GlobalContext);
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [drawer, setDrawer] = useState(null);
  const [usersBeingUpdated, setUsersBeingUpdated] = useState(/* @__PURE__ */ new Set());
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const {
    data: rawPayrollList,
    mutate: refreshPayrollUsers,
    isValidating: loadingPayrollUsers
  } = useApiClient(PayrollLocalEndpoints.getUserPayrollMembershipList(), { suspense: false });
  const employeeList = useMemo(() => {
    var _a;
    return (_a = rawPayrollList == null ? void 0 : rawPayrollList.all) != null ? _a : [];
  }, [rawPayrollList == null ? void 0 : rawPayrollList.all]);
  const drawerRecord = useMemo(() => employeeList.find((e) => e.userId === (drawer == null ? void 0 : drawer.userId)), [
    drawer == null ? void 0 : drawer.userId,
    employeeList
  ]);
  const refreshPayrollState = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshPayroll(), refreshPayrollUsers == null ? void 0 : refreshPayrollUsers()]);
  }), [refreshPayroll, refreshPayrollUsers]);
  const getUserDisplayName = useCallback(
    (userId) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );
  const markUserUpdating = useCallback((userIds, isUpdating) => {
    setUsersBeingUpdated((currentUserIds) => {
      return new Set(
        isUpdating ? [...currentUserIds, ...userIds] : [...currentUserIds].filter((userId) => !userIds.includes(userId))
      );
    });
  }, []);
  const contractCountryMatchesUserPayrollCountry = useCallback((record) => {
    var _a, _b, _c;
    return isSameCountryCode((_a = record.payrollJurisdiction) != null ? _a : "GB", (_c = (_b = record.userPayroll) == null ? void 0 : _b.countryCode) != null ? _c : "GB");
  }, []);
  const refreshBillingRestrictions = useCallback(() => __async(void 0, null, function* () {
    var _a;
    const response = yield AuthAPI.getAuthMe(false);
    const authUser = (_a = response == null ? void 0 : response.user) != null ? _a : null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser
    });
  }), [dispatch]);
  const addToPayroll = useCallback(
    (userIds) => __async(void 0, null, function* () {
      if (!userIds.length) return true;
      let userAddedToPayroll = null;
      markUserUpdating(userIds, true);
      try {
        userAddedToPayroll = yield PayrollAPI.addUsersToPayroll(payroll.id, userIds);
        if (userAddedToPayroll.failed.length === 0) {
          showMessage(
            polyglot.t("PayrunTable.usersAddedToPayroll", { smart_count: userAddedToPayroll.added.length }),
            "success"
          );
        } else {
          const failureList = userAddedToPayroll.failed.map((f) => `${f.name}: ${f.reason}`).join("\n");
          showMessage(polyglot.t("PayrunTable.usersNotAddedToPayroll", { reason: failureList }), "warning");
        }
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(getApiErrorMessage(error), "warning");
        }
      } finally {
        markUserUpdating(userIds, false);
      }
      refreshPayrollState == null ? void 0 : refreshPayrollState();
      refreshBillingRestrictions();
      return (userAddedToPayroll == null ? void 0 : userAddedToPayroll.failed.length) === 0;
    }),
    [payroll.id, markUserUpdating, refreshBillingRestrictions, refreshPayrollState, showMessage, polyglot]
  );
  const removeFromPayroll = useCallback(
    (userIds) => __async(void 0, null, function* () {
      if (!userIds.length) return true;
      let userRemovedFromPayroll = null;
      markUserUpdating(userIds, true);
      try {
        userRemovedFromPayroll = yield PayrollAPI.removeUsersFromPayroll(payroll.id, userIds);
        if (userRemovedFromPayroll.failed.length === 0) {
          showMessage(
            polyglot.t("PayrunTable.usersRemovedToPayroll", { smart_count: userRemovedFromPayroll.removed.length }),
            "success"
          );
        } else {
          const failureList = userRemovedFromPayroll.failed.map((f) => `${f.name}: ${f.reason}`).join("\n");
          showMessage(polyglot.t("PayrunTable.usersNotRemovedFromPayroll", { reason: failureList }), "warning");
        }
      } catch (error) {
        showMessage(getApiErrorMessage(error), "warning");
      } finally {
        markUserUpdating(userIds, false);
      }
      refreshPayrollState == null ? void 0 : refreshPayrollState();
      refreshBillingRestrictions();
      return (userRemovedFromPayroll == null ? void 0 : userRemovedFromPayroll.failed.length) === 0;
    }),
    [payroll.id, markUserUpdating, refreshBillingRestrictions, refreshPayrollState, showMessage, polyglot]
  );
  const rows = useMemo(() => {
    var _a;
    const result = [];
    for (const payrollUser of employeeList) {
      const { entityId, user } = payrollUser;
      const paySchedule = (_a = payrollUser.compensationBreakdown) == null ? void 0 : _a.paySchedule;
      if (entityId !== payroll.entity.id) continue;
      if (user.status === UserLifecycleStatuses.Terminated && user.leaveDate && nextPayrun.startDate > user.leaveDate)
        continue;
      if (user.startDate && user.startDate > nextPayrun.endDate) continue;
      if (paySchedule && paySchedule !== nextPayrun.payPeriod) continue;
      result.push(payrollUser);
    }
    const statusOrder = [
      PayrollStatusLabel.NotInPayroll,
      PayrollStatusLabel.NewJoiner,
      PayrollStatusLabel.Leaver,
      PayrollStatusLabel.Current
    ];
    return result.sort((a, b) => {
      const [aStatus, bStatus] = [
        getUserStatusFromUserPayrollForTableEntry(a, nextPayrun).label,
        getUserStatusFromUserPayrollForTableEntry(b, nextPayrun).label
      ];
      return statusOrder.indexOf(aStatus) - statusOrder.indexOf(bStatus) || getUserDisplayName(a.userId).localeCompare(getUserDisplayName(b.userId), polyglot.locale(), {
        sensitivity: "base"
      });
    });
  }, [employeeList, getUserDisplayName, payroll.entity.id, nextPayrun, polyglot]);
  const filteredRows = useMemo(() => {
    return filterByTextSearch(searchQuery, rows, (user) => [getUserDisplayName(user.userId)]);
  }, [getUserDisplayName, searchQuery, rows]);
  const salaries = useMemo(() => {
    const result = {
      byUserId: /* @__PURE__ */ new Map(),
      totalSalary: 0
    };
    for (const user of rows) {
      if (!user.compensationBreakdown) continue;
      result.byUserId.set(user.userId, user.compensationBreakdown);
      result.totalSalary += user.compensationBreakdown.payScheduleRate;
    }
    return result;
  }, [rows]);
  const selectedUserIds = useMemo(() => new Set(selectedUsers), [selectedUsers]);
  const updateSelectedUsers = useCallback(
    ({ select, deselect }) => {
      const updatedUserIds = new Set(selectedUserIds);
      select == null ? void 0 : select.forEach((userId) => updatedUserIds.add(userId));
      deselect == null ? void 0 : deselect.forEach((userId) => updatedUserIds.delete(userId));
      setSelectedUsers == null ? void 0 : setSelectedUsers([...updatedUserIds]);
    },
    [selectedUserIds, setSelectedUsers]
  );
  const columnData = useMemo(() => {
    const columns = [
      {
        id: "employee",
        header: () => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx("div", { onClick: (e) => e.stopPropagation(), children: setSelectedUsers && /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              checked: filteredRows.length > 0 && filteredRows.every(({ userId }) => selectedUserIds.has(userId)),
              disabled: filteredRows.length === 0,
              onChange: (_, checked) => {
                const filteredUserIds = filteredRows.map(({ userId }) => userId);
                if (checked) updateSelectedUsers({ select: filteredUserIds });
                else updateSelectedUsers({ deselect: filteredUserIds });
              }
            }
          ) }),
          polyglot.t("PayrunTable.employee")
        ] }),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => /* @__PURE__ */ jsxs(Stack, { sx: { flexDirection: "row", alignItems: "center", gap: spacing.g10 }, children: [
          setSelectedUsers && /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              checked: selectedUserIds.has(c.row.original.userId),
              onChange: (_, checked) => {
                const { userId } = c.row.original;
                if (checked) updateSelectedUsers({ select: [userId] });
                else updateSelectedUsers({ deselect: [userId] });
              }
            }
          ),
          /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId })
        ] }),
        footer: () => polyglot.t("PayrunTable.total")
      },
      ...separateCurrencyColumn ? [
        {
          id: "salary-currency",
          header: () => polyglot.t("General.currency"),
          accessorFn: (row) => row,
          cell: (c) => {
            var _a;
            const userSalary = salaries.byUserId.get(c.row.original.userId);
            const currency = (_a = userSalary == null ? void 0 : userSalary.currency) != null ? _a : "";
            return /* @__PURE__ */ jsx(Fragment, { children: currency });
          }
        }
      ] : [],
      {
        id: "salary-basis",
        header: () => polyglot.t("PayrunTable.salaryBasis"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return formatSalaryBasis((_a = salaries.byUserId.get(item.userId)) == null ? void 0 : _a.salaryBasis, polyglot);
        }),
        cell: (c) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: formatSalaryBasis((_a = salaries.byUserId.get(c.row.original.userId)) == null ? void 0 : _a.salaryBasis, polyglot) });
        }
      },
      {
        id: "salary-rate",
        header: () => polyglot.t("PayrunTable.salaryRate"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = salaries.byUserId.get(item.userId)) == null ? void 0 : _a.rate;
        }),
        cell: (c) => {
          var _a;
          const userSalary = salaries.byUserId.get(c.row.original.userId);
          const currency = (_a = userSalary == null ? void 0 : userSalary.currency) != null ? _a : DEFAULT_CURRENCY;
          return /* @__PURE__ */ jsx(CurrencyCell, { value: userSalary == null ? void 0 : userSalary.rate, formatOptions: { noCurrencySymbol }, currency });
        }
      },
      {
        id: "units",
        header: () => polyglot.t("PayrunTable.units"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return ((_a = item.compensationBreakdown) == null ? void 0 : _a.units) || 0;
        }),
        cell: (c) => {
          var _a, _b, _c;
          if (((_b = (_a = c.row.original) == null ? void 0 : _a.compensationBreakdown) == null ? void 0 : _b.salaryBasis) && [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(c.row.original.compensationBreakdown.salaryBasis)) {
            return (_c = c.row.original.compensationBreakdown.units) != null ? _c : /* @__PURE__ */ jsx(EmptyCell, {});
          }
          return /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "earnings",
        header: () => formatPayPeriodIncome(nextPayrun.payPeriod, polyglot),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = salaries.byUserId.get(item.userId)) == null ? void 0 : _a.payScheduleRate;
        }),
        cell: (c) => {
          var _a;
          const userSalary = salaries.byUserId.get(c.row.original.userId);
          const currency = (_a = userSalary == null ? void 0 : userSalary.currency) != null ? _a : DEFAULT_CURRENCY;
          return /* @__PURE__ */ jsx(
            CurrencyCell,
            {
              value: userSalary == null ? void 0 : userSalary.payScheduleRate,
              formatOptions: { noCurrencySymbol },
              currency
            }
          );
        },
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: salaries.totalSalary, formatOptions: { noCurrencySymbol } })
      },
      {
        id: "status",
        header: () => polyglot.t("PayrunTable.status"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => {
          var _a, _b;
          const statusA = (_a = getUserStatusFromUserPayrollForTableEntry(a.original, nextPayrun)) == null ? void 0 : _a.label;
          const statusB = (_b = getUserStatusFromUserPayrollForTableEntry(b.original, nextPayrun)) == null ? void 0 : _b.label;
          const orderA = getUserPayrollStatusSortOrder(statusA);
          const orderB = getUserPayrollStatusSortOrder(statusB);
          return orderA - orderB;
        },
        cell: (c) => /* @__PURE__ */ jsx(UserPayrollStatusCell, { status: getUserStatusFromUserPayrollForTableEntry(c.row.original, nextPayrun) })
      },
      {
        header: () => "",
        id: "action",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original: payrollUser } }) => {
          return /* @__PURE__ */ jsx(
            EmployeeActionsCell,
            {
              user: payrollUser,
              payroll,
              openDrawer: (mode, { userId }) => setDrawer({ userId, mode }),
              disabled,
              updating: usersBeingUpdated.has(payrollUser.userId),
              addToPayroll: (userId) => __async(void 0, null, function* () {
                return addToPayroll([userId]);
              }),
              removeFromPayroll: (userId) => __async(void 0, null, function* () {
                return removeFromPayroll([userId]);
              })
            }
          );
        }
      }
    ];
    return columns;
  }, [
    polyglot,
    getUserDisplayName,
    selectedUserIds,
    setSelectedUsers,
    salaries,
    nextPayrun,
    payroll,
    disabled,
    usersBeingUpdated,
    addToPayroll,
    removeFromPayroll,
    filteredRows,
    updateSelectedUsers,
    noCurrencySymbol,
    separateCurrencyColumn
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx, children: [
      /* @__PURE__ */ jsxs(
        Stack,
        {
          sx: { flexDirection: "row", alignItems: "center", justifyContent: "space-between", mr: "20px", mb: "20px" },
          children: [
            /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
            selectedUserIds.size > 0 && /* @__PURE__ */ jsx(
              PayrollUserActionMenu,
              {
                onAddToPayrollClick: () => {
                  addToPayroll([...selectedUserIds]);
                },
                onRemoveFromPayrollClick: () => {
                  removeFromPayroll([...selectedUserIds]);
                }
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          loading: loadingPayrollUsers && !rawPayrollList,
          rowData: filteredRows,
          columnData,
          hidePagination: true,
          maxUnpaginatedRows: 500,
          showFooter: true,
          initialSort: [
            { id: "status", desc: false },
            { id: "employee", desc: true }
          ],
          stickyHeader,
          stickyHeaderHeight
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      PayrollMissingInformationDrawer,
      {
        isOpen: (drawer == null ? void 0 : drawer.mode) === "missing-info",
        close: () => setDrawer(null),
        payrollRecord: drawerRecord,
        refreshPayroll: refreshPayrollState
      }
    ),
    /* @__PURE__ */ jsx(
      EditPayrollRecordDrawer,
      {
        isOpen: (drawer == null ? void 0 : drawer.mode) === "edit",
        close: () => setDrawer(null),
        payrollRecord: drawerRecord && contractCountryMatchesUserPayrollCountry(drawerRecord) && drawerRecord.userPayroll || null,
        mode: (drawerRecord == null ? void 0 : drawerRecord.userPayroll) && contractCountryMatchesUserPayrollCountry(drawerRecord) ? "append" : "initial",
        userId: drawer == null ? void 0 : drawer.userId,
        onUpdateStarted: () => !!drawer && markUserUpdating([drawer.userId], true),
        onUpdateFinished: (success) => {
          !!drawer && markUserUpdating([drawer.userId], false);
          if (success) refreshPayrollState == null ? void 0 : refreshPayrollState();
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ViewUserPayrollDrawer,
      {
        isOpen: (drawer == null ? void 0 : drawer.mode) === "view",
        close: (options) => {
          if ((options == null ? void 0 : options.switchToEdit) && drawer) {
            setDrawer({ userId: drawer.userId, mode: "edit" });
            return;
          }
          setDrawer(null);
        },
        onClose: () => (drawer == null ? void 0 : drawer.mode) === "view" && setDrawer(null),
        record: drawerRecord || null,
        isUserUpdating: !!drawer && usersBeingUpdated.has(drawer.userId),
        addToPayroll: drawerRecord && canAddToPayroll(drawerRecord, payroll) ? (record) => addToPayroll([record.user.userId]) : void 0,
        removeFromPayroll: drawerRecord && canRemoveFromPayroll(drawerRecord, payroll) ? (record) => removeFromPayroll([record.user.userId]) : void 0,
        canEdit: true
      }
    ),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.MONEY_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] });
};
