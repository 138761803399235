"use strict";
export var NotificationTypes = /* @__PURE__ */ ((NotificationTypes2) => {
  NotificationTypes2["newJoiner"] = "newJoiner";
  NotificationTypes2["workAnniversary"] = "workAnniversary";
  NotificationTypes2["lastDay"] = "lastDay";
  NotificationTypes2["birthday"] = "birthday";
  return NotificationTypes2;
})(NotificationTypes || {});
export var SSOAppsEnum = /* @__PURE__ */ ((SSOAppsEnum2) => {
  SSOAppsEnum2["google-workspace"] = "Google Workspace";
  SSOAppsEnum2["sso-google-workspace"] = "Google";
  SSOAppsEnum2["sso-okta"] = "Okta";
  SSOAppsEnum2["sso-azure-ad"] = "Azure AD";
  return SSOAppsEnum2;
})(SSOAppsEnum || {});
export const SSOAppsOptions = [
  // { label: SSOAppsEnum['google-workspace'], value: 'google-workspace' },
  { label: "Google" /* sso-google-workspace */, value: "sso-google-workspace" },
  { label: "Okta" /* sso-okta */, value: "sso-okta" },
  { label: "Azure AD" /* sso-azure-ad */, value: "sso-azure-ad" }
];
export var AuditTrailStatus = /* @__PURE__ */ ((AuditTrailStatus2) => {
  AuditTrailStatus2["Processed"] = "Processed";
  AuditTrailStatus2["InProgress"] = "In progress";
  AuditTrailStatus2["Failed"] = "Failed";
  AuditTrailStatus2["Sent"] = "Sent";
  return AuditTrailStatus2;
})(AuditTrailStatus || {});
export const INTEGRATION_SCOPE_LIST = ["user.basicInfo", "user.basicInfo:read"];
export const integrationScopeListOptions = INTEGRATION_SCOPE_LIST.map((scope) => ({ label: scope, value: scope }));
