"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ReviewAPI {
  static upsertComment(reviewId, cycleId, updateObject) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/reviews/comment/${reviewId}/${cycleId}`, updateObject)).data;
    });
  }
  static deleteComment(reviewId, cycleId, updateObject) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/reviews/delete-comment/${reviewId}/${cycleId}`, updateObject)).data;
    });
  }
  static publishReviewResult(reviewId, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/reviews/publish-result/${reviewId}/${cycleId}`)).data;
    });
  }
  static unpublishReviewResult(reviewId, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/reviews/unpublish-result/${reviewId}/${cycleId}`)).data;
    });
  }
}
export class ReviewEndpoints {
  static getMeResultList(userId, search) {
    const urlParams = new URLSearchParams(__spreadValues({}, search && { search }));
    return {
      url: `/apiv2/reviews/result/me/${userId}?${urlParams.toString()}`
    };
  }
  static getUserResultList(userId, search) {
    const urlParams = new URLSearchParams(__spreadValues({}, search && { search }));
    return {
      url: `/apiv2/reviews/result/user/${userId}?${urlParams.toString()}`
    };
  }
}
