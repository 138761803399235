"use strict";
import { GROWTH_COMPANY_GOALS_ROUTE, GROWTH_ME_GOALS_ROUTE } from "@/lib/routes";
export const GROWTH_GOALS_ROUTES = {
  company: GROWTH_COMPANY_GOALS_ROUTE,
  me: GROWTH_ME_GOALS_ROUTE
};
export const GROWTH_GOALS_ROUTE_SCOPES = {
  company: ["goals:all"],
  me: ["goals"]
};
