"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GridDisplayBarV2 = ({
  cells,
  onClick,
  showBorder = false,
  sx = {}
}) => {
  return /* @__PURE__ */ jsx(
    Grid,
    {
      container: true,
      sx: __spreadValues(__spreadProps(__spreadValues(__spreadValues({
        width: "100%",
        boxSizing: "border-box",
        padding: spacing.s2
      }, showBorder ? { borderRadius: spacing.s2, border: borders.middle } : { borderBottom: borders.background }), onClick && {
        "&:hover": {
          background: themeColors.TableHover
        }
      }), {
        cursor: onClick ? "pointer" : "default"
      }), sx),
      alignItems: "center",
      onClick,
      children: cells.map((cell, index) => {
        var _a, _b;
        return /* @__PURE__ */ jsx(Grid, { item: true, xs: cell.gridXs, sx: (_a = cell.sx) != null ? _a : {}, children: (_b = cell.content) != null ? _b : null }, index);
      })
    }
  );
};
