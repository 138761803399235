"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { keyBy } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewCycleAPI, ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { PlaceholderBox } from "@/v2/feature/growth/reviews/features/components/placeholder-box.component";
import { QuestionBuilder } from "@/v2/feature/growth/reviews/features/components/question-builder.component";
import { SectionBuilder } from "@/v2/feature/growth/reviews/features/components/section-builder.component";
import { CycleCreationMoveToSectionModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-move-to-section-modal.component";
import { CycleCreationQuestionFormModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-question-form-modal.component";
import { CycleCreationQuestionPreviewer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-question-previewer.component";
import { CycleCreationQuestionSelectModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-question-select-modal.component";
import { CycleCreationSectionFormModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-section-form-modal.component";
import { CycleCreationTemplateModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-template-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { useCycleQuestionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-cycle-question-options.hook";
import { useCycleSectionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-cycle-section-options.hook";
import {
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const RCUpsertQuestionPage = ({ cycleId, reach }) => {
  var _a, _b, _c, _d, _e;
  const { data: bankCount } = useApiClient(ReviewTemplateEndpoints.getBankCount(), { suspense: false });
  const {
    data: reviewStructure,
    mutate: refreshReviewStructure,
    isLoading: reviewStructureLoading,
    isValidating: reviewStructureValidating
  } = useApiClient(ReviewCycleEndpoints.getReviewCycleStructureById(cycleId), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState(false);
  const [question, setQuestion] = useState(null);
  const [section, setSection] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(void 0);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const [previewReviewer, setPreviewReviewer] = useState(void 0);
  const [cycle, setCycle] = useState(void 0);
  const [sections, setSections] = useState(void 0);
  const [questions, setQuestions] = useState(void 0);
  const [showMessage] = useMessage();
  useEffect(() => {
    if (reviewStructure) {
      setCycle(reviewStructure.cycle);
      setSections(reviewStructure.sections);
      setQuestions(reviewStructure.questions);
    }
  }, [reviewStructure]);
  const getReviewerSelects = useMemo(() => {
    var _a2;
    if (!(reviewStructure == null ? void 0 : reviewStructure.questions)) return [];
    const allReviewerSelects = (_a2 = reviewStructure == null ? void 0 : reviewStructure.questions) == null ? void 0 : _a2.flatMap((q) => q.reviewerSelect);
    return allReviewerSelects;
  }, [reviewStructure]);
  const isEditable = useMemo(
    () => Boolean((cycle == null ? void 0 : cycle.state) === CycleState.Draft || (cycle == null ? void 0 : cycle.state) === CycleState.Scheduled),
    [cycle]
  );
  const renderQuestion = (questionId) => {
    const question2 = questions && questions.find((q) => q.id === questionId);
    if (!question2 || !cycle) return null;
    return /* @__PURE__ */ jsx(
      QuestionComponent,
      {
        question: question2,
        cycle,
        setQuestion,
        setIsQuestionModalOpen,
        refreshReviewStructure,
        setSelectedQuestionId,
        setIsMoveToSectionOpen,
        hasSections: Boolean(sections && sections.length),
        growthFactors,
        isEditable
      },
      question2.id
    );
  };
  const renderSection = (sectionId) => {
    var _a2;
    const section2 = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section2 || !cycle) return null;
    const orderSection = cycle == null ? void 0 : cycle.order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a2 = orderSection.questions) != null ? _a2 : []).map((questionId) => questions == null ? void 0 : questions.find((q) => q.id === questionId)).filter((question2) => Boolean(question2));
    const handleDragDrop = (results) => __async(void 0, null, function* () {
      const { source, destination, type } = results;
      if (!destination) return;
      if (!cycle) return;
      if (type !== "group") return;
      const sectionIndex = cycle.order.findIndex((o) => o.type === "section" && o.id === section2.id);
      if (sectionIndex === -1) return;
      const reOrdered = __spreadValues({}, cycle.order[sectionIndex]);
      if (!reOrdered || !reOrdered.questions) return;
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      if (sourceIndex === -1 || destinationIndex === -1) return;
      const [removed] = reOrdered.questions.splice(sourceIndex, 1);
      reOrdered.questions.splice(destinationIndex, 0, removed);
      const updatedOrder = [...cycle.order];
      updatedOrder[sectionIndex] = reOrdered;
      setCycle((prevCycle) => {
        if (!prevCycle) return prevCycle;
        return __spreadProps(__spreadValues({}, prevCycle), {
          order: updatedOrder
        });
      });
      try {
        yield ReviewCycleAPI.updateOrderReviewCycle({ cycleId, order: updatedOrder });
        yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
        yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      }
    });
    return /* @__PURE__ */ jsx(
      ReviewBuilderSection,
      {
        section: section2,
        setSection,
        setIsQuestionModalOpen,
        setIsSectionModalOpen,
        questions: orderedQuestions,
        handleDragDrop,
        cycle,
        setQuestion,
        refreshReviewStructure,
        setSelectedQuestionId,
        setIsMoveToSectionOpen,
        sections,
        growthFactors,
        isEditable,
        reviewStructureValidating
      },
      section2.id
    );
  };
  const cloneQuestions = (selectedQuestionIds) => __async(void 0, null, function* () {
    try {
      yield ReviewCycleAPI.cloneQuestionsInCycle([...selectedQuestionIds], cycleId);
      refreshReviewStructure == null ? void 0 : refreshReviewStructure();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  const moveQuestionInsideSection = (sectionId, questionId) => __async(void 0, null, function* () {
    try {
      yield ReviewCycleAPI.moveQuestionInsideSection({ cycleId, sectionId, questionId });
      refreshReviewStructure == null ? void 0 : refreshReviewStructure();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  const getReviewerActionsOptions = useCallback(() => {
    return [
      ...getReviewerSelects.includes(ReviewerTypes.Self) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Self);
          },
          label: ReviewerTypes.Self,
          disabled: false
        }
      ] : [],
      ...getReviewerSelects.includes(ReviewerTypes.Manager) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Manager);
          },
          label: ReviewerTypes.Manager,
          disabled: false
        }
      ] : [],
      ...getReviewerSelects.includes(ReviewerTypes.Upward) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Upward);
          },
          label: ReviewerTypes.Upward,
          disabled: false
        }
      ] : [],
      ...getReviewerSelects.includes(ReviewerTypes.Peer) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Peer);
          },
          label: ReviewerTypes.Peer,
          disabled: false
        }
      ] : []
    ];
  }, [getReviewerSelects]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: cycle ? /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle: cycle, reachType: reach }) : /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text" }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: reviewStructureLoading,
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              height: "100%",
              boxSizing: "border-box"
            },
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  className: "sub-title",
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  },
                  children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Questions" }),
                    getReviewerSelects.length > 1 && /* @__PURE__ */ jsx(
                      StyledMenuComponent,
                      {
                        options: getReviewerActionsOptions(),
                        actionButtonDetails: {
                          type: "button",
                          colorVariant: "secondary",
                          sizeVariant: "small",
                          title: "Preview"
                        }
                      }
                    )
                  ]
                }
              ),
              isEditable && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16, mt: spacing.m24 }, children: [
                /* @__PURE__ */ jsx(
                  PlaceholderBox,
                  {
                    title: "Start with a template",
                    countOne: `${(_a = bankCount == null ? void 0 : bankCount.template) != null ? _a : 0} templates`,
                    countTwo: `${(_b = bankCount == null ? void 0 : bankCount.templateQuestions) != null ? _b : 0} questions`,
                    action: () => setIsTemplateModalOpen(true)
                  }
                ),
                /* @__PURE__ */ jsx(
                  PlaceholderBox,
                  {
                    title: "Select questions from library",
                    countOne: `${(_c = bankCount == null ? void 0 : bankCount.totalCategories) != null ? _c : 0} factors`,
                    countTwo: `${(_d = bankCount == null ? void 0 : bankCount.totalQuestions) != null ? _d : 0} questions`,
                    action: () => setIsQuestionBankOpen(true)
                  }
                )
              ] })
            ]
          }
        ),
        reviewStructureLoading ? /* @__PURE__ */ jsx(CycleSetupQuestionBuilderLoader, {}) : /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({}, cycle && cycle.order && cycle.order.length > 0 ? {} : { border: `1px dashed ${themeColors.GreyLight}` }), {
              mt: spacing.m24,
              height: "100%"
            }),
            children: [
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    flexDirection: "column"
                  },
                  children: cycle && ((_e = cycle.order) == null ? void 0 : _e.map((item) => {
                    if (item.type === "question") {
                      return renderQuestion(item.id);
                    } else if (item.type === "section") {
                      return renderSection(item.id);
                    }
                    return /* @__PURE__ */ jsx(Fragment, {});
                  }))
                }
              ),
              isEditable && /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadValues({
                    display: "flex",
                    gap: spacing.g5,
                    mt: cycle && cycle.order ? spacing.m24 : "0px"
                  }, cycle && cycle.order && cycle.order.length > 0 ? {} : { padding: spacing.p16 }),
                  children: [
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsSectionModalOpen(true),
                        children: "New section"
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsQuestionModalOpen(true),
                        children: "New question"
                      }
                    )
                  ]
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationTemplateModal,
          {
            isTemplateModalOpen,
            setIsTemplateModalOpen,
            cycleId,
            refresh: () => __async(void 0, null, function* () {
              yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
            })
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionSelectModal,
          {
            setIsQuestionsModalOpen: setIsQuestionBankOpen,
            isQuestionsModalOpen: isQuestionBankOpen,
            action: cloneQuestions,
            excludeQuestions: []
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionFormModal,
          {
            setIsOpen: setIsQuestionModalOpen,
            isOpen: isQuestionModalOpen,
            question,
            onClose: () => {
              setIsQuestionModalOpen(false);
              setQuestion(null);
              setSection(null);
            },
            refresh: () => __async(void 0, null, function* () {
              yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
            }),
            section,
            cycleId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationSectionFormModal,
          {
            setIsOpen: setIsSectionModalOpen,
            isOpen: isSectionModalOpen,
            section,
            onClose: () => {
              setIsSectionModalOpen(false);
              setSection(null);
              setQuestion(null);
            },
            refresh: () => __async(void 0, null, function* () {
              yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
            }),
            cycleId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationMoveToSectionModal,
          {
            isOpen: isMoveToSectionOpen,
            setIsOpen: setIsMoveToSectionOpen,
            onClose: () => {
              setIsMoveToSectionOpen(false);
            },
            action: moveQuestionInsideSection,
            sections,
            questionId: selectedQuestionId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionPreviewer,
          {
            isPreviewerOpen,
            setIsPreviewerOpen,
            previewReviewer,
            allQuestions: (reviewStructure == null ? void 0 : reviewStructure.questions) || [],
            allSections: (reviewStructure == null ? void 0 : reviewStructure.sections) || [],
            order: (reviewStructure == null ? void 0 : reviewStructure.cycle.order) || []
          }
        )
      ]
    }
  );
};
const ReviewBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  questions,
  handleDragDrop,
  cycle,
  setQuestion,
  refreshReviewStructure,
  setSelectedQuestionId,
  setIsMoveToSectionOpen,
  sections,
  growthFactors,
  isEditable,
  reviewStructureValidating
}) => {
  const sectionOptions = useCycleSectionOptions(
    section,
    setSection,
    setIsSectionModalOpen,
    cycle,
    refreshReviewStructure
  );
  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };
  return /* @__PURE__ */ jsx(
    SectionBuilder,
    {
      section,
      sectionOptions,
      dragQuestionComponent: /* @__PURE__ */ jsx(DragDropContext, { onDragEnd: handleDragDrop, children: /* @__PURE__ */ jsx(Droppable, { droppableId: "ROOT", type: "group", isDropDisabled: false, children: (provided) => /* @__PURE__ */ jsxs("div", __spreadProps(__spreadValues({}, provided.droppableProps), { ref: provided.innerRef, children: [
        questions && questions.map((question, idx) => /* @__PURE__ */ jsx(
          Draggable,
          {
            isDragDisabled: !isEditable || reviewStructureValidating,
            draggableId: question.id,
            index: idx,
            children: (provided2) => /* @__PURE__ */ jsx("div", __spreadProps(__spreadValues(__spreadValues({}, provided2.dragHandleProps), provided2.draggableProps), { ref: provided2.innerRef, children: /* @__PURE__ */ jsx(
              QuestionComponent,
              {
                question,
                cycle,
                setQuestion,
                setIsQuestionModalOpen,
                refreshReviewStructure,
                setSelectedQuestionId,
                setIsMoveToSectionOpen,
                hasSections: Boolean(sections && sections.length),
                growthFactors,
                isEditable
              }
            ) }))
          },
          question.id
        )),
        provided.placeholder
      ] })) }) }),
      newQuestionAction,
      showAction: isEditable
    }
  );
};
const QuestionComponent = ({
  question,
  cycle,
  setQuestion,
  setIsQuestionModalOpen,
  refreshReviewStructure,
  setSelectedQuestionId,
  setIsMoveToSectionOpen,
  hasSections,
  growthFactors,
  isEditable
}) => {
  const questionOptions = useCycleQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    cycle,
    refreshReviewStructure,
    setSelectedQuestionId,
    hasSections
  );
  return /* @__PURE__ */ jsx(
    QuestionBuilder,
    {
      questionOptions,
      question,
      growthFactors,
      showAction: isEditable
    }
  );
};
const CycleSetupQuestionBuilderLoader = () => {
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g10 }, spacing.mt20), children: [...Array(10)].map((e, i) => /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" })
  ] }, i)) });
};
