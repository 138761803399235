"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { UserCustomBenefitPaymentStatus } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const getBenefitPaymentStatusComponent = (status, statusSx) => {
  switch (status) {
    case UserCustomBenefitPaymentStatus.Pending:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ color: themeColors.Grey }, statusSx), children: UserCustomBenefitPaymentStatus.Pending })
      ] });
    case UserCustomBenefitPaymentStatus.Approved:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        /* @__PURE__ */ jsx(Typography, { sx: statusSx, children: UserCustomBenefitPaymentStatus.Approved })
      ] });
    case UserCustomBenefitPaymentStatus.Rejected:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { sx: statusSx, children: UserCustomBenefitPaymentStatus.Rejected })
      ] });
    default:
      return /* @__PURE__ */ jsx(Fragment, {});
  }
};
