"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { PendingPHCancellationActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/pending-ph-cancellation-action-item.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Timeaway } from "@/images/dashboard-icons/Timeaway.svg";
import { TodoCard } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components";
import {
  DomainTypes
} from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { iconSize } from "@/v2/styles/menu.styles";
export const PendingPHDeletionRequestTodo = ({
  todo,
  setLastChild,
  refreshTodos
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState(void 0);
  return /* @__PURE__ */ jsxs(Box, { children: [
    todo[DomainTypes.Absence].map((h, idx) => {
      var _a, _b;
      const title = polyglot.t("getDetailByDomain.holidayDeletion", {
        displayName: polyglot.t((_b = (_a = getCachedUserById(h.userId)) == null ? void 0 : _a.displayName) != null ? _b : "")
      });
      return /* @__PURE__ */ jsx(
        TodoCard,
        {
          icon: /* @__PURE__ */ jsx(Timeaway, __spreadValues({}, iconSize)),
          title: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: {
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%"
              },
              children: title
            }
          ),
          actionOnclick: () => {
            setSelectedRow(h);
          },
          showBorder: !(setLastChild && todo[DomainTypes.Absence].length === idx + 1),
          userAvatar: /* @__PURE__ */ jsx(UserAvatar, { userId: h.userId, size: "xxxsmall" })
        },
        `-${h.userId}-${idx}`
      );
    }),
    selectedRow && /* @__PURE__ */ jsx(
      PendingPHCancellationActionItem,
      {
        selectedRow,
        refresh: () => __async(void 0, null, function* () {
          if (refreshTodos) yield refreshTodos();
        }),
        afterClose: () => setSelectedRow(void 0)
      }
    )
  ] });
};
