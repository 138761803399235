"use strict";
import { DeviceTransitStatus } from "@v2/feature/device/device.interface";
import { getDeviceOwnerByDevicePossession } from "@v2/feature/device/device.util";
import {
  format,
  getDate,
  isBefore,
  isMonday,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  previousMonday
} from "date-fns";
import { KindTypes } from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { LocalDate } from "@/v2/util/local-date";
export const getDateFormation = (start, end) => {
  const startDate = new LocalDate(start).getDate();
  if (!end) return `${format(startDate, "d MMM")}`;
  else {
    const endDate = new LocalDate(end).getDate();
    if (isSameMonth(startDate, endDate)) return `${getDate(startDate)}-${format(endDate, "d MMM")}`;
    if (!isSameMonth(startDate, endDate)) return `${format(startDate, "d MMM")}-${format(endDate, "d MMM")}`;
  }
};
export const getDayMonthFormation = (start) => {
  const [_year, month, day] = start.split("-").map(Number);
  const placeholderYear = 1700;
  const date = new Date(placeholderYear, month - 1, day);
  const formatter = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" });
  return formatter.format(date);
};
export const getPlaceholderDate = (dateString) => {
  const [month, day] = dateString.split("-").map(Number);
  const placeholderYear = 1700;
  return new LocalDate(new Date(placeholderYear, month - 1, day)).toDateString();
};
export const isAnniversaryToday = (date, currentDate) => {
  const eventDate = new LocalDate(date).getDate();
  if (isSameDay(eventDate, currentDate)) return false;
  if (!isBefore(eventDate, currentDate)) return false;
  eventDate.setFullYear(currentDate.getFullYear());
  return isSameDay(eventDate, currentDate);
};
export function isAnniversaryInPeriod(date, weekRange) {
  const eventDate = new LocalDate(date).getDate();
  const startDate = new LocalDate(weekRange[0]).getDate();
  const endDate = new LocalDate(weekRange[1]).getDate();
  if (isWithinInterval(eventDate, { start: startDate, end: endDate })) return false;
  if (!isBefore(eventDate, endDate)) return false;
  eventDate.setFullYear(startDate.getFullYear());
  return isWithinInterval(eventDate, { start: startDate, end: endDate });
}
export const getWeekRange = () => {
  const today = /* @__PURE__ */ new Date();
  if (isMonday(today)) {
    const nextSunday = /* @__PURE__ */ new Date();
    nextSunday.setDate(nextSunday.getDate() + 6);
    return [new LocalDate(today).toDateString(), new LocalDate(nextSunday).toDateString()];
  }
  const lastMonday = new Date(previousMonday(today));
  const comingSunday = new Date(previousMonday(today));
  comingSunday.setDate(comingSunday.getDate() + 6);
  return [new LocalDate(lastMonday).toDateString(), new LocalDate(comingSunday).toDateString()];
};
export const getTodayToLastDayOfMonth = () => {
  const todayDate = /* @__PURE__ */ new Date();
  const firstDayOfNextMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 1);
  const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);
  return [new LocalDate(todayDate).toDateString(), new LocalDate(lastDayOfMonth).toDateString()];
};
export const getTodayAndDateAfter30Days = () => {
  const todayDate = new LocalDate();
  const dateAfter30Days = new LocalDate(todayDate.getDate().getTime() + 30 * 24 * 60 * 60 * 1e3);
  const todayDateString = todayDate.toDateString();
  const dateAfter30DaysString = dateAfter30Days.toDateString();
  return [todayDateString, dateAfter30DaysString];
};
export const getDatesBetweenMonths = (startDate, endDate) => {
  const start = new LocalDate(startDate);
  const end = new LocalDate(endDate);
  const result = [];
  while (start.toDateString() <= end.toDateString()) {
    result.push(start.toDateString());
    start.getDate().setDate(start.getDate().getDate() + 1);
  }
  return result;
};
export const editGridLayout = (order, preferenceLookup) => {
  let x = 0;
  let y = 0;
  const layout = [];
  order == null ? void 0 : order.forEach((widget) => {
    const widgetDetails = preferenceLookup[widget];
    const w = widgetDetails.size === "small" ? 4 : 8;
    if (x + w > 12) {
      x = 0;
      y += 1;
    }
    layout.push({
      x,
      y,
      w,
      h: 1,
      i: widget,
      static: widgetDetails.isStatic,
      isBounded: true,
      isResizable: false
    });
    x += w;
  });
  return layout;
};
export function getStringArrayInOrder(arr) {
  const sortedArray = arr.sort((a, b) => {
    if (a.y === b.y) {
      return a.x - b.x;
    }
    return a.y - b.y;
  });
  let result = [];
  let prevY = sortedArray[0].y;
  for (const item of sortedArray) {
    if (item.y !== prevY) {
      prevY = item.y;
    }
    result.push(item.i);
  }
  return result;
}
export const getDetailByDomain = (item, getCachedUserById, currentUser, polyglot) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
  switch (item.kind) {
    case KindTypes.IncompleteTasks: {
      const { name, dueDate } = item;
      return polyglot.t("getDetailByDomain.incompleteTask", {
        name: polyglot.t(name),
        date: format(new LocalDate(dueDate).getDate(), "d MMM")
      });
    }
    case KindTypes.PendingRequest: {
      const { cancellationRequested, userId, policyName, start, end } = item;
      return cancellationRequested ? polyglot.t("getDetailByDomain.pendingCancellationRequest", {
        displayName: polyglot.t((_b = (_a = getCachedUserById(userId)) == null ? void 0 : _a.displayName) != null ? _b : ""),
        policyName: policyName ? polyglot.t(policyName) : null,
        date: getDateFormation(start, end)
      }) : polyglot.t("getDetailByDomain.pendingRequest", {
        displayName: polyglot.t((_d = (_c = getCachedUserById(userId)) == null ? void 0 : _c.displayName) != null ? _d : ""),
        policyName: policyName ? polyglot.t(policyName) : null,
        date: getDateFormation(start, end)
      });
    }
    case KindTypes.PendingPHCancellation: {
      const { userId } = item;
      return polyglot.t("getDetailByDomain.holidayDeletion", {
        displayName: polyglot.t((_f = (_e = getCachedUserById(userId)) == null ? void 0 : _e.displayName) != null ? _f : "")
      });
    }
    case KindTypes.SubmittedAttendance: {
      const { userId, weekNo } = item;
      return polyglot.t("getDetailByDomain.attendanceAwaiting", {
        displayName: polyglot.t((_h = (_g = getCachedUserById(userId)) == null ? void 0 : _g.displayName) != null ? _h : ""),
        weekNo
      });
    }
    case KindTypes.UnfinishedAttendance: {
      const { userId, logDate } = item;
      return polyglot.t("getDetailByDomain.unfinishedShift", {
        displayName: polyglot.t((_j = (_i = getCachedUserById(userId)) == null ? void 0 : _i.displayName) != null ? _j : ""),
        date: getDateFormation(logDate)
      });
    }
    case KindTypes.MissingDocuments: {
      const { name } = item;
      return polyglot.t("getDetailByDomain.missingDocument", { name: polyglot.t(name) });
    }
    case KindTypes.PendingContracts: {
      return polyglot.t("getDetailByDomain.pendingContracts");
    }
    case KindTypes.PendingReviews: {
      const { dueDate, userId, cycleName, reviewType } = item;
      return polyglot.t("getDetailByDomain.completeReview", {
        cycleName,
        date: format(new LocalDate(dueDate).getDate(), "d MMM yyyy"),
        user: (_k = getCachedUserById(userId)) == null ? void 0 : _k.displayName,
        reviewType
      });
    }
    case KindTypes.PendingSurveys: {
      const { dueDate, cycleName } = item;
      return polyglot.t("getDetailByDomain.completeSurvey", {
        cycleName,
        date: format(new LocalDate(dueDate).getDate(), "d MMM yyyy")
      });
    }
    case KindTypes.PendingBenefitPayment: {
      const { userId } = item;
      return currentUser.userId === userId ? "You requested a benefit payment" : `${polyglot.t((_m = (_l = getCachedUserById(userId)) == null ? void 0 : _l.displayName) != null ? _m : "")} requested a benefit payment`;
    }
    case KindTypes.BenefitRequest: {
      const { userId } = item;
      return currentUser.userId === userId ? "You requested a benefit change" : `${polyglot.t((_o = (_n = getCachedUserById(userId)) == null ? void 0 : _n.displayName) != null ? _o : "")} requested a benefit change`;
    }
    case KindTypes.ExpenseRequest: {
      const { userId } = item;
      return currentUser.userId === userId ? "You requested an expense" : `${polyglot.t((_q = (_p = getCachedUserById(userId)) == null ? void 0 : _p.displayName) != null ? _q : "")} requested an expense`;
    }
    case KindTypes.InvoiceRequest: {
      const { userId } = item;
      return currentUser.userId === userId ? "You requested an invoice" : `${polyglot.t((_s = (_r = getCachedUserById(userId)) == null ? void 0 : _r.displayName) != null ? _s : "")} requested an invoice`;
    }
    case KindTypes.SubmittedRequestForm: {
      const { formName, status } = item;
      return status === "pending" ? polyglot.t("getDetailByDomain.awaitingApproval", {
        formName
      }) : polyglot.t("getDetailByDomain.requestApproved", {
        formName
      });
    }
    case KindTypes.DeviceTransit: {
      const { modelName, status } = item;
      if (currentUser.userId === item.senderPossession.possessionId && status === DeviceTransitStatus.Pending) {
        return polyglot.t("getDetailByDomain.deviceTransitSender", {
          model: modelName,
          receiverName: getDeviceOwnerByDevicePossession(item.receiverPossession, null, getCachedUserById)
        });
      } else if (currentUser.userId === item.receiverPossession.possessionId && status === DeviceTransitStatus.Shipping) {
        return polyglot.t("getDetailByDomain.deviceTransitReceiver", {
          model: modelName
        });
      }
      return "";
    }
    default:
      return "";
  }
};
