"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { LocalDate } from "@v2/util/local-date";
import axios from "axios";
export class CustomBenefitAPI {
  static createCustomBenefit(benefitData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/custom-benefit", benefitData)).data;
    });
  }
  static repayLoanPayment(userId, customBenefitId, userBenefitId, paymentValue) {
    return __async(this, null, function* () {
      yield axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${userBenefitId}/repay-loan-payment`,
        { paymentValue }
      );
    });
  }
  static getAllCustomBenefits() {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get("/apiv2/custom-benefit")) == null ? void 0 : _a.data;
    });
  }
  static updateCustomBenefitGeneral(id, benefitData) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/custom-benefit/${id}/general`, benefitData)) == null ? void 0 : _a.data;
    });
  }
  static updateCustomBenefitBalance(id, benefitData) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/custom-benefit/${id}/balance`, benefitData)) == null ? void 0 : _a.data;
    });
  }
  static updateCustomBenefitMembers(id, benefitData) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/custom-benefit/${id}/members`, benefitData)) == null ? void 0 : _a.data;
    });
  }
  static updateCustomBenefitPayroll(id, benefitData) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/custom-benefit/${id}/payroll`, benefitData)) == null ? void 0 : _a.data;
    });
  }
  static updateCustomBenefitPayment(id, benefitData) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/custom-benefit/${id}/payment`, benefitData)) == null ? void 0 : _a.data;
    });
  }
  static updateCustomBenefitApproval(id, benefitData) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/custom-benefit/${id}/approval`, benefitData)) == null ? void 0 : _a.data;
    });
  }
  static deleteBenefitById(benefitId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.delete(`/apiv2/custom-benefit/${benefitId}`)) == null ? void 0 : _a.data;
    });
  }
  static addUserToCustomBenefit(userId, customBenefitId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}`, data);
    });
  }
  static updateUserCustomBenefitById(userId, customBenefitId, id, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${id}`, update);
    });
  }
  static deleteUserCustomBenefit(userId, customBenefitId, id) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${id}`);
    });
  }
  static approveOrRejectCustomBenefitPaymentById(customBenefitId, userId, userCustomBenefitId, paymentId, status) {
    return __async(this, null, function* () {
      yield axios.patch(
        `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/payments/${paymentId}/status`,
        { status }
      );
    });
  }
  static userCustomBenefitOneOffPayment(userId, customBenefitId, userBenefitId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${userBenefitId}`, {
        amount: data.amount,
        date: new LocalDate().toDateString(),
        notes: data.notes
      });
    });
  }
  static requestUserBenefitLoan(userId, customBenefitId, userCustomBenefitId, data) {
    return __async(this, null, function* () {
      return (yield axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/loan-request`,
        data
      )).data;
    });
  }
  static requestUserBenefitChange(userId, customBenefitId, userCustomBenefitId, data) {
    return __async(this, null, function* () {
      return (yield axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/change-request`,
        data
      )).data;
    });
  }
  static requestUserBenefitOptOut(userId, customBenefitId, userCustomBenefitId, data) {
    return __async(this, null, function* () {
      return (yield axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/opt-out`,
        data
      )).data;
    });
  }
  static approveUserBenefitRequest(userId, customBenefitId, requestId, force = false) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/requests/${requestId}/approve`, {
        force
      });
    });
  }
  static rejectUserBenefitRequest(userId, customBenefitId, requestId, force = false) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/requests/${requestId}/reject`, {
        force
      });
    });
  }
  static checkUserBenefitMembership(userId, expensePolicyId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/custom-benefit/users/${userId}/policy/${expensePolicyId}/check-membership`)) == null ? void 0 : _a.data;
    });
  }
}
export class CustomBenefitEndpoints {
  static getAllUserCustomBenefits(benefitId) {
    return {
      url: `/apiv2/custom-benefit/${benefitId}/users`
    };
  }
  static getCustomBenefitById(benefitId) {
    return {
      url: `/apiv2/custom-benefit/${benefitId}`
    };
  }
  static getCustomBenefitPaymentAndRemainingBalance(benefitId, userId, userCustomBenefitId, benefitPaymentId) {
    return {
      url: `/apiv2/custom-benefit/${benefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/payments/${benefitPaymentId}`
    };
  }
  static getAllUserCustomBenefitsByBenefitId(userId, customBenefitId) {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/by-user/${userId}`
    };
  }
  static canManageBenefitForUser(customBenefitId, userId) {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/can-manage`
    };
  }
  static allEffectiveUserBenefitsByUserId(userId) {
    return {
      url: `/apiv2/custom-benefit/by-user/${userId}/effective`
    };
  }
  static getUserCustomBenefitRequestsByBenefitId(userId, customBenefitId, userCustomBenefitId) {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/requests`
    };
  }
  static getUserCustomBenefitRequestById(userId, customBenefitId, userCustomBenefitId, requestId) {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/requests/${requestId}`
    };
  }
  static getUserCustomBenefitRemainingAllowance(userId, customBenefitId, userCustomBenefitId, date) {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/remaining/${date}`
    };
  }
}
