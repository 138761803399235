"use strict";
import { jsPDF } from "jspdf";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { captureExcel } from "@/v2/util/export-reports.util";
import { LocalDate } from "@/v2/util/local-date";
import { stripHtml } from "@/v2/util/string.util";
const getAnswerLabel = (question, answer) => {
  var _a, _b, _c, _d, _e, _f;
  if (!(answer == null ? void 0 : answer.answer)) return "";
  if (question.type === QuestionType.ScaleQuestion)
    return `${(_b = (_a = question.scaleConfig) == null ? void 0 : _a.points) == null ? void 0 : _b[answer.answer]} (${((_d = (_c = question.scaleConfig) == null ? void 0 : _c.value) == null ? void 0 : _d[answer.answer]) || 0} out of ${Math.max(...Object.values(((_e = question.scaleConfig) == null ? void 0 : _e.value) || []).map(Number))})`;
  if (question.type === QuestionType.MultipleAnswer)
    return answer.answer.split(",").map((ans) => {
      var _a2;
      return (_a2 = question.answerOptions) == null ? void 0 : _a2[ans];
    }).filter(Boolean).join(", ");
  if (question.type === QuestionType.SingleAnswer) return ((_f = question.answerOptions) == null ? void 0 : _f[answer.answer]) || "NA";
  return "";
};
export const generateExcel = (results, reviewCycle, reportName, revieweeId, getCachedUserById) => {
  if (!results) return [];
  const fromDate = new LocalDate(reviewCycle.timelineSettings.startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" });
  const reviewee = getCachedUserById(Number(revieweeId));
  const revieweeName = (reviewee == null ? void 0 : reviewee.displayName) || "NA";
  const { questionResponseSummary } = results;
  const data = [];
  const addBoldHeader = (text) => {
    data.push([{ v: text, s: { font: { bold: true } } }]);
  };
  addBoldHeader(`${reviewCycle.displayName} - ${fromDate}` || `Review cycle - ${fromDate}`);
  addBoldHeader(`You are reviewing: ${revieweeName}`);
  addBoldHeader("");
  data.push([
    "Cycle name",
    "Reviewee",
    "Reviewee department",
    "Question",
    "Factor",
    "Role",
    "Name",
    "Answer",
    "Comment"
  ]);
  (questionResponseSummary || []).forEach((question) => {
    const { questionText, factor, answers } = question;
    answers.forEach((answer) => {
      var _a, _b, _c, _d, _e;
      const { answerType, comment } = answer;
      const username = ((_a = results == null ? void 0 : results.visibilitySettings) == null ? void 0 : _a.hidePeerAuthor) && !answer.reviewerId ? "Author hidden" : ((_b = getCachedUserById(answer.reviewerId)) == null ? void 0 : _b.displayName) || "NA";
      const commentText = comment ? stripHtml(comment) : "";
      const answerLabel = getAnswerLabel(question, answer);
      const cycleName = reviewCycle.displayName;
      const revieweeDept = (_e = (_d = (_c = reviewee == null ? void 0 : reviewee.role) == null ? void 0 : _c.department) == null ? void 0 : _d.name) != null ? _e : "NA";
      data.push([
        cycleName,
        revieweeName,
        revieweeDept,
        questionText,
        factor,
        answerType,
        username,
        answerLabel,
        commentText
      ]);
    });
  });
  captureExcel(data, reportName);
};
export const generatePdfReport = (results, reviewCycle, reportName, revieweeId, getCachedUserById) => {
  var _a;
  if (!results) return;
  const doc = new jsPDF("p", "mm", "a4");
  doc.setFont("Times-Roman", "bold");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 14;
  const maxWidth = pageWidth - margin * 2;
  const lineHeight = 8;
  const textLineHeight = 6;
  let yPosition = 22;
  const checkAndAddPage = () => {
    if (yPosition + lineHeight > pageHeight - margin) {
      doc.addPage();
      yPosition = margin + lineHeight;
    }
  };
  const revieweeName = ((_a = getCachedUserById(Number(revieweeId))) == null ? void 0 : _a.displayName) || "NA";
  const generateCoverPage = () => {
    doc.setFont("Times-Roman", "bold");
    doc.setFontSize(24);
    doc.setTextColor(0, 0, 0);
    doc.text(reviewCycle.displayName, pageWidth / 2, pageHeight / 2 - 30, { align: "center" });
    doc.setFont("Times-Roman", "normal");
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Reviewee: ${revieweeName}`, pageWidth / 2, pageHeight / 2, { align: "center" });
    doc.setFontSize(12);
    const startDate = new LocalDate(reviewCycle.timelineSettings.startDate).toLocaleDateString();
    doc.text(`Start date: ${startDate}`, pageWidth / 2, pageHeight / 2 + 8, { align: "center" });
    doc.text(`Report generated: ${(/* @__PURE__ */ new Date()).toLocaleDateString()}`, pageWidth / 2, pageHeight / 2 + 16, {
      align: "center"
    });
    doc.setLineWidth(0.3);
    doc.setDrawColor(100, 100, 100);
    doc.line(margin, pageHeight / 2 - 40, pageWidth - margin, pageHeight / 2 - 40);
    doc.line(margin, pageHeight / 2 + 25, pageWidth - margin, pageHeight / 2 + 25);
    doc.addPage();
  };
  const generateSummarySection = () => {
    doc.setFont("Times-Roman", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("Review summary", margin, yPosition);
    yPosition += 10;
    doc.setFont("Times-Roman", "normal");
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(`Reviewee name: ${revieweeName}`, margin, yPosition);
    yPosition += textLineHeight;
    doc.text(
      `Start date: ${new LocalDate(reviewCycle.timelineSettings.startDate).toLocaleDateString()}`,
      margin,
      yPosition
    );
    yPosition += textLineHeight;
    const totalQuestions = results.questionResponseSummary.length;
    doc.text(`Total questions answered: ${totalQuestions}`, margin, yPosition);
    yPosition += textLineHeight;
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 0, 0);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += textLineHeight;
  };
  generateCoverPage();
  generateSummarySection();
  doc.setFont("Times-Roman", "bold");
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  yPosition += 6;
  doc.text("Review result", margin, yPosition);
  yPosition += 6;
  results.questionResponseSummary.forEach((question, idx) => {
    yPosition += lineHeight;
    const { questionText, answers, type } = question;
    doc.setFont("Times-Roman", "bold");
    doc.setFontSize(12);
    const splitQuestionText = doc.splitTextToSize(`${questionText}`, maxWidth);
    splitQuestionText.forEach((line) => {
      checkAndAddPage();
      doc.text(line, margin, yPosition);
      yPosition += textLineHeight;
    });
    yPosition += 1;
    answers.forEach((answer) => {
      var _a2, _b;
      const { comment, answerType } = answer;
      const username = ((_a2 = results == null ? void 0 : results.visibilitySettings) == null ? void 0 : _a2.hidePeerAuthor) && !answer.reviewerId ? "Author hidden" : ((_b = getCachedUserById(answer.reviewerId)) == null ? void 0 : _b.displayName) || "NA";
      const commentText = comment ? stripHtml(comment) : "";
      const answerLabel = type === QuestionType.OpenEnded ? commentText : getAnswerLabel(question, answer);
      doc.setFont("Times-Roman", "normal");
      doc.setFontSize(12);
      const answerText = `[${username} - ${answerType}] ${answerLabel}`;
      const splitAnswerText = doc.splitTextToSize(answerText, maxWidth);
      splitAnswerText.forEach((line) => {
        checkAndAddPage();
        doc.text(line, margin, yPosition);
        yPosition += textLineHeight;
      });
      if (commentText && type !== QuestionType.OpenEnded) {
        const splitCommentText = doc.splitTextToSize(`[Comment]: ${commentText}`, maxWidth);
        splitCommentText.forEach((line) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });
      }
      yPosition += 2;
    });
    yPosition += lineHeight / 2;
    checkAndAddPage();
    if (idx !== results.questionResponseSummary.length - 1) {
      doc.setLineWidth(0.5);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += lineHeight / 2;
    }
  });
  if ((results == null ? void 0 : results.comments) && (results == null ? void 0 : results.comments.length) > 0) {
    yPosition += textLineHeight * 2;
    doc.setFont("Times-Roman", "bold");
    doc.setFontSize(14);
    doc.text("Review comments", margin, yPosition);
    yPosition += 4;
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += textLineHeight;
    results.comments.forEach((commentObj) => {
      var _a2, _b;
      const commentText = stripHtml((_a2 = commentObj == null ? void 0 : commentObj.comment) != null ? _a2 : "");
      const isPublic = commentObj.isPublic ? "Public comment" : "Private comment";
      const username = ((_b = getCachedUserById(commentObj.commentBy)) == null ? void 0 : _b.displayName) || "NA";
      doc.setFont("Times-Roman", "normal");
      doc.setFontSize(12);
      const commentHeader = `[${isPublic} by ${username}]:`;
      const splitCommentHeader = doc.splitTextToSize(commentHeader, maxWidth);
      splitCommentHeader.forEach((line) => {
        checkAndAddPage();
        doc.text(line, margin, yPosition);
        yPosition += textLineHeight;
      });
      const splitCommentText = doc.splitTextToSize(commentText, maxWidth);
      splitCommentText.forEach((line) => {
        checkAndAddPage();
        doc.text(line, margin, yPosition);
        yPosition += textLineHeight;
      });
      yPosition += lineHeight;
    });
  }
  doc.save(`${reportName}.pdf`);
};
