"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { UnfinishedAttendanceShiftDrawer } from "@/v2/feature/attendance/components/unfinished-attendance-shift-drawer.component";
import { AttendanceShiftAPI } from "@/v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
export const ShiftActionItem = ({
  selectedRow,
  refresh,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [showMessage] = useMessage();
  const getShiftById = useCallback(() => __async(void 0, null, function* () {
    try {
      const shift = yield AttendanceShiftAPI.getShiftById(Number(selectedRow.id), Number(selectedRow.userId));
      setSelectedShift(shift);
      setIsOpen(true);
    } catch (error) {
      console.error(error);
      showMessage(
        polyglot.t("ShiftActionItem.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
      setSelectedShift(null);
    }
  }), [selectedRow.id, selectedRow.userId, showMessage, polyglot]);
  useEffect(() => {
    getShiftById();
  }, [getShiftById]);
  return /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
    UnfinishedAttendanceShiftDrawer,
    {
      isOpen,
      setIsOpen,
      shift: selectedShift,
      onClose: () => {
        setSelectedShift(null);
        afterClose == null ? void 0 : afterClose();
      },
      refresh
    }
  ) });
};
