"use strict";
import { jsx } from "react/jsx-runtime";
import { TimeDrawerContent } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-drawer-content.component";
export const TimeActionItem = ({
  selectedRow,
  refresh,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(
    TimeDrawerContent,
    {
      absenceId: selectedRow.id,
      userId: selectedRow.userId,
      refresh,
      afterClose
    }
  );
};
