"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { helperTextSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { ReactComponent as Comment } from "@/images/growth/Comment.svg";
import { ReactComponent as Actions } from "@/images/side-bar-icons/Actions.svg";
import { ReactComponent as TrashIcon } from "@/images/side-bar-icons/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
export const SelectWorkOptions = ({
  formik,
  logDate,
  attendanceSchedule,
  entries,
  setEntries,
  showErrorMessage
}) => {
  const { polyglot } = usePolyglot();
  const [visibleNotes, setVisibleNotes] = useState(entries.map((entry) => !!entry.notes));
  const TypeOptions = useMemo(
    () => attendanceSchedule.attendanceTypesAllowed.map((type) => ({
      value: type.id,
      label: type.name
    })),
    [attendanceSchedule == null ? void 0 : attendanceSchedule.attendanceTypesAllowed]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    entries && entries.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g40 }, children: entries.map((r, index) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t;
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s1, alignItems: "flex-end" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { flex: 1.25 }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "typeId",
              label: polyglot.t("AttendanceDomain.type"),
              options: TypeOptions,
              value: r.typeId,
              onChange: (e) => {
                const updatedEntries = [...entries];
                updatedEntries[index].typeId = e.target.value;
                setEntries(updatedEntries);
              },
              error: ((_b = (_a = formik.touched.attendanceEntries) == null ? void 0 : _a[index]) == null ? void 0 : _b.typeId) && Boolean((_d = (_c = formik.errors.attendanceEntries) == null ? void 0 : _c[index]) == null ? void 0 : _d.typeId)
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { flex: 0.75 }, children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "startHour",
              label: polyglot.t("AttendanceDomain.from"),
              value: r.startHour,
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${logDate}T${time}:00`);
                  const updatedEntries = [...entries];
                  updatedEntries[index].startHour = date.toFullString();
                  updatedEntries[index].startHourTimestamp = date.getDate();
                  setEntries(updatedEntries);
                }
              },
              fullWidth: true,
              error: ((_f = (_e = formik.touched.attendanceEntries) == null ? void 0 : _e[index]) == null ? void 0 : _f.startHour) && Boolean((_h = (_g = formik.errors.attendanceEntries) == null ? void 0 : _g[index]) == null ? void 0 : _h.startHour)
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { flex: 0.75 }, children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "endHour",
              label: polyglot.t("AttendanceDomain.to"),
              value: r.endHour,
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${logDate}T${time}:00`);
                  const updatedEntries = [...entries];
                  updatedEntries[index].endHour = date.toFullString();
                  updatedEntries[index].endHourTimestamp = date.getDate();
                  setEntries(updatedEntries);
                }
              },
              fullWidth: true,
              error: ((_j = (_i = formik.touched.attendanceEntries) == null ? void 0 : _i[index]) == null ? void 0 : _j.endHour) && Boolean((_l = (_k = formik.errors.attendanceEntries) == null ? void 0 : _k[index]) == null ? void 0 : _l.endHour)
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { flex: 0.25 }, children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: [
                ...!visibleNotes[index] ? [
                  {
                    icon: /* @__PURE__ */ jsx(Comment, __spreadValues({}, iconSize)),
                    handler: () => {
                      setVisibleNotes((prev) => {
                        const newNotes = [...prev];
                        newNotes[index] = !newNotes[index];
                        return newNotes;
                      });
                    },
                    label: polyglot.t("General.note"),
                    disabled: false
                  }
                ] : [],
                {
                  icon: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)),
                  handler: () => {
                    setEntries(entries.filter((e, i) => i !== index));
                    setVisibleNotes((prev) => prev.filter((_, i) => i !== index));
                  },
                  label: polyglot.t("General.remove"),
                  disabled: false
                }
              ],
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "light",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(Actions, __spreadValues({}, iconSize))
              }
            }
          ) })
        ] }),
        visibleNotes[index] && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "notes",
            label: polyglot.t("General.note"),
            value: r.notes,
            type: "text",
            inputRef: (node) => {
              if (node && !node.__hasFocused && !r.notes) {
                node.__hasFocused = true;
                requestAnimationFrame(() => {
                  const inputElement = node.focus ? node : node.querySelector("input");
                  if (inputElement && document.activeElement !== inputElement) {
                    inputElement.focus();
                  }
                });
              }
            },
            error: ((_n = (_m = formik.touched.attendanceEntries) == null ? void 0 : _m[index]) == null ? void 0 : _n.notes) && Boolean((_p = (_o = formik.errors.attendanceEntries) == null ? void 0 : _o[index]) == null ? void 0 : _p.notes),
            helperText: ((_r = (_q = formik.touched.attendanceEntries) == null ? void 0 : _q[index]) == null ? void 0 : _r.notes) && ((_t = (_s = formik.errors.attendanceEntries) == null ? void 0 : _s[index]) == null ? void 0 : _t.notes),
            onChange: (e) => {
              const updatedEntries = [...entries];
              updatedEntries[index].notes = e.target.value;
              setEntries(updatedEntries);
            },
            clearText: () => {
              const updatedEntries = [...entries];
              updatedEntries[index].notes = "";
              setEntries(updatedEntries);
              setVisibleNotes((prev) => {
                const newNotes = [...prev];
                newNotes[index] = !newNotes[index];
                return newNotes;
              });
            }
          }
        )
      ] }, index);
    }) }),
    showErrorMessage && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, helperTextSx), { color: themeColors.darkRed, mb: spacing.m10 }), children: polyglot.t("AttendanceDomain.errors.makeSureAllAreValid") }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s2 }, children: /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        colorVariant: "secondary",
        sizeVariant: "filter",
        onClick: () => {
          setEntries([
            ...entries.map((e, index) => __spreadProps(__spreadValues({}, e), { priority: index })),
            {
              typeId: "",
              startHour: "",
              endHour: "",
              startHourTimestamp: null,
              endHourTimestamp: null,
              priority: entries.length,
              notes: ""
            }
          ]);
          setVisibleNotes((prev) => [...prev, false]);
        },
        children: [
          "+ ",
          polyglot.t("General.add")
        ]
      }
    ) })
  ] });
};
