"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  USER_BENEFITS_CUSTOM_BENEFIT_ROUTE,
  USER_BENEFITS_INSURANCE_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_PENSION_TAB
} from "@/lib/routes";
import { BenefitsUserOverviewPage } from "@v2/feature/benefits/benefits-user-overview.page";
import { useCallback, useEffect, useState } from "react";
import useMessage from "@/hooks/notification.hook";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { nestErrorMessage } from "@/lib/errors";
import { useUserProfileConfig } from "@v2/feature/user/features/user-profile/user-profile.hook";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import useScopes from "@/hooks/scopes.hook";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { UserPensionRouter } from "@/component/dashboard/userDetails/pension/user-pension.router";
import { InsuranceUserRouter } from "@v2/feature/benefits/subfeature/insurance/insurance-user.router";
import { CustomBenefitUserRouter } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit-user.router";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
export const UserBenefitsRouter = ({ userId, pensionScopes, handleUpdate }) => {
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionOwnerScope = hasScopes(["pension"], scopesContext);
  const hasInsuranceOwnerScope = hasScopes(["insurance"], scopesContext);
  const { data: userBenefits, isLoading: loadingCustomBenefits, mutate: refreshUserCBenefits } = useApiClient(
    hasInsuranceOwnerScope ? CustomBenefitEndpoints.allEffectiveUserBenefitsByUserId(userId) : null,
    {
      suspense: false
    }
  );
  const [employeePension, setEmployeePension] = useState(null);
  const [userInsurance, setUserInsurance] = useState(null);
  const [showMessage] = useMessage();
  const [loadingPension, setLoadingPension] = useState(false);
  const [loadingInsurance, setLoadingInsurance] = useState(false);
  const { userOverviewConfig, refreshUserOverviewConfig } = useUserProfileConfig(userId);
  const autoEnrollmentCallback = useCallback(() => __async(void 0, null, function* () {
    if (!hasPensionOwnerScope) return;
    try {
      setLoadingPension(true);
      const [employeePension2] = yield Promise.all([
        PensionAPI.getEmployeePension(userId),
        yield refreshUserOverviewConfig()
      ]);
      setEmployeePension(employeePension2 != null ? employeePension2 : null);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoadingPension(false);
    }
  }), [hasPensionOwnerScope, userId, refreshUserOverviewConfig, showMessage]);
  const fetchUserInsurance = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceOwnerScope) return;
    try {
      setLoadingInsurance(true);
      const userInsurance2 = yield InsuranceAPI.getUserInsurancePolicyById(userId, true);
      setUserInsurance(userInsurance2);
    } catch (error) {
      showMessage(`Could not get insurance policy details. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoadingInsurance(false);
    }
  }), [hasInsuranceOwnerScope, userId, showMessage]);
  const refreshCustomBenefits = useCallback(() => __async(void 0, null, function* () {
    if (refreshUserCBenefits) yield refreshUserCBenefits();
  }), [refreshUserCBenefits]);
  useEffect(() => {
    if (userOverviewConfig == null ? void 0 : userOverviewConfig.inPension) {
      autoEnrollmentCallback();
    }
  }, [autoEnrollmentCallback, userOverviewConfig == null ? void 0 : userOverviewConfig.inPension]);
  useEffect(() => {
    fetchUserInsurance();
  }, [fetchUserInsurance]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    (userOverviewConfig == null ? void 0 : userOverviewConfig.contractType) === UserContractTypeEnum.Payrolled && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: pensionScopes, context: scopesContext, path: USER_PENSION_TAB, children: /* @__PURE__ */ jsx(UserPensionRouter, { userId, onUpdate: handleUpdate }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance"], context: scopesContext, path: USER_BENEFITS_INSURANCE_ROUTE, children: /* @__PURE__ */ jsx(
      InsuranceUserRouter,
      {
        userId,
        userInsurance,
        refresh: fetchUserInsurance,
        loading: loadingInsurance
      }
    ) }),
    userOverviewConfig.inCustomBenefits && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance"], context: scopesContext, path: USER_BENEFITS_CUSTOM_BENEFIT_ROUTE, children: /* @__PURE__ */ jsx(
      CustomBenefitUserRouter,
      {
        userId,
        userBenefits: userBenefits != null ? userBenefits : [],
        loading: !!loadingCustomBenefits,
        refreshCustomBenefits
      }
    ) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: USER_BENEFITS_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(
      BenefitsUserOverviewPage,
      {
        userId,
        userPension: employeePension,
        userInsurance,
        userBenefits: userBenefits != null ? userBenefits : [],
        userOverviewConfig,
        loading: loadingInsurance || loadingPension || !!loadingCustomBenefits
      }
    ) }),
    /* @__PURE__ */ jsx(Redirect, { to: USER_BENEFITS_OVERVIEW_ROUTE })
  ] });
};
