"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltMdmAppsPolicyEditor } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-apps-policy-editor.component";
import { ZeltMdmLoginItemsPolicyEditor } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-login-items-policy-editor.component";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DevicePolicyZeltType } from "@/v2/feature/device/device.interface";
export const ZeltMdmAppsPolicyViewer = ({
  companyPolicies,
  updatePolicies,
  configurablePoliciesSelected
}) => {
  const [globalState] = useContext(GlobalContext);
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isOpenLoginItemsEditor, setIsOpenLoginItemsEditor] = useState(false);
  const initialValues = {
    apps: companyPolicies.filter((element) => element.type === DevicePolicyZeltType.apps && element.isInhouseMdm).filter((element) => !(element.externalId === 9 && globalState.user.company.companyId !== 618)).filter((element) => !(element.externalId === 18 && globalState.user.company.companyId !== 877)).filter((element) => !(element.externalId === 19 && globalState.user.company.companyId !== 618)).map((app) => {
      var _a, _b;
      const zeltManagedApp = {
        id: app.id,
        selected: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableApps) == null ? void 0 : _a.includes(app.id)) != null ? _b : false,
        name: app.name,
        externalId: app.externalId
      };
      return zeltManagedApp;
    })
  };
  const loginItemsPolicy = () => {
    var _a, _b;
    return {
      autoLaunchedApplicationDictionaryManaged: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableLoginItems) == null ? void 0 : _a.autoLaunchedApplicationDictionaryManaged) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableLoginItems) == null ? void 0 : _b.autoLaunchedApplicationDictionaryManaged : []
    };
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Managed apps" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setIsOpenEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      initialValues.apps.map((app) => /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: app.name }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, app.selected ? __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 })),
                children: app.selected ? "Enabled" : "Not set"
              }
            )
          ]
        }
      ))
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Open at login" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setIsOpenLoginItemsEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Applications:" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, loginItemsPolicy().autoLaunchedApplicationDictionaryManaged.length > 0 ? __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 })),
                children: loginItemsPolicy().autoLaunchedApplicationDictionaryManaged.length > 0 ? loginItemsPolicy().autoLaunchedApplicationDictionaryManaged.map((e) => e.Path).join(", ") : "Not set"
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpenEditor, setIsOpen: setIsOpenEditor, children: /* @__PURE__ */ jsx(
      ZeltMdmAppsPolicyEditor,
      {
        companyPolicies,
        configurablePoliciesSelected,
        updatePolicies,
        isOpen: isOpenEditor,
        setIsOpen: setIsOpenEditor
      }
    ) }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpenLoginItemsEditor, setIsOpen: setIsOpenLoginItemsEditor, children: /* @__PURE__ */ jsx(
      ZeltMdmLoginItemsPolicyEditor,
      {
        configurablePoliciesSelected,
        updatePolicies,
        isOpen: isOpenEditor,
        setIsOpen: setIsOpenEditor
      }
    ) })
  ] });
};
