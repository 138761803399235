"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { getDefaultBenefitImageByCategory } from "@v2/feature/benefits/benefits.util";
import { getLogoByProviderName } from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, matchPath, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import {
  BENEFITS_ME_ROUTE,
  CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  INSURANCE_ME_OVERVIEW_ROUTE,
  PENSION_ME_SUMMARY_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_BENEFITS_PENSION_SUMMARY_ROUTE
} from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
export const BenefitsUserOverviewPage = ({
  userId,
  userPension,
  userInsurance,
  userBenefits,
  userOverviewConfig,
  loading
}) => {
  var _a, _b;
  const routerHistory = useHistory();
  const location = useLocation();
  const [state] = useContext(GlobalContext);
  const isMePage = useMemo(() => {
    return location.pathname.startsWith(BENEFITS_ME_ROUTE);
  }, [location]);
  const showForCurrentUser = useMemo(() => userId === state.user.userId, [state.user.userId, userId]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(SettingsSectionContent, { title: "Overview", contentWidth: "100%", headerWidth: "100%", children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      loadingAll: loading,
      sections: [
        {
          contentWidth: "100%",
          items: [
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsxs(Box, { sx: flexContainerStyle, children: [
                userOverviewConfig.inPension && userPension && userPension.pensionProviderName && /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                      getPensionLogoByProviderName(userPension.pensionProviderName, 40),
                      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: (_a = userPension == null ? void 0 : userPension.displayName) != null ? _a : "Pension scheme" }),
                      " "
                    ] }),
                    boxSx: responsiveCardStyle,
                    boxAction: () => {
                      routerHistory.push(
                        matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE) ? generatePath(USER_BENEFITS_PENSION_SUMMARY_ROUTE, { userId }) : PENSION_ME_SUMMARY_ROUTE
                      );
                    },
                    contentItemsSets: [
                      {
                        name: (_b = PensionProvidersValueToLabel[userPension.pensionProviderName]) != null ? _b : userPension.pensionProviderName,
                        type: ContentItemType.chip,
                        textColor: "DarkGrey",
                        backgroundColor: "white",
                        border: "middle"
                      }
                    ]
                  },
                  "pension"
                ),
                userOverviewConfig.inInsurance && (userInsurance == null ? void 0 : userInsurance.policy) && /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                      getLogoByProviderName(userInsurance.policy.providerName, 40),
                      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: userInsurance.policy.providerName }),
                      " "
                    ] }),
                    boxSx: responsiveCardStyle,
                    boxAction: () => {
                      routerHistory.push(
                        matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE) ? generatePath(USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE, { userId }) : INSURANCE_ME_OVERVIEW_ROUTE
                      );
                    },
                    contentItemsSets: [
                      {
                        name: "Health Insurance",
                        type: ContentItemType.chip,
                        textColor: "DarkGrey",
                        backgroundColor: "white",
                        border: "middle"
                      }
                    ]
                  },
                  "insurance"
                ),
                userOverviewConfig.inCustomBenefits && (userBenefits == null ? void 0 : userBenefits.map((benefit, index) => {
                  var _a2, _b2, _c, _d;
                  return /* @__PURE__ */ jsx(
                    SettingsItemCard,
                    {
                      title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                        ((_a2 = benefit == null ? void 0 : benefit.customBenefit) == null ? void 0 : _a2.url) ? /* @__PURE__ */ jsx("img", { src: benefit.customBenefit.url, width: 40, height: 40, alt: "benefit-logo" }) : getDefaultBenefitImageByCategory((_b2 = benefit == null ? void 0 : benefit.customBenefit) == null ? void 0 : _b2.category, "50px", "auto"),
                        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: (_d = (_c = benefit.customBenefit) == null ? void 0 : _c.name) != null ? _d : "Custom benefit" }),
                        " "
                      ] }),
                      boxSx: responsiveCardStyle,
                      boxAction: () => {
                        if (matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)) {
                          routerHistory.push(
                            generatePath(USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE, {
                              userId,
                              id: benefit.customBenefitId
                            })
                          );
                        } else
                          routerHistory.push(
                            generatePath(CUSTOM_BENEFIT_OVERVIEW_ROUTE, { id: benefit.customBenefitId })
                          );
                      },
                      contentItemsSets: [
                        {
                          name: "Benefit",
                          type: ContentItemType.chip,
                          textColor: "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        }
                      ]
                    },
                    `benefit-${index}`
                  );
                }))
              ] })
            },
            {
              type: SectionItemType.Component,
              value: !userOverviewConfig.inPension && !userOverviewConfig.inInsurance && !userOverviewConfig.inCustomBenefits ? /* @__PURE__ */ jsx(
                EmptyStateBox,
                {
                  title: isMePage || showForCurrentUser ? "Your benefits" : "User benefits",
                  subtitle: isMePage || showForCurrentUser ? "You will see your benefits here. At the moment there is nothing to show" : "You will see employee's benefits here. At the moment there is nothing to show"
                }
              ) : /* @__PURE__ */ jsx(Fragment, {})
            }
          ]
        }
      ]
    }
  ) }) }) });
};
