"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { ReactComponent as Inprogress } from "@/images/fields/Inprogress.svg";
import { ReactComponent as Overdue } from "@/images/fields/Overdue.svg";
import { ReactComponent as HiddenEye } from "@/images/new-theme-icon/HiddenEye.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GrowthProgressLineChart } from "@/v2/feature/growth/shared/components/growth-progress-chart.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DATE_FORMAT = "dd MMM yyyy";
export const getStatus = () => {
  return {
    [CycleState.Draft]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: capitalize(CycleState.Draft),
        backgroundColor: "white",
        textColor: "DarkGrey",
        border: "light",
        textVariant: "caption"
      }
    ),
    [CycleState.Scheduled]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: capitalize(CycleState.Scheduled),
        backgroundColor: "DarkGrey",
        textColor: "white",
        textVariant: "caption"
      }
    ),
    [CycleState.Launching]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `${capitalize(CycleState.Launching)}...`,
        backgroundColor: "Background",
        textColor: "DarkGrey",
        textVariant: "caption"
      }
    ),
    [CycleState.Paused]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `${capitalize(CycleState.Paused)}`,
        backgroundColor: "Background",
        textColor: "DarkGrey",
        textVariant: "caption"
      }
    ),
    [CycleState.Ongoing]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: capitalize(CycleState.Ongoing),
        backgroundColor: "Green",
        textColor: "white",
        textVariant: "caption"
      }
    ),
    [CycleState.Finalising]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `${capitalize(CycleState.Finalising)}...`,
        backgroundColor: "Background",
        textColor: "DarkGrey",
        textVariant: "caption"
      }
    ),
    [CycleState.Completed]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: capitalize(CycleState.Completed),
        backgroundColor: "Background",
        textColor: "DarkGrey",
        textVariant: "caption"
      }
    )
  };
};
export const TitleStatusComponent = ({
  reviewCycle,
  reachType,
  showAction,
  action,
  published = false
}) => {
  var _a;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: [
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "title2",
          sx: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "450px", display: "block" },
          children: reachType === ReachType.Me ? reviewCycle.displayName || reviewCycle.internalName : (_a = reviewCycle.internalName) != null ? _a : "Overview"
        }
      ),
      reachType !== ReachType.Me && published ? /* @__PURE__ */ jsx(
        ChipComponent,
        {
          name: "Published",
          backgroundColor: "Background",
          textColor: "DarkGrey",
          border: "light",
          textVariant: "caption"
        }
      ) : getStatus()[reviewCycle.state]
    ] }),
    showAction && /* @__PURE__ */ jsx(Fragment, { children: action })
  ] });
};
export const binaryStatusChip = (reviewrObject, label, state) => {
  const { complete, show, overdue } = reviewrObject;
  const isOverdue = state === CycleState.Ongoing && overdue;
  const fillColor = complete ? themeColors.Green : isOverdue ? themeColors.RedDark : themeColors.Grey;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: show ? "flex" : "none",
        alignItems: "center",
        gap: spacing.g4,
        padding: spacing.p4,
        borderRadius: radius.br25,
        border: `1px solid ${themeColors.lightGrey}`
      },
      children: [
        complete ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: fillColor } })) : /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: fillColor } })),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: label })
      ]
    }
  );
};
export const polyStatusChip = (reviewrObject, label, state) => {
  const { complete, total, show, overdue } = reviewrObject;
  const isComplete = complete === total;
  const isOverdue = state === CycleState.Ongoing && overdue;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: show ? "flex" : "none",
        alignItems: "center",
        gap: spacing.g4,
        padding: spacing.p4,
        borderRadius: radius.br25,
        border: `1px solid ${themeColors.lightGrey}`
      },
      children: [
        isComplete ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : reviewrObject.complete > 0 ? isOverdue ? /* @__PURE__ */ jsx(Overdue, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Inprogress, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: isOverdue ? themeColors.RedDark : themeColors.Grey } })),
        reviewrObject.complete > 0 && /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", children: [
          reviewrObject.complete,
          "/",
          reviewrObject.total
        ] }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: label })
      ]
    }
  );
};
export const getParticipantProgressStatus = (participantObject, reviewCycle) => {
  const { reviewerSelect, state } = reviewCycle;
  return /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: [
    reviewerSelect.includes(ReviewerTypes.Self) && binaryStatusChip(participantObject.status.self, ReviewerTypes.Self, state),
    reviewerSelect.includes(ReviewerTypes.Manager) && participantObject.status.manager.total > 0 && polyStatusChip(participantObject.status.manager, ReviewerTypes.Manager, state),
    reviewerSelect.includes(ReviewerTypes.Upward) && participantObject.status.upward.total > 0 && polyStatusChip(participantObject.status.upward, ReviewerTypes.Upward, state),
    reviewerSelect.includes(ReviewerTypes.Peer) && participantObject.status.peer.total > 0 && polyStatusChip(participantObject.status.peer, ReviewerTypes.Peer, state)
  ] });
};
export const getReviewerTypesChips = (hideVisibility) => {
  return {
    [ReviewerTypes.Self]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
          ReviewerTypes.Self,
          " ",
          hideVisibility && /* @__PURE__ */ jsx(HiddenEye, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.white }))
        ] }),
        backgroundColor: "PastelSeaGreen",
        textColor: "white",
        textVariant: "caption"
      }
    ),
    [ReviewerTypes.Manager]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
          ReviewerTypes.Manager,
          " ",
          hideVisibility && /* @__PURE__ */ jsx(HiddenEye, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.white }))
        ] }),
        backgroundColor: "Pink",
        textColor: "white",
        textVariant: "caption"
      }
    ),
    [ReviewerTypes.Upward]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
          ReviewerTypes.Upward,
          " ",
          hideVisibility && /* @__PURE__ */ jsx(HiddenEye, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.white }))
        ] }),
        backgroundColor: "PastelPurple",
        textColor: "white",
        textVariant: "caption"
      }
    ),
    [ReviewerTypes.Peer]: /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
          ReviewerTypes.Peer,
          " ",
          hideVisibility && /* @__PURE__ */ jsx(HiddenEye, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.white }))
        ] }),
        backgroundColor: "PastelBrown",
        textColor: "white",
        textVariant: "caption"
      }
    )
  };
};
export const getReviewerTypesChipsByAnswer = (answers) => {
  const counts = {
    [ReviewerTypes.Self]: 0,
    [ReviewerTypes.Manager]: 0,
    [ReviewerTypes.Upward]: 0,
    [ReviewerTypes.Peer]: 0
  };
  answers.forEach((answer) => {
    if (counts[answer.answerType] !== void 0) {
      counts[answer.answerType] += 1;
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    counts[ReviewerTypes.Self] > 0 && /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `Self ${counts[ReviewerTypes.Self]}`,
        backgroundColor: "white",
        textColor: "Grey",
        textVariant: "caption",
        style: { border: `1px solid ${themeColors.PastelSeaGreen}` }
      }
    ),
    counts[ReviewerTypes.Manager] > 0 && /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `Manager ${counts[ReviewerTypes.Manager]}`,
        backgroundColor: "white",
        textColor: "Grey",
        textVariant: "caption",
        style: { border: `1px solid ${themeColors.Pink}` }
      }
    ),
    counts[ReviewerTypes.Upward] > 0 && /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `Upward ${counts[ReviewerTypes.Upward]}`,
        backgroundColor: "white",
        textColor: "Grey",
        textVariant: "caption",
        style: { border: `1px solid ${themeColors.PastelPurple}` }
      }
    ),
    counts[ReviewerTypes.Peer] > 0 && /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: `Peer ${counts[ReviewerTypes.Peer]}`,
        backgroundColor: "white",
        textColor: "Grey",
        textVariant: "caption",
        style: { border: `1px solid ${themeColors.PastelBrown}` }
      }
    )
  ] });
};
export const getReviewerTypesColor = () => {
  return {
    [ReviewerTypes.Self]: "PastelSeaGreen",
    [ReviewerTypes.Peer]: "PastelBrown",
    [ReviewerTypes.Upward]: "PastelPurple",
    [ReviewerTypes.Manager]: "Pink"
  };
};
export const hexToRGBA = (hex, opacity) => {
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
export const getAnalyticsByType = (question) => {
  switch (question.type) {
    case QuestionType.OpenEnded:
      return /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Not available for Comment question." });
    case QuestionType.ScaleQuestion:
      return /* @__PURE__ */ jsx(
        GrowthProgressLineChart,
        {
          label: `${question.series.length > 0 ? `${question.series[0]}%` : `${0}%`}`,
          labelVariant: "caption",
          percentJustifyContent: "start",
          percentages: [
            {
              value: question.series.length > 0 ? Number(question.series[0]) : 0,
              color: "blue"
            }
          ]
        }
      );
    default:
      return /* @__PURE__ */ jsx(
        GrowthProgressLineChart,
        {
          label: "\u2013",
          labelVariant: "caption",
          percentJustifyContent: "start",
          percentages: question.series.map((q, idx) => {
            return { value: Number(q), color: "blue", label: question.categories[idx], showTooltip: true };
          })
        }
      );
  }
};
const getOrCreateTooltip = (chart) => {
  var _a, _b;
  let tooltipEl = (_a = chart.canvas.parentNode) == null ? void 0 : _a.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = themeColors.DarkGrey;
    tooltipEl.style.width = "220px";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = "1";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.fontWeight = "300";
    tooltipEl.style.fontFamily = "Inter, sans-serif";
    const table = document.createElement("table");
    table.style.margin = "0px";
    table.style.fontFamily = "Inter, sans-serif";
    table.style.fontWeight = "300";
    tooltipEl.appendChild(table);
    (_b = chart.canvas.parentNode) == null ? void 0 : _b.appendChild(tooltipEl);
  }
  return tooltipEl;
};
export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableHead = document.createElement("thead");
    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;
      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);
      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });
    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";
      span.style.fontFamily = "Inter, sans-serif";
      span.style.fontWeight = "300";
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;
      const td = document.createElement("td");
      td.style.borderWidth = 0;
      const text = document.createTextNode(body + "%");
      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });
    const tableRoot = tooltipEl.querySelector("table");
    while (tableRoot == null ? void 0 : tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    tableRoot == null ? void 0 : tableRoot.appendChild(tableHead);
    tableRoot == null ? void 0 : tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = "1";
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.fontSize = "12px";
  tooltipEl.style.fontFamily = "Inter, sans-serif";
  tooltipEl.style.fontWeight = "300";
  tooltipEl.style.textAlign = "left";
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};
export const updateReviewerIds = (reviewerIds, filteredIds, revieweeId) => {
  if (filteredIds.length > 0) {
    reviewerIds[revieweeId] = filteredIds;
  } else {
    delete reviewerIds[revieweeId];
  }
};
export const ReviewerTypesOrderMap = {
  [ReviewerTypes.Self]: 1,
  [ReviewerTypes.Manager]: 2,
  [ReviewerTypes.Upward]: 3,
  [ReviewerTypes.Peer]: 4
};
export const countFilters = (nestedObject) => {
  if (!nestedObject || nestedObject === null) return 0;
  let totalCount = 0;
  for (const categoryKey in nestedObject) {
    const category = nestedObject[categoryKey];
    for (const filterKey in category) {
      const filtersArray = category[filterKey];
      if (Array.isArray(filtersArray)) {
        totalCount += filtersArray.length;
      }
    }
  }
  return totalCount;
};
