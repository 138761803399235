"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class CompanyAPI {
  static getCompanyUploadURL() {
    return `/apiv2/companies/avatar`;
  }
  static getCompanyUploadWallpaperURL() {
    return `/apiv2/companies/avatar/wallpaper`;
  }
  static getTranslationsJSON() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/translations-json`)).data;
    });
  }
  static getDefaultTranslationsJSON() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/default-translations-json`)).data;
    });
  }
  static getAllCompaniesAsSuperAdmin(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/companies/superadmin?${searchParams.toString()}`)).data;
    });
  }
  static deleteOwnCompany() {
    return __async(this, null, function* () {
      yield axios.delete("/apiv2/companies/company");
    });
  }
  static createEntity(newEntity) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/companies/entity", newEntity)).data;
    });
  }
  static updateEntity(updatedEntity) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/companies/entity", updatedEntity)).data;
    });
  }
  static createCompanyTranslation(translation) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/companies/translations", translation);
    });
  }
  static updateCompanyTranslation(id, translation) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/translations/${id}`, translation);
    });
  }
  static deleteCompanyTranslation(id) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/companies/translations/${id}`);
    });
  }
  static deleteEntity(entity) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/companies/entity/${entity.id}`);
    });
  }
  static getGeneralSettings() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/companies/general-settings")).data;
    });
  }
  static updateGeneralSettings(updatedSettings) {
    return __async(this, null, function* () {
      yield axios.patch("/apiv2/companies/general-settings", updatedSettings);
    });
  }
  static deleteCompanyWallpaper() {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/companies/avatar/delete-wallpaper");
    });
  }
  static changeActiveStatus(companyId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/${companyId}/superadmin/is-active`);
    });
  }
  static changeChatSupportStatus(companyId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/${companyId}/superadmin/has-support`);
    });
  }
  static hasChatSupport() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/has-support`)).data;
    });
  }
  static changeCompanyPrioritySupport(companyId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/${companyId}/superadmin/priority-support`);
    });
  }
  // @deprecated
  // static async priorityChatSupport(): Promise<boolean> {
  //   return (await axios.get(`/apiv2/companies/priority-support`)).data;
  // }
  static getAllCompanyPlans(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/companies/plans?${searchParams.toString()}`)).data;
    });
  }
  static getAllCompanySubscriptions(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/companies/subscription/all?${searchParams.toString()}`)).data;
    });
  }
  static updateCompanySubscription(companyId, subscriptionId, updatedSubscription) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/subscription/${companyId}/${subscriptionId}`, updatedSubscription);
    });
  }
  static getPublicURL() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/public-url`)).data;
    });
  }
  static getCompanyEntityOptions() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/entities-options`)).data;
    });
  }
}
export class CompanyEndpoints {
  static getGeneralSettings() {
    return {
      url: "/apiv2/companies/general-settings"
    };
  }
  static getEntitiesCount() {
    return {
      url: "/apiv2/companies/count-entities"
    };
  }
  static getOrgStructureCounts() {
    return {
      url: "/apiv2/companies/org-structure-counts"
    };
  }
  static getAllCompaniesForSelectionList() {
    return {
      url: "/apiv2/companies/superadmin/selection-list"
    };
  }
  static getDowngradeViability() {
    return {
      url: "/apiv2/companies/downgrade-viability"
    };
  }
  static getCompanyAuditTrail() {
    return {
      url: "/apiv2/companies/audit-trail"
    };
  }
  static getAuditTrailsForDevice(_deviceId) {
    return {
      url: "/apiv2/companies/audit-trail"
    };
  }
  static getCompanyConfig() {
    return {
      url: "/apiv2/companies/config"
    };
  }
  static getCompanyEntities() {
    return {
      url: "/apiv2/companies/entities"
    };
  }
  static getCompanyEntityOptions() {
    return {
      url: "/apiv2/companies/entities-options"
    };
  }
  static getCompanyTranslations() {
    return {
      url: "/apiv2/companies/translations"
    };
  }
  static getAvailablePlanDefinitions() {
    return {
      url: "/apiv2/plan-definition"
    };
  }
  static getAvailableModulesForUpgrade() {
    return {
      url: "/apiv2/companies/subscription/modules-for-upgrade"
    };
  }
}
