"use strict";
import { themeColors } from "@/v2/styles/colors.styles";
export const FONT_PRIMARY = "Inter, sans-serif !important";
const FONT_SECONDARY = "Dancing Script, cursive !important";
export const HEADER_FONT_PRIMARY = "NoiGrotesk, sans-serif !important";
const BASE_FONT_SIZE = 16;
const pxtorem = (px) => {
  return `${px / BASE_FONT_SIZE}rem`;
};
export const themeFonts = {
  title1: {
    fontFamily: HEADER_FONT_PRIMARY,
    fontSize: pxtorem(36),
    fontWeight: 500,
    letterSpacing: pxtorem(-0.5),
    lineHeight: pxtorem(40),
    color: themeColors.DarkGrey
  },
  title2: {
    fontFamily: HEADER_FONT_PRIMARY,
    fontSize: pxtorem(24),
    //24px
    fontWeight: 500,
    letterSpacing: pxtorem(-0.5),
    //-0.5px
    lineHeight: pxtorem(30),
    //30px
    color: themeColors.DarkGrey
  },
  title3: {
    fontFamily: HEADER_FONT_PRIMARY,
    fontSize: pxtorem(18),
    //18px
    fontWeight: 500,
    letterSpacing: pxtorem(-0.5),
    //-0.5px
    lineHeight: pxtorem(25)
    //25px
  },
  title4: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(14),
    fontWeight: 500,
    letterSpacing: pxtorem(-0.5),
    lineHeight: pxtorem(20)
  },
  title5: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(11),
    fontWeight: 500,
    letterSpacing: pxtorem(-0.5),
    lineHeight: pxtorem(15)
  },
  bodyLarge: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(18),
    fontWeight: 300,
    letterSpacing: pxtorem(-1),
    lineHeight: pxtorem(25)
  },
  caption: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(14),
    fontWeight: 300,
    letterSpacing: pxtorem(-0.4),
    lineHeight: pxtorem(20)
  },
  caption2: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(14),
    fontWeight: 300,
    letterSpacing: pxtorem(-0.4),
    lineHeight: pxtorem(15)
  },
  captionBold: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(14),
    fontWeight: 500,
    letterSpacing: pxtorem(-0.3),
    lineHeight: pxtorem(15)
  },
  captionSmall: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(11),
    fontWeight: 400,
    letterSpacing: pxtorem(-0.3),
    lineHeight: pxtorem(15)
  },
  captionSmall2: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(12),
    fontWeight: 400,
    letterSpacing: pxtorem(-0.3),
    lineHeight: pxtorem(16)
  },
  bodyTiny: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(8),
    fontWeight: 400,
    letterSpacing: pxtorem(-0.1),
    lineHeight: pxtorem(10)
  },
  //check later
  signature: {
    fontFamily: FONT_SECONDARY,
    fontSize: 26
  },
  tooltip: {
    fontFamily: FONT_PRIMARY,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "-0.3px !important",
    lineHeight: "16px",
    color: themeColors.white
  }
};
