"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { UserPayrollStatusCell } from "@/v2/feature/payroll/components/user-payroll-status-cell.component";
import { getUserPayrollStatusSortOrder } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { getUserStatusFromPayrunEntry } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { SalarySummaryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/salary-summary-drawer.component";
import {
  CurrencyWithDiff,
  ValueWithDiff
} from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { filterByTextSearch, sum } from "@/v2/util/array.util";
export const UKPayrunSummaryTable = ({
  localPayRun,
  entries,
  previousEntries,
  searchQuery,
  sx,
  stickyHeader,
  stickyHeaderHeight
}) => {
  const { getCachedUserById } = useCachedUsers();
  const [salarySummary, setSalarySummary] = useState();
  const { data: customPayCodes } = useApiClient(PayrollLocalEndpoints.getUKPayrunPayCodes(localPayRun.id), {
    suspense: false
  });
  const [summaryTotals, previousSummaryTotals] = useMemo(() => {
    const calculateTotals = (entries2) => ({
      income: sum(entries2, (e) => {
        var _a;
        return (_a = e.income) != null ? _a : 0;
      }),
      deductions: sum(entries2, (e) => {
        var _a;
        return (_a = e.deductions) != null ? _a : 0;
      }),
      takeHomePay: sum(entries2, (e) => {
        var _a;
        return (_a = e.takeHomePay) != null ? _a : 0;
      }),
      employerCost: sum(entries2, (e) => {
        var _a;
        return (_a = e.employerCost) != null ? _a : 0;
      })
    });
    return [
      calculateTotals(entries),
      previousEntries.length ? calculateTotals(previousEntries) : {}
    ];
  }, [entries, previousEntries]);
  const getUserDisplayName = useCallback(
    (userId) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );
  const filteredEntries = useMemo(() => {
    return filterByTextSearch(searchQuery, entries, (item) => [getUserDisplayName(item.userId)]);
  }, [entries, getUserDisplayName, searchQuery]);
  const columnData = useMemo(() => {
    const previousById = new Map(previousEntries.map((item) => [item.employee.id, item]));
    return [
      {
        id: "employee",
        header: () => "Employee",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId }),
        footer: () => "Total"
      },
      {
        id: "income",
        header: () => "Income",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.income) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (item) => {
              var _a;
              return (_a = item.income) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: summaryTotals.income, previousValue: previousSummaryTotals.income })
      },
      {
        id: "deductions",
        header: () => "Deductions",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.deductions) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (item) => {
              var _a;
              return (_a = item.deductions) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: summaryTotals.deductions, previousValue: previousSummaryTotals.deductions })
      },
      {
        id: "take-home",
        header: () => "Take home pay",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.takeHomePay) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (item) => {
              var _a;
              return (_a = item.takeHomePay) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: summaryTotals.takeHomePay,
            previousValue: previousSummaryTotals.takeHomePay
          }
        )
      },
      {
        id: "employer-cost",
        header: () => "Employer cost",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.employerCost) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (item) => {
              var _a;
              return (_a = item.employerCost) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: summaryTotals.employerCost,
            previousValue: previousSummaryTotals.employerCost
          }
        )
      },
      {
        id: "status",
        header: () => "Status",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => {
          var _a, _b;
          const statusA = (_a = getUserStatusFromPayrunEntry(a.original)) == null ? void 0 : _a.label;
          const statusB = (_b = getUserStatusFromPayrunEntry(b.original)) == null ? void 0 : _b.label;
          const orderA = getUserPayrollStatusSortOrder(statusA);
          const orderB = getUserPayrollStatusSortOrder(statusB);
          return orderA - orderB;
        },
        cell: (c) => {
          return /* @__PURE__ */ jsx(UserPayrollStatusCell, { status: getUserStatusFromPayrunEntry(c.row.original) });
        }
      }
    ];
  }, [previousEntries, summaryTotals, previousSummaryTotals, getUserDisplayName]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", flexFlow: "column" }, sx), children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredEntries,
        columnData,
        hidePagination: true,
        showFooter: true,
        rowClick: (row) => {
          setSalarySummary({ userId: row.original.userId, payrunEntry: row.original });
        },
        initialSort: [{ id: "status", desc: false }],
        stickyHeader,
        stickyHeaderHeight
      }
    ) }),
    /* @__PURE__ */ jsx(
      SalarySummaryDrawer,
      {
        userId: salarySummary == null ? void 0 : salarySummary.userId,
        payrunEntry: salarySummary == null ? void 0 : salarySummary.payrunEntry,
        onClose: () => setSalarySummary(void 0),
        customPayCodes: customPayCodes != null ? customPayCodes : [],
        localPayRun
      }
    )
  ] });
};
