"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const NewEmployeeSection = ({ onNext, sx, templateId, user }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { refreshCachedUsers } = useCachedUsers();
  const NewEmployeeSchema = useMemo(
    () => Yup.object().shape({
      firstName: Yup.string().trim().required(polyglot.t("NewEmployeeSection.errorMessages.nameRequired")),
      lastName: Yup.string().trim().required(polyglot.t("NewEmployeeSection.errorMessages.lastNameRequired")),
      emailAddress: Yup.string().trim().email(polyglot.t("NewEmployeeSection.errorMessages.emailInvalid")).required(polyglot.t("NewEmployeeSection.errorMessages.emailRequired")),
      personalEmail: Yup.string().trim().email(polyglot.t("NewEmployeeSection.errorMessages.emailInvalid")).required(polyglot.t("NewEmployeeSection.errorMessages.personalEmailRequired")),
      startDate: Yup.string().test(dateFieldTest).required(polyglot.t("NewEmployeeSection.errorMessages.chooseDate"))
    }),
    [polyglot]
  );
  const formik = useFormik({
    initialValues: {
      firstName: (_a = user == null ? void 0 : user.firstName) != null ? _a : "",
      lastName: (_b = user == null ? void 0 : user.lastName) != null ? _b : "",
      emailAddress: (_c = user == null ? void 0 : user.emailAddress) != null ? _c : "",
      personalEmail: (_d = user == null ? void 0 : user.personalEmail) != null ? _d : "",
      startDate: (_e = user == null ? void 0 : user.startDate) != null ? _e : todaysDateShortISOString(),
      displayName: (_f = user == null ? void 0 : user.displayName) != null ? _f : "",
      isAdmin: false,
      type: UserContractTypeEnum.Payrolled,
      templateId
    },
    validateOnMount: true,
    validationSchema: NewEmployeeSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        const userId = user ? (yield UserAPI.patchUserBasicInfo(user.userId, __spreadProps(__spreadValues({}, values), {
          displayName: `${values.firstName} ${values.lastName}`
        })), user.userId) : yield OnboardingAPI.createUserOnboarding(
          __spreadProps(__spreadValues({}, values), {
            displayName: `${values.firstName} ${values.lastName}`
          }),
          refreshCachedUsers
        );
        onNext(userId);
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeSection.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("NewEmployeeSection.add") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "firstName",
        label: polyglot.t("NewEmployeeSection.firstName"),
        value: formik.values.firstName,
        onChange: formik.handleChange,
        disabled: saving,
        clearText: () => formik.setFieldValue("firstName", ""),
        helperText: hasSubmitted && formik.errors.firstName,
        error: hasSubmitted && !!formik.errors.firstName,
        autoFocus: true,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "lastName",
        label: polyglot.t("NewEmployeeSection.lastName"),
        value: formik.values.lastName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("lastName", ""),
        helperText: hasSubmitted && formik.errors.lastName,
        error: hasSubmitted && !!formik.errors.lastName,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emailAddress",
        label: polyglot.t("NewEmployeeSection.emailAddress"),
        type: "email",
        value: formik.values.emailAddress,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emailAddress", ""),
        helperText: hasSubmitted && formik.errors.emailAddress,
        error: hasSubmitted && !!formik.errors.emailAddress,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "personalEmail",
        label: polyglot.t("NewEmployeeSection.personalEmail"),
        type: "email",
        value: formik.values.personalEmail,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("personalEmail", ""),
        helperText: (_g = hasSubmitted && formik.errors.personalEmail) != null ? _g : "",
        error: hasSubmitted && !!formik.errors.personalEmail,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_h = formik.values.startDate) != null ? _h : null,
        onChange: (value) => {
          formik.setFieldValue("startDate", value);
        },
        name: "start",
        required: true,
        label: polyglot.t("NewEmployeeSection.start"),
        disabled: saving || !!user
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        fullWidth: true
      }
    )
  ] }) }) });
};
