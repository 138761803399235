"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { GrowthChip } from "@v2/feature/growth/shared/components/growth-chip.component";
import { generatePath, Link } from "react-router-dom";
import { GrowthFactorsGroup } from "./growth-factors-group.component";
import { OwnerChip } from "./owner-chip.component";
import { StatusChip } from "./status-chip.component";
import { ReactComponent as CalendarIcon } from "@/images/icons/calendar.svg";
import { ReactComponent as KeyResultsIcon } from "@/images/icons/key-results.svg";
import { GROWTH_COMPANY_GOAL_ROUTE, GROWTH_ME_GOAL_ROUTE } from "@/lib/routes";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { useGoalsReach } from "@/v2/feature/growth/goals/hooks/use-goals-reach.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
import { LocalDate } from "@/v2/util/local-date";
export const GoalListItem = ({ goal, showDescription, hoverState }) => {
  const reach = useGoalsReach();
  const goalListItem = useMemo(() => {
    const goalPageLink = generatePath(reach === ReachType.Company ? GROWTH_COMPANY_GOAL_ROUTE : GROWTH_ME_GOAL_ROUTE, {
      goalId: goal.id
    });
    return /* @__PURE__ */ jsx(
      Link,
      {
        to: goalPageLink,
        title: goal.name,
        style: {
          width: "100%",
          textDecoration: "none",
          color: "inherit"
        },
        children: /* @__PURE__ */ jsx(
          ListItem,
          {
            progressBar: { progress: goal.lastProgress, progressType: "percentage" },
            details: {
              title: goal.name,
              description: showDescription ? goal.description : null,
              chips: /* @__PURE__ */ jsxs(Fragment, { children: [
                /* @__PURE__ */ jsx(OwnerChip, { ownerType: goal.ownerType, departmentId: goal.departmentId, ownerIds: goal.ownerIds }),
                /* @__PURE__ */ jsx(
                  GrowthChip,
                  {
                    options: {
                      text: goal.keyResultsCount,
                      icon: /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(KeyResultsIcon, {}) }),
                      border: "middleGrey2"
                    }
                  }
                ),
                goal.dueDate ? /* @__PURE__ */ jsx(
                  GrowthChip,
                  {
                    options: {
                      text: new LocalDate(goal.dueDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }),
                      icon: /* @__PURE__ */ jsx(CalendarIcon, { width: "12px" }),
                      border: "middleGrey2"
                    }
                  }
                ) : null,
                /* @__PURE__ */ jsx(GrowthFactorsGroup, { factorIds: goal.factorIds })
              ] })
            },
            hoverState,
            endSection: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(StatusChip, { status: goal.status }) })
          }
        )
      }
    );
  }, [goal, showDescription, hoverState, reach]);
  return goalListItem;
};
