"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InfiniteScrollTrigger } from "@/v2/feature/growth/goals/components/infinite-scroll-trigger.component";
import { KeyResultProgressListItem } from "@/v2/feature/growth/goals/features/key-result-progress/components/key-result-progress-list-item.component";
import {
  KeyResultProgressAPI,
  KeyResultProgressEndpoints
} from "@/v2/feature/growth/goals/features/key-result-progress/key-result-progress.api";
import { KeyResultListItem } from "@/v2/feature/growth/goals/features/key-results/components/key-result-list-item.component";
import { KeyResultMutationModal } from "@/v2/feature/growth/goals/features/key-results/components/key-result-mutation-modal.component";
import {
  KeyResultStatus,
  keyResultStatusOptions
} from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { KeyResultAPI } from "@/v2/feature/growth/goals/features/key-results/key-results.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const iconSize = { width: 14, height: 14 };
export const KeyResultDrawer = ({
  keyResult,
  isOpen,
  onClosed,
  onKeyResultUpdated,
  onKeyResultDeleted,
  onProgressDeleted
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen: (isOpenVal) => {
        if (!isOpenVal) onClosed == null ? void 0 : onClosed();
      },
      widthPercentage: 96,
      onClose: () => {
        onClosed == null ? void 0 : onClosed();
      },
      children: /* @__PURE__ */ jsx(
        KeyResultDrawerContent,
        {
          keyResult,
          isOpen,
          onKeyResultUpdated,
          onKeyResultDeleted,
          onProgressDeleted
        }
      )
    }
  );
};
export const KeyResultDrawerContent = ({
  keyResult,
  isOpen,
  onKeyResultUpdated,
  onKeyResultDeleted,
  onProgressDeleted
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const isAdmin = canAccessScopes(user, ["goals:all"]);
  const canMutateKeyResults = isAdmin;
  const isKeyResultOwner = useMemo(() => {
    if (!keyResult) return false;
    return keyResult.ownerIds.includes(user.userId);
  }, [keyResult, user]);
  const canPostProgressUpdates = isKeyResultOwner || isAdmin;
  const [editKeyResultDrawerOpen, setEditKeyResultDrawerOpen] = useState(false);
  const [deleteKeyResultDrawerOpen, setDeleteKeyResultDrawerOpen] = useState(false);
  const [deleteProgressDrawerOpen, setDeleteProgressDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pagedProgressItems, setPagedProgressItems] = useState({});
  const { data: paginatedProgressItems, isLoading: progressItemsLoading, mutate: refreshProgressItems } = useApiClient(
    KeyResultProgressEndpoints.getKeyResultProgressItems((_a = keyResult == null ? void 0 : keyResult.goalId) != null ? _a : "", (_b = keyResult == null ? void 0 : keyResult.id) != null ? _b : "", page),
    {
      suspense: false
    },
    Boolean(isOpen && (keyResult == null ? void 0 : keyResult.goalId) && keyResult.id)
  );
  useEffect(() => {
    var _a2;
    if (!paginatedProgressItems) return;
    const bodyPage = (_a2 = paginatedProgressItems.page) != null ? _a2 : 1;
    setPagedProgressItems((prev) => {
      return __spreadProps(__spreadValues({}, prev), { [bodyPage]: paginatedProgressItems.items });
    });
  }, [paginatedProgressItems]);
  const progressItems = useMemo(() => Object.values(pagedProgressItems).flat(), [pagedProgressItems]);
  const getKeyResultActionsOptions = useCallback(
    () => [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => setEditKeyResultDrawerOpen(true),
        label: polyglot.t("KeyResultDrawer.actionsMenu.edit"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => setDeleteKeyResultDrawerOpen(true),
        label: polyglot.t("KeyResultDrawer.actionsMenu.delete"),
        disabled: false
      }
    ],
    [polyglot]
  );
  const deleteKeyResult = useCallback(() => __async(void 0, null, function* () {
    if (!keyResult) return;
    try {
      yield KeyResultAPI.removeKeyResult(keyResult.goalId, keyResult.id);
      setDeleteKeyResultDrawerOpen(false);
      showMessage(polyglot.t("KeyResultDrawer.keyResultDeleted"), "success");
      onKeyResultDeleted == null ? void 0 : onKeyResultDeleted();
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [keyResult, onKeyResultDeleted, setDeleteKeyResultDrawerOpen, polyglot, showMessage]);
  const onReachedListEnd = useCallback(() => {
    if (progressItemsLoading) return;
    if (!(paginatedProgressItems == null ? void 0 : paginatedProgressItems.nextPage)) return;
    if (!(paginatedProgressItems == null ? void 0 : paginatedProgressItems.items)) return;
    setPage(paginatedProgressItems.nextPage);
  }, [progressItemsLoading, paginatedProgressItems, setPage]);
  const formik = useFormik({
    initialValues: {
      value: (_c = keyResult == null ? void 0 : keyResult.lastProgress) != null ? _c : 0,
      comment: "",
      keyResultStatus: (_d = keyResult == null ? void 0 : keyResult.status) != null ? _d : KeyResultStatus.Active
    },
    validationSchema: yup.object({
      value: yup.number().min(0, polyglot.t("KeyResultDrawer.errorMessages.minValue")).required(polyglot.t("KeyResultDrawer.errorMessages.valueRequired")),
      comment: yup.string().notRequired(),
      keyResultStatus: yup.string()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      var _a2, _b2, _c2, _d2;
      try {
        yield Promise.all([
          KeyResultProgressAPI.createKeyResultProgress((_a2 = keyResult == null ? void 0 : keyResult.goalId) != null ? _a2 : "", (_b2 = keyResult == null ? void 0 : keyResult.id) != null ? _b2 : "", {
            comment: values.comment,
            value: Number(values.value)
          }),
          KeyResultAPI.patchKeyResult((_c2 = keyResult == null ? void 0 : keyResult.goalId) != null ? _c2 : "", (_d2 = keyResult == null ? void 0 : keyResult.id) != null ? _d2 : "", {
            status: values.keyResultStatus
          })
        ]);
        showMessage(polyglot.t("KeyResultDrawer.keyResultProgressUpdated"), "success");
        onKeyResultUpdated == null ? void 0 : onKeyResultUpdated();
        refreshProgressItems == null ? void 0 : refreshProgressItems();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  if (!keyResult) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
      /* @__PURE__ */ jsx(
        TopHeader,
        {
          title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("KeyResultDrawer.keyResult") }),
          actions: /* @__PURE__ */ jsxs(Fragment, { children: [
            canMutateKeyResults && /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: getKeyResultActionsOptions(),
                actionButtonDetails: {
                  type: "iconButton",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: "actions",
                  icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                }
              }
            ),
            canPostProgressUpdates && /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "primary",
                onClick: () => {
                  formik.handleSubmit();
                },
                children: polyglot.t("KeyResultDrawer.update")
              }
            )
          ] }),
          showAction: true,
          sx: { display: "flex", justifyContent: "space-between", width: "100%" }
        }
      ),
      keyResult ? /* @__PURE__ */ jsx(KeyResultListItem, { keyResult, hoverState: false, sx: { padding: "1rem 0" } }) : null,
      /* @__PURE__ */ jsx(
        Box,
        {
          className: "sub-title",
          sx: {
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("KeyResultDrawer.progress") })
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "3rem", marginTop: "1rem" }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            required: true,
            name: "value",
            type: "number",
            label: polyglot.t("KeyResultDrawer.formFields.progress"),
            value: formik.values.value,
            onChange: formik.handleChange,
            error: formik.touched.value && !!formik.errors.value,
            helperText: formik.touched.value && formik.errors.value,
            endAdornment: "none",
            fullWidth: true,
            disabled: !canPostProgressUpdates
          }
        ),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "keyResultStatus",
            label: polyglot.t("KeyResultDrawer.formFields.status"),
            options: keyResultStatusOptions,
            value: formik.values.keyResultStatus,
            onChange: formik.handleChange,
            compareValue: formik.values.keyResultStatus,
            error: !!formik.errors.keyResultStatus && formik.touched.keyResultStatus,
            helperText: formik.touched.keyResultStatus && formik.errors.keyResultStatus,
            disabled: !canPostProgressUpdates
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        Box,
        {
          className: "sub-title",
          sx: {
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
          children: /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("KeyResultDrawer.comments") })
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "0.75rem" }, children: [
        /* @__PURE__ */ jsx(UserCell, { sx: { flex: "auto" }, userId: user.userId }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("KeyResultDrawer.whatsNew") })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { marginTop: "1rem" }, children: /* @__PURE__ */ jsx(
        RichTextField,
        {
          value: "",
          onChange: (value) => formik.setFieldValue("comment", value),
          error: formik.touched.comment && !!formik.errors.comment,
          helperText: formik.touched.comment && formik.errors.comment,
          disabled: !canPostProgressUpdates
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { children: [
        progressItems == null ? void 0 : progressItems.map((progressItem) => {
          if (!keyResult) return null;
          return /* @__PURE__ */ jsx(
            KeyResultProgressListItem,
            {
              progress: progressItem,
              goalId: keyResult.goalId,
              onProgressDeleted: () => {
                refreshProgressItems == null ? void 0 : refreshProgressItems();
                onProgressDeleted == null ? void 0 : onProgressDeleted();
              }
            },
            progressItem.id
          );
        }),
        /* @__PURE__ */ jsx(InfiniteScrollTrigger, { onReachedEnd: onReachedListEnd })
      ] })
    ] }) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("DeleteKeyResultDrawer.title"),
        description: polyglot.t("DeleteKeyResultDrawer.description"),
        isOpen: deleteKeyResultDrawerOpen,
        setIsOpen: setDeleteKeyResultDrawerOpen,
        loading: false,
        action: () => deleteKeyResult()
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("DeleteKeyResultDrawer.title"),
        description: polyglot.t("DeleteKeyResultDrawer.description"),
        isOpen: deleteProgressDrawerOpen,
        setIsOpen: setDeleteProgressDrawerOpen,
        loading: false,
        action: () => deleteKeyResult()
      }
    ),
    /* @__PURE__ */ jsx(
      KeyResultMutationModal,
      {
        mode: "edit",
        goalId: keyResult == null ? void 0 : keyResult.goalId,
        keyResult: keyResult != null ? keyResult : void 0,
        isOpen: editKeyResultDrawerOpen,
        setIsOpen: setEditKeyResultDrawerOpen,
        onClose: () => setEditKeyResultDrawerOpen(false),
        refresh: () => __async(void 0, null, function* () {
          onKeyResultUpdated == null ? void 0 : onKeyResultUpdated();
        })
      }
    )
  ] });
};
