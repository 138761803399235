"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoTransparent.svg";
import { ReactComponent as Warning } from "@/images/side-bar-icons/MistakeOrange.svg";
import { ReactComponent as HintIcon } from "@/images/side-bar-icons/Question.svg";
import {
  CalculationMethodMonth1,
  CalculationMethodStandard,
  CalculationMethodsValues,
  NITableValues,
  No,
  StarterDeclarationA,
  StarterDeclarationValues,
  StudentLoanValues,
  Yes,
  YesNoValues
} from "@/lib/payroll";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { MAX_PAYROLL_ID_LENGTH } from "@/v2/feature/payroll/payroll-external.interface";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const PayrollFieldSchema = (polyglot) => Yup.object().shape({
  directorFrom: Yup.string().nullable().notRequired().when("isDirector", {
    is: true,
    then: Yup.string().test(dateFieldTest).required(polyglot.t("OnboardingByUser.validations.requiredField"))
  }),
  directorTo: Yup.string().test(dateFieldTest).nullable().notRequired(),
  isDirector: Yup.boolean().notRequired().default(false),
  legalGender: Yup.string().nullable().oneOf(["male", "female"]).required(polyglot.t("OnboardingByUser.validations.requiredField")),
  niNumber: Yup.string().nullable().notRequired().when("hasNino", {
    is: true,
    then: Yup.string().nullable().trim().matches(
      /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s*\d{2}){3}\s*[A-D]$/i,
      polyglot.t("OnboardingByUser.validations.validValue")
    ).required(polyglot.t("OnboardingByUser.validations.requiredField"))
  }),
  niTable: Yup.string().required(polyglot.t("OnboardingByUser.validations.requiredField")),
  starterDeclaration: Yup.string().required(polyglot.t("OnboardingByUser.validations.requiredField")),
  openingPreviousGross: Yup.number().notRequired().when(["starterDeclaration", "noP45"], {
    is: (starterDeclaration, noP45) => starterDeclaration === "B" && !noP45,
    then: Yup.number().moreThan(0, polyglot.t("OnboardingByUser.validations.requiredField"))
  }),
  openingPreviousTax: Yup.number().notRequired().when(["starterDeclaration", "noP45"], {
    is: (starterDeclaration, noP45) => starterDeclaration === "B" && !noP45,
    then: Yup.number().moreThan(0, polyglot.t("OnboardingByUser.validations.requiredField"))
  }),
  openingNotNewStarter: Yup.boolean().notRequired(),
  openingCurrentGross: Yup.number().notRequired(),
  openingCurrentTax: Yup.number().notRequired(),
  studentLoan: Yup.string().notRequired(),
  postgradLoan: Yup.boolean().notRequired(),
  taxCode: Yup.string().matches(/^([a-zA-Z0-9]+)$/i, polyglot.t("OnboardingByUser.validations.validValue")).required(polyglot.t("OnboardingByUser.validations.requiredField")),
  week1Month1: Yup.boolean().required(polyglot.t("OnboardingByUser.validations.requiredField")),
  effectiveDate: Yup.string().test(dateFieldTest).required(polyglot.t("OnboardingByUser.validations.requiredField")),
  changeReason: Yup.string().nullable().optional()
});
export const EditUserPayrollUKPage = ({
  payrollId,
  effectiveDate,
  changeReason,
  payrollValues,
  savePayrollRecord,
  isUserUpdating,
  close,
  requireReason,
  userPayrollId
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingPayrollId, setIsEditingPayrollId] = useState(false);
  const p45Enabled = useCallback((starterDeclaration) => {
    return starterDeclaration === "B";
  }, []);
  const formik = useFormik({
    initialValues: __spreadProps(__spreadValues({
      payrollId,
      starterDeclaration: StarterDeclarationA.value,
      niTable: NITableValues[0].value,
      niNumber: null,
      studentLoan: StudentLoanValues[0].value,
      postgradLoan: false,
      isDirector: false,
      niAlternativeMethod: false,
      openingNotNewStarter: false,
      openingPreviousGross: 0,
      openingPreviousTax: 0,
      openingCurrentGross: 0,
      openingCurrentTax: 0,
      taxCode: "",
      week1Month1: false,
      legalGender: null,
      isP45Filled: false
    }, payrollValues), {
      hasNino: !!(payrollValues == null ? void 0 : payrollValues.niNumber),
      noP45: !payrollValues || payrollValues.starterDeclaration === "B" && !payrollValues.openingPreviousGross,
      effectiveDate: effectiveDate != null ? effectiveDate : todaysDateShortISOString(),
      changeReason: changeReason || null
    }),
    validationSchema: PayrollFieldSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      const _a2 = values, { payrollId: payrollId2, effectiveDate: effectiveDate2, changeReason: changeReason2, hasNino: hasNino2, noP45: noP452 } = _a2, payrollValues2 = __objRest(_a2, ["payrollId", "effectiveDate", "changeReason", "hasNino", "noP45"]);
      if (requireReason && !(changeReason2 == null ? void 0 : changeReason2.trim())) {
        formik.setFieldError("changeReason", polyglot.t("OnboardingByUser.validations.requiredField"));
        return;
      }
      setIsSaving(true);
      const cleanAlphanumericField = (text) => text.trim().toUpperCase().replace(/[^A-Z0-9]/i, "");
      const updated = yield savePayrollRecord({
        payrollId: payrollId2 || void 0,
        // if a blank string is entered, leave it unchanged
        changeReason: changeReason2,
        effectiveDate: effectiveDate2,
        payrollValues: __spreadProps(__spreadValues({}, payrollValues2), {
          taxCode: cleanAlphanumericField(values.taxCode),
          niNumber: hasNino2 && values.niNumber ? cleanAlphanumericField(values.niNumber) : null,
          openingPreviousGross: noP452 ? 0 : values.openingPreviousGross,
          openingPreviousTax: noP452 ? 0 : values.openingPreviousTax
        }),
        remotePayrollValues: null,
        countryCode: "GB",
        userPayrollId
      });
      setIsSaving(false);
      if (updated) {
        close();
      }
    })
  });
  const { hasNino, noP45 } = formik.values;
  const stripNonAlphanumeric = useCallback((value) => {
    return value.replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase();
  }, []);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", display: isEditingPayrollId ? "none" : void 0 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.sm }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("OnboardingByUser.payrollId") }),
        !!payrollId ? /* @__PURE__ */ jsx(Typography, { variant: "title4", children: payrollId }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OnboardingByUser.autoGenerate") })
      ] }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: { ml: "auto", p: 0, height: "16px" },
          onClick: () => setIsEditingPayrollId(true),
          disabled: isSaving,
          children: /* @__PURE__ */ jsx(EditIcon, { width: 16, height: 16 })
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.sm, display: !isEditingPayrollId ? "none" : void 0 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("OnboardingByUser.newPayrollId"),
          name: "payrollId",
          value: (_a = formik.values.payrollId) != null ? _a : "",
          onChange: formik.handleChange,
          clearText: () => formik.setFieldValue("payrollId", ""),
          maxLength: MAX_PAYROLL_ID_LENGTH,
          disabled: isSaving
        }
      ),
      !!payrollId && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", mt: spacing.m5, gap: spacing.g5, alignItems: "flex-start" }, children: [
        /* @__PURE__ */ jsx(Warning, { style: { width: 24 } }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OnboardingByUser.changingIdRequire") })
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "effectiveDate",
        label: polyglot.t("OnboardingByUser.effectiveDate"),
        required: true,
        onChange: (value) => {
          formik.setFieldValue("effectiveDate", value);
        },
        value: formik.values.effectiveDate,
        error: formik.touched.effectiveDate && !!formik.errors.effectiveDate,
        helperText: formik.touched.effectiveDate && formik.errors.effectiveDate,
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("OnboardingByUser.taxCode"),
        name: "taxCode",
        value: formik.values.taxCode,
        onChange: (e) => {
          const taxCode = stripNonAlphanumeric(e.target.value);
          formik.setFieldValue("taxCode", taxCode);
          if (taxCode.endsWith("X")) {
            formik.setFieldValue("week1Month1", true);
          }
        },
        clearText: () => formik.setFieldValue("taxCode", ""),
        autoFocus: true,
        maxLength: 10,
        error: formik.touched.taxCode && !!formik.errors.taxCode,
        helperText: formik.touched.taxCode && formik.errors.taxCode,
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "week1Month1",
          label: polyglot.t("OnboardingByUser.calculationMethod"),
          options: CalculationMethodsValues,
          value: formik.values.week1Month1 ? CalculationMethodMonth1 : CalculationMethodStandard,
          onChange: (e) => formik.setFieldValue("week1Month1", e.target.value === CalculationMethodMonth1),
          disabled: isSaving
        }
      ),
      formik.values.taxCode.endsWith("X") && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(InfoIcon, { style: { height: 14, width: 14 } }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OnboardingByUser.yourTaxCodeShouldBe", { method: CalculationMethodMonth1 }) })
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "starterDeclaration",
        label: polyglot.t("OnboardingByUser.starterDeclaration"),
        options: StarterDeclarationValues.map(({ value, label, details }) => ({
          label: `${label}. ${details}`,
          value
        })),
        value: formik.values.starterDeclaration,
        onChange: (e) => {
          const p45 = p45Enabled(e.target.value);
          formik.handleChange(e);
          formik.setFieldValue("openingPreviousGross", p45 ? formik.initialValues.openingPreviousGross : 0, true);
          formik.setFieldValue("openingPreviousTax", p45 ? formik.initialValues.openingPreviousTax : 0, true);
        },
        disabled: isSaving
      }
    ),
    p45Enabled(formik.values.starterDeclaration) && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("OnboardingByUser.starterSettings") }),
        /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("OnboardingByUser.p45Figures"), children: /* @__PURE__ */ jsx(HintIcon, {}) })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g30 }, children: [
        /* @__PURE__ */ jsx(
          MoneyTextfieldComponent,
          {
            name: "openingPreviousGross",
            label: polyglot.t("OnboardingByUser.openingPreviousGross"),
            value: formik.values.openingPreviousGross,
            onChange: (value) => formik.setFieldValue("openingPreviousGross", value || 0),
            error: formik.touched.openingPreviousGross && !!formik.errors.openingPreviousGross,
            helperText: formik.touched.openingPreviousGross && formik.errors.openingPreviousGross,
            disabled: noP45 || isSaving,
            emptyIsZero: true,
            clearToZero: true
          }
        ),
        /* @__PURE__ */ jsx(
          MoneyTextfieldComponent,
          {
            name: "openingPreviousTax",
            label: polyglot.t("OnboardingByUser.openingPreviousTax"),
            value: formik.values.openingPreviousTax,
            onChange: (value) => formik.setFieldValue("openingPreviousTax", value || 0),
            error: formik.touched.openingPreviousTax && !!formik.errors.openingPreviousTax,
            helperText: formik.touched.openingPreviousTax && formik.errors.openingPreviousTax,
            disabled: noP45 || isSaving,
            emptyIsZero: true,
            clearToZero: true
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            name: "noP45",
            label: polyglot.t("OnboardingByUser.noP45"),
            checked: noP45,
            onChange: (e, checked) => {
              if (checked) {
                formik.setFieldValue("openingPreviousGross", 0);
                formik.setFieldValue("openingPreviousTax", 0);
                formik.setFieldValue("week1Month1", true);
              } else {
                formik.setFieldValue("openingPreviousGross", formik.initialValues.openingPreviousGross);
                formik.setFieldValue("openingPreviousTax", formik.initialValues.openingPreviousTax);
                formik.setFieldValue("week1Month1", formik.initialValues.week1Month1);
              }
              formik.handleChange(e);
            },
            sx: themeFonts.caption,
            disabled: isSaving
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "niTable",
        label: polyglot.t("OnboardingByUser.niTable"),
        options: NITableValues.map(({ value, label, details }) => ({
          label: `${label}. ${details}`,
          value
        })),
        value: formik.values.niTable,
        onChange: formik.handleChange,
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "hasNino",
        label: polyglot.t("OnboardingByUser.hasNino"),
        checked: hasNino,
        onChange: (e, checked) => {
          formik.setFieldValue("niNumber", checked ? formik.initialValues.niNumber : "");
          formik.handleChange(e);
        },
        disabled: isSaving
      }
    ),
    hasNino && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("OnboardingByUser.niNumber"),
        name: "niNumber",
        value: hasNino ? formik.values.niNumber || "" : "",
        disabled: !hasNino || isSaving,
        onChange: (e) => formik.setFieldValue("niNumber", stripNonAlphanumeric(e.target.value)),
        clearText: () => formik.setFieldValue("niNumber", ""),
        maxLength: 20,
        autoComplete: "off",
        error: (formik.touched.niNumber || formik.touched.hasNino) && !!formik.errors.niNumber,
        helperText: (formik.touched.niNumber || formik.touched.hasNino) && formik.errors.niNumber
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "studentLoan",
        label: polyglot.t("OnboardingByUser.studentLoan"),
        options: StudentLoanValues,
        value: formik.values.studentLoan,
        onChange: formik.handleChange,
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "postgradLoan",
        label: polyglot.t("OnboardingByUser.postgraduateLoan"),
        options: YesNoValues,
        value: formik.values.postgradLoan ? Yes : No,
        onChange: (e) => formik.setFieldValue("postgradLoan", e.target.value === Yes),
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "legalGender",
        label: polyglot.t("OnboardingByUser.sex"),
        options: [
          { label: polyglot.t("OnboardingByUser.male"), value: "male" },
          { label: polyglot.t("OnboardingByUser.female"), value: "female" }
        ],
        value: formik.values.legalGender || "",
        onChange: formik.handleChange,
        error: formik.touched.legalGender && !!formik.errors.legalGender,
        helperText: formik.touched.legalGender && formik.errors.legalGender,
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "isDirector",
        label: polyglot.t("OnboardingByUser.isDirector"),
        checked: formik.values.isDirector,
        onChange: (e, checked) => {
          formik.setFieldValue("directorFrom", checked ? formik.initialValues.directorFrom : null);
          formik.setFieldValue("directorTo", checked ? formik.initialValues.directorTo : null);
          formik.setFieldValue("niAlternativeMethod", checked ? !!formik.initialValues.niAlternativeMethod : null);
          formik.handleChange(e);
        },
        disabled: isSaving
      }
    ),
    formik.values.isDirector && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          name: "directorFrom",
          label: polyglot.t("OnboardingByUser.directorFrom"),
          value: formik.values.directorFrom,
          onChange: (value) => {
            formik.setFieldValue("directorFrom", value);
          },
          error: formik.touched.directorFrom && !!formik.errors.directorFrom,
          helperText: formik.touched.directorFrom && formik.errors.directorFrom,
          disabled: isSaving
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { gap: spacing.sm, children: [
        /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            name: "directorTo",
            label: polyglot.t("OnboardingByUser.directorTo"),
            value: formik.values.directorTo,
            onChange: (value) => formik.setFieldValue("directorTo", value),
            error: formik.touched.directorTo && !!formik.errors.directorTo,
            helperText: formik.touched.directorTo && formik.errors.directorTo,
            disabled: isSaving
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            name: "directorshipActive",
            label: polyglot.t("OnboardingByUser.directorshipActive"),
            checked: !formik.values.directorTo,
            onChange: (_, checked) => {
              formik.setFieldValue("directorTo", checked ? null : dayjs().format("YYYY-MM-DD"));
            },
            disabled: isSaving
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "niAlternativeMethod",
          label: polyglot.t("OnboardingByUser.niAlternativeMethod"),
          checked: !!formik.values.niAlternativeMethod,
          onChange: (e) => formik.handleChange(e),
          disabled: isSaving
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { gap: spacing.s1, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "openingNotNewStarter",
          label: polyglot.t("OnboardingByUser.openingNotNewStarter"),
          checked: (_b = formik.values.openingNotNewStarter) != null ? _b : false,
          onChange: (e, checked) => {
            formik.setFieldValue("openingCurrentGross", checked ? formik.initialValues.openingCurrentGross : 0);
            formik.setFieldValue("openingCurrentTax", checked ? formik.initialValues.openingCurrentTax : 0);
            formik.handleChange(e);
          },
          disabled: isSaving
        }
      ),
      formik.values.openingNotNewStarter && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OnboardingByUser.figuresFromPrevious") }),
        /* @__PURE__ */ jsxs(Stack, { gap: spacing.s1, children: [
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "openingCurrentGross",
              label: polyglot.t("OnboardingByUser.openingCurrentGross"),
              value: formik.values.openingCurrentGross,
              onChange: (value) => formik.setFieldValue("openingCurrentGross", value || 0),
              error: formik.touched.openingCurrentGross && !!formik.errors.openingCurrentGross,
              helperText: formik.touched.openingCurrentGross && formik.errors.openingCurrentGross,
              disabled: isSaving
            }
          ),
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "openingCurrentTax",
              label: polyglot.t("OnboardingByUser.openingCurrentTax"),
              value: formik.values.openingCurrentTax,
              onChange: (value) => formik.setFieldValue("openingCurrentTax", value || 0),
              error: formik.touched.openingCurrentTax && !!formik.errors.openingCurrentTax,
              helperText: formik.touched.openingCurrentTax && formik.errors.openingCurrentTax,
              disabled: isSaving
            }
          )
        ] })
      ] })
    ] }),
    requireReason && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("OnboardingByUser.changeReason"),
        name: "changeReason",
        value: (_c = formik.values.changeReason) != null ? _c : "",
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("changeReason", ""),
        error: formik.touched.changeReason && !!formik.errors.changeReason,
        helperText: formik.touched.changeReason && formik.errors.changeReason,
        disabled: isSaving
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => close(),
          disabled: isUserUpdating || isSaving,
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          colorVariant: "primary",
          sizeVariant: "medium",
          name: polyglot.t("General.save"),
          loading: isSaving,
          fullWidth: true,
          disabled: isUserUpdating || isSaving
        }
      )
    ] })
  ] }) });
};
