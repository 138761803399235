"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { AttendanceAPI, AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import {
  AttendanceStatus,
  ScheduleTrackingType
} from "@v2/feature/attendance/attendance.interface";
import {
  calculateTotalEntriesDuration,
  getAttendanceEntriesStatus,
  getAttendanceStatusIcon
} from "@v2/feature/attendance/attendance.util";
import { SelectWorkOptions } from "@v2/feature/attendance/company/components/select-work-options.component";
import { AttendanceShiftEndpoints } from "@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
import { UserShiftHandler } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { extractTimeOnlyFromDateTimeString } from "@v2/util/date-format.util";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { NotFoundBlock } from "@/v2/components/not-found-block.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import { ApproversList } from "@/v2/feature/approval-rule/components/approvers-list.component";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
const DayNoToScheduleDay = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday"
};
const adjustTimeToLogDate = (timeString, logDate) => {
  if (!timeString) return "";
  return `${logDate}T${timeString.split("T")[1].slice(0, 5)}`;
};
const getAttendanceEntriesFromSchedule = (logDate, userSchedule) => {
  if (!logDate || !(userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed) || userSchedule.attendanceTypesAllowed.length === 0) return [];
  const date = new LocalDate(logDate);
  const dayInSchedule = DayNoToScheduleDay[date.getDate().getDay()];
  const daySchedule = userSchedule[dayInSchedule];
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);
  if (!daySchedule || !daySchedule[weekIndex] || daySchedule[weekIndex].length === 0) return [];
  return daySchedule[weekIndex].map((dayEntry, index) => {
    const allowedType = userSchedule.attendanceTypesAllowed.find((type) => type.id === dayEntry.typeId);
    if (!allowedType) return null;
    const startHour = adjustTimeToLogDate(dayEntry.from, logDate);
    const endHour = adjustTimeToLogDate(dayEntry.to, logDate);
    const startHourTimestamp = new Date(startHour);
    const endHourTimestamp = new Date(endHour);
    return {
      typeId: dayEntry.typeId,
      startHour,
      endHour,
      startHourTimestamp,
      endHourTimestamp,
      priority: index
    };
  }).filter(Boolean);
};
export const TrackTimeDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  onClose,
  afterClose,
  refresh,
  view,
  logDate,
  mode = void 0,
  weekAttendances
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    TimeTrackerManager,
    {
      isOpen,
      setIsOpen,
      userId,
      refresh,
      view,
      logDate,
      mode,
      weekAttendances
    }
  ) });
};
export const TimeTrackerManager = ({
  isOpen,
  setIsOpen,
  userId,
  refresh,
  view,
  logDate,
  mode = void 0,
  weekAttendances
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const { hasScopes, getScopesContext } = useScopes();
  const { getCachedUserById } = useCachedUsers();
  const canManageAttendanceForUser = userId && hasScopes(["attendance:manager"], getScopesContext({ userId }));
  const { data: existingEntries, mutate: refreshExistingEntries } = useApiClient(
    logDate && userId ? AttendanceEndpoints.getUserAttendanceEntriesByLogDate(userId, logDate) : null,
    {
      suspense: false
    }
  );
  const { data: userSchedule } = useApiClient(
    logDate && userId ? AttendanceEndpoints.getUserAttendanceScheduleByLogDate(userId, logDate) : userId ? AttendanceEndpoints.getUserAttendanceSchedule(userId) : null,
    { suspense: false }
  );
  const { data: todaysShift, mutate: refreshShift } = useApiClient(
    (userSchedule == null ? void 0 : userSchedule.trackingType) === ScheduleTrackingType.ClockInClockOut && userId ? AttendanceShiftEndpoints.getTodaysShiftByUser(userId) : null
  );
  const userDataObject = useMemo(() => userId ? getCachedUserById(userId) : null, [userId, getCachedUserById]);
  const refreshTodaysShift = useCallback(() => __async(void 0, null, function* () {
    if (refreshShift) yield refreshShift();
  }), [refreshShift]);
  const refreshLocalAttendanceEntries = useCallback(() => __async(void 0, null, function* () {
    if (refreshExistingEntries) yield refreshExistingEntries();
  }), [refreshExistingEntries]);
  const [isEditMode, setIsEditMode] = useState(Boolean(mode === "edit" || mode === "new"));
  useEffect(() => {
    if (isOpen) setIsEditMode(Boolean(mode === "edit" || mode === "new"));
  }, [mode, isOpen]);
  if (!userId || !logDate) {
    return /* @__PURE__ */ jsx(NotFoundBlock, { title: "Oops", subtitle: "User or logging not found, try again later" });
  }
  return /* @__PURE__ */ jsx(Fragment, { children: existingEntries && !isEditMode ? /* @__PURE__ */ jsx(
    ViewTrackTimeDrawerContent,
    {
      attendanceEntries: existingEntries != null ? existingEntries : [],
      setEditMode: setIsEditMode,
      refresh: () => __async(void 0, null, function* () {
        yield Promise.all([refresh(), refreshLocalAttendanceEntries()]);
      }),
      setIsOpen,
      userSchedule,
      userId,
      logDate
    }
  ) : isEditMode && (canManageAttendanceForUser || (userSchedule == null ? void 0 : userSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut || logDate && logDate === (todaysShift == null ? void 0 : todaysShift.logDate) && (todaysShift == null ? void 0 : todaysShift.isEnded)) ? /* @__PURE__ */ jsx(
    EditTrackTimeDrawerContent,
    {
      refresh: () => __async(void 0, null, function* () {
        yield Promise.all([refresh(), refreshLocalAttendanceEntries()]);
      }),
      userId,
      setIsOpen,
      setEditMode: setIsEditMode,
      attendanceEntries: existingEntries != null ? existingEntries : [],
      view,
      logDate,
      userSchedule
    }
  ) : isEditMode && logDate && userId && (userSchedule == null ? void 0 : userSchedule.trackingType) === ScheduleTrackingType.ClockInClockOut ? /* @__PURE__ */ jsx(
    UserShiftHandler,
    {
      selectedDate: logDate,
      refresh: () => __async(void 0, null, function* () {
        yield Promise.all([refresh(), refreshTodaysShift(), refreshLocalAttendanceEntries()]);
      }),
      shift: todaysShift,
      userSiteId: (_b = (_a = userDataObject == null ? void 0 : userDataObject.role) == null ? void 0 : _a.site) == null ? void 0 : _b.id,
      currentWeekAttendanceEntries: weekAttendances != null ? weekAttendances : [],
      attendanceSchedule: userSchedule,
      mode: "drawer"
    }
  ) : /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ViewTrackTimeDrawerContent.attendance") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: "20px" }, children: polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: "Please try again." }) })
  ] }) });
};
export const EditTrackTimeDrawerContent = ({
  userId,
  refresh,
  setIsOpen,
  setEditMode,
  attendanceEntries,
  logDate,
  view,
  showDatePicker = false,
  userSchedule
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getCachedUserById } = useCachedUsers({ refresh: true });
  const user = useMemo(() => {
    var _a2;
    return userId ? (_a2 = getCachedUserById(userId)) != null ? _a2 : null : null;
  }, [userId, getCachedUserById]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b;
      if (!values.userId) {
        showMessage(polyglot.t("EditTrackTimeDrawerContent.errorMessages.select"), "error");
        return;
      }
      const formattedAttendanceEntries = (_b = (_a2 = values == null ? void 0 : values.attendanceEntries) == null ? void 0 : _a2.filter((a) => a.startHour.length > 0 && a.endHour.length > 0 && typeof a.typeId === "number").map((entry) => {
        var _a3;
        return __spreadProps(__spreadValues({}, entry), {
          notes: (_a3 = entry.notes) != null ? _a3 : null
        });
      })) != null ? _b : [];
      try {
        setLoading(true);
        const attendanceEntries2 = formattedAttendanceEntries;
        yield AttendanceAPI.createAttendanceEntriesForDate(values.userId, values.logDate, attendanceEntries2);
        showMessage(polyglot.t("EditTrackTimeDrawerContent.successMessages.submit"), "success");
        yield refresh();
        if (setEditMode) setEditMode(false);
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("EditTrackTimeDrawerContent.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, setEditMode, showMessage, refresh]
  );
  const formik = useFormik({
    initialValues: {
      userId,
      logDate,
      attendanceEntries: attendanceEntries.map((entry, index) => ({
        typeId: entry.typeId,
        startHour: entry.startHour,
        endHour: entry.endHour,
        startHourTimestamp: entry.startHourTimestamp,
        endHourTimestamp: entry.endHourTimestamp,
        priority: index,
        notes: entry.notes
      }))
    },
    validationSchema: Yup.object({
      userId: Yup.number().integer().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.userRequired")),
      logDate: Yup.string().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateRequired")),
      attendanceEntries: Yup.array().of(
        Yup.object({
          typeId: Yup.number().integer().typeError(polyglot.t("EditTrackTimeDrawerContent.errorMessages.workTypeInvalid")).required(),
          startHour: Yup.string().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.starthourRequired")),
          endHour: Yup.string().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.endHourRequired")),
          startHourTimestamp: Yup.date().typeError(polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateInvalid")).required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.starthourRequired")),
          endHourTimestamp: Yup.date().typeError(polyglot.t("EditTrackTimeDrawerContent.errorMessages.dateInvalid")).required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.endHourRequired")),
          priority: Yup.number().required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.priorityRequired")),
          notes: Yup.string().max(255, polyglot.t("EditTrackTimeDrawerContent.errorMessages.maximumChars")).nullable().notRequired()
        })
      ).min(1, polyglot.t("EditTrackTimeDrawerContent.errorMessages.minOne")).required(polyglot.t("EditTrackTimeDrawerContent.errorMessages.minOne"))
    }),
    onSubmit
  });
  useEffect(() => {
    formik.setFieldValue("logDate", logDate);
    formik.setFieldValue("userId", userId);
  }, [logDate, userId]);
  useEffect(() => {
    const entries = attendanceEntries.length > 0 ? attendanceEntries.map((entry, index) => ({
      typeId: entry.typeId,
      startHour: entry.startHour,
      endHour: entry.endHour,
      startHourTimestamp: entry.startHourTimestamp,
      endHourTimestamp: entry.endHourTimestamp,
      priority: index,
      notes: entry.notes
    })) : userSchedule ? getAttendanceEntriesFromSchedule(formik.values.logDate, userSchedule) : [];
    formik.setFieldValue("attendanceEntries", entries);
  }, [attendanceEntries, userSchedule, formik.values.logDate]);
  const totalTime = useMemo(() => calculateTotalEntriesDuration(formik.values.attendanceEntries, polyglot), [
    formik.values.attendanceEntries,
    polyglot
  ]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: attendanceEntries ? polyglot.t("EditTrackTimeDrawerContent.edit") : polyglot.t("EditTrackTimeDrawerContent.track") }),
    !userId && !attendanceEntries && /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: view,
        onChange: (_, x) => {
          var _a2;
          const updateValue = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
          formik.setFieldValue("userId", updateValue);
          if (!updateValue) formik.setFieldValue("attendanceEntries", []);
        },
        value: formik.values.userId,
        label: polyglot.t("EditTrackTimeDrawerContent.employee"),
        error: (
          // show error from formik OR show error if user is not predefined & user has no schedule
          formik.touched.userId && Boolean(formik.errors.userId) || !userId && !userSchedule
        ),
        helperText: formik.touched.userId && formik.errors.userId || !userSchedule && polyglot.t("AttendanceDomain.noScheduleFound")
      }
    ),
    attendanceEntries && /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: polyglot.t("EditTrackTimeDrawerContent.employee"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m5, display: "flex", alignItem: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId, size: "xxsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: user ? user.displayName || `${user.firstName} ${user.lastName}` : "" })
        ] })
      }
    ),
    showDatePicker ? /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.logDate) != null ? _a : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) formik.setFieldValue("logDate", value);
          else formik.setFieldValue("logDate", "");
        },
        name: "logDate",
        label: polyglot.t("EditTrackTimeDrawerContent.period"),
        error: !!formik.errors.logDate && formik.touched.logDate,
        helperText: formik.touched.logDate && formik.errors.logDate
      }
    ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("EditTrackTimeDrawerContent.period") }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: new LocalDate(formik.values.logDate).toLocaleDateString(void 0, {
        day: "2-digit",
        month: "short",
        year: "numeric"
      }) })
    ] }),
    userId && !userSchedule && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "RedDark", children: polyglot.t("EditTrackTimeDrawerContent.noScheduleNotification") }),
    userSchedule && formik.values.logDate && /* @__PURE__ */ jsx(
      SelectWorkOptions,
      {
        formik,
        logDate: formik.values.logDate,
        attendanceSchedule: userSchedule,
        entries: formik.values.attendanceEntries,
        setEntries: (value) => {
          formik.setFieldValue(
            "attendanceEntries",
            value.map((o, index) => __spreadProps(__spreadValues({}, o), { priority: index }))
          );
        },
        showErrorMessage: Boolean(formik.touched.attendanceEntries && !!formik.errors.attendanceEntries)
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("EditTrackTimeDrawerContent.total") }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: totalTime })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setIsOpen(false), fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          fullWidth: true,
          loading,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ),
      /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: Boolean(anchorEl),
          onClose: () => setAnchorEl(null),
          anchorEl,
          takeAction: () => {
            formik.handleSubmit();
            setAnchorEl(null);
          },
          message: polyglot.t("EditTrackTimeDrawerContent.continueMessage"),
          callToAction: polyglot.t("General.yes")
        }
      )
    ] })
  ] }) });
};
const ViewTrackTimeDrawerContent = ({
  attendanceEntries,
  refresh,
  setEditMode,
  setIsOpen,
  userSchedule,
  userId,
  logDate
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { getScopesContext, hasScopes } = useScopes();
  const hasStdAttendanceScope = hasScopes(["attendance"], getScopesContext({ userId }));
  const canManageThis = hasScopes(["attendance:manager"], getScopesContext({ userId }));
  const dayStatus = getAttendanceEntriesStatus(attendanceEntries);
  const canApproveOrReject = useMemo(
    () => Boolean(
      attendanceEntries.filter((a) => a.status === AttendanceStatus.Submitted).every((a) => a.canApproveOrReject)
    ),
    [attendanceEntries]
  );
  const canForceApproveOrReject = useMemo(
    () => Boolean(
      attendanceEntries.filter((a) => a.status === AttendanceStatus.Submitted).every((a) => a.canForceApproveOrReject)
    ),
    [attendanceEntries]
  );
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const attendanceTypesAllowedById = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed) == null ? void 0 : _a2.reduce((response, type) => {
        response[type.id] = type;
        return response;
      }, {})) != null ? _b2 : {};
    },
    [userSchedule]
  );
  const attendanceUser = useMemo(() => getCachedUserById(userId), [getCachedUserById, userId]);
  const hasEntries = useMemo(() => Boolean(attendanceEntries && attendanceEntries.length > 0), [attendanceEntries]);
  const approver = useMemo(
    // TODO:  here we assume all entries in a day are always approved / rejected by the same person
    () => attendanceEntries[0] && attendanceEntries[0].approvedBy && getCachedUserById(attendanceEntries[0].approvedBy),
    [getCachedUserById, attendanceEntries]
  );
  const deleteRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      yield AttendanceAPI.deleteUserAttendanceEntriesByLogDate(userId, logDate);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.delete"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (e) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.delete", { errorMessage: nestErrorMessage(e) }),
        "error"
      );
    }
  }), [showMessage, userId, logDate, refresh, setIsOpen, polyglot]);
  const approveRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsApproveLoading(true);
      yield AttendanceAPI.approveUserAttendanceEntriesByLogDate(userId, logDate, AttendanceStatus.Approved);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.approve"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.approve", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsApproveLoading(false);
    }
  }), [setIsOpen, userId, logDate, showMessage, refresh, polyglot]);
  const rejectRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsRejectLoading(true);
      yield AttendanceAPI.approveUserAttendanceEntriesByLogDate(userId, logDate, AttendanceStatus.Rejected);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.reject"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.reject", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsRejectLoading(false);
    }
  }), [setIsOpen, userId, logDate, showMessage, refresh, polyglot]);
  const forceApproveRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canForceApproveOrReject) return;
    try {
      setIsApproveLoading(true);
      yield AttendanceAPI.forceApprovalForAttendanceByLogDate(userId, logDate, AttendanceStatus.Approved);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.approve"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.approve", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsApproveLoading(false);
    }
  }), [userId, logDate, setIsOpen, showMessage, refresh, polyglot, canForceApproveOrReject]);
  const forceRejectRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canForceApproveOrReject) return;
    try {
      setIsRejectLoading(true);
      yield AttendanceAPI.forceApprovalForAttendanceByLogDate(userId, logDate, AttendanceStatus.Rejected);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.reject"), "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.reject", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsRejectLoading(false);
    }
  }), [userId, logDate, setIsOpen, showMessage, refresh, polyglot, canForceApproveOrReject]);
  const isPayrunLinkedToPayrun = useMemo(
    () => attendanceEntries.length > 0 && attendanceEntries.some((entry) => entry.ukPayrunId || entry.globalPayrunId),
    [attendanceEntries]
  );
  const editTitle = useMemo(() => {
    if (isPayrunLinkedToPayrun) {
      return polyglot.t("ViewTrackTimeDrawerContent.attendanceLinkedToPayrun");
    }
    return polyglot.t("ViewTrackTimeDrawerContent.actions");
  }, [isPayrunLinkedToPayrun, polyglot]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ViewTrackTimeDrawerContent.attendance") }),
      hasEntries && (canManageThis || hasStdAttendanceScope && (dayStatus === AttendanceStatus.InProgress || dayStatus === AttendanceStatus.Submitted) && userId === currentUser.userId && (userSchedule == null ? void 0 : userSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut) && /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: isPayrunLinkedToPayrun ? [] : [
            {
              icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
              handler: () => setEditMode(true),
              label: polyglot.t("General.edit")
            },
            {
              icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
              handler: deleteRequest,
              label: polyglot.t("General.delete")
            }
          ],
          actionButtonDetails: {
            type: "iconButton",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: editTitle,
            icon: /* @__PURE__ */ jsx(ActionsSmall, { width: "14px", height: "14px" })
          },
          disabled: isPayrunLinkedToPayrun
        }
      )
    ] }),
    attendanceUser && /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.employee"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: attendanceUser.userId, size: "xxsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: attendanceUser.displayName || `${attendanceUser.firstName} ${attendanceUser.lastName}` })
        ] })
      }
    ),
    dayStatus && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.status"),
        value: getAttendanceStatusIcon(dayStatus, false, polyglot)
      }
    ),
    dayStatus && approver && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: `${dayStatus} by`,
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: approver.userId, size: "xxsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: approver.displayName || `${approver.firstName} ${approver.lastName}` })
        ] })
      },
      "approver"
    ),
    attendanceEntries[0] && attendanceEntries[0].status === AttendanceStatus.Submitted && /* @__PURE__ */ jsx(ApproversList, { approverSteps: attendanceEntries[0].approverSteps }),
    attendanceEntries[0] && ((_a = attendanceEntries[0]) == null ? void 0 : _a.approvedByIds) && attendanceEntries[0].approvedByIds.length > 0 && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("General.approvedBy"),
        value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: (_b = attendanceEntries[0]) == null ? void 0 : _b.approvedByIds })
      },
      "approvedBy"
    ),
    attendanceEntries[0] && ((_c = attendanceEntries[0]) == null ? void 0 : _c.rejectedByIds) && attendanceEntries[0].rejectedByIds.length > 0 && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("General.rejectedBy"),
        value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: (_d = attendanceEntries[0]) == null ? void 0 : _d.rejectedByIds })
      },
      "rejectedBy"
    ),
    attendanceEntries[0] && attendanceEntries[0].approvedOnTimestamp && [AttendanceStatus.Approved, AttendanceStatus.Rejected].includes(attendanceEntries[0].status) && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: attendanceEntries[0].status === AttendanceStatus.Approved ? polyglot.t("AbsenceViewDrawerContent.dateApproved") : polyglot.t("AbsenceViewDrawerContent.dateRejected"),
        value: getDateString(attendanceEntries[0].approvedOnTimestamp)
      },
      "date-approved"
    ),
    userSchedule && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.schedule"),
        value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: userSchedule.name }) })
      },
      "schedule"
    ),
    /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("ViewTrackTimeDrawerContent.period"),
        value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: new LocalDate(logDate).toLocaleDateString() }) })
      }
    ),
    attendanceEntries.map((entry, index) => {
      var _a2, _b2, _c2;
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(
            DrawerViewerItem,
            {
              title: (_b2 = (_a2 = attendanceTypesAllowedById[entry.typeId]) == null ? void 0 : _a2.name) != null ? _b2 : "N/A",
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: `${extractTimeOnlyFromDateTimeString(
                entry.startHour
              )} - ${extractTimeOnlyFromDateTimeString(entry.endHour)}` })
            },
            "entry-name"
          ),
          /* @__PURE__ */ jsx(
            DrawerViewerItem,
            {
              title: ((_c2 = entry.type) == null ? void 0 : _c2.name) ? polyglot.t("ViewTrackTimeDrawerContent.typeLength", { type: entry.type.name }) : polyglot.t("ViewTrackTimeDrawerContent.length"),
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey, minWidth: "80px" }, children: convertMinutesToClockHours(entry.length, polyglot) })
            },
            "entry-length"
          )
        ] }),
        entry.notes && /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: {
              color: themeColors.DarkGrey,
              wordWrap: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal"
            },
            children: entry.notes
          }
        )
      ] }, index);
    }),
    hasEntries && dayStatus === AttendanceStatus.Submitted && canApproveOrReject ? /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          fullWidth: true,
          name: polyglot.t("General.reject"),
          loading: isRejectLoading,
          onClick: rejectRequest,
          sizeVariant: "medium",
          colorVariant: "secondary"
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          name: polyglot.t("General.approve"),
          loading: isApproveLoading,
          fullWidth: true,
          onClick: approveRequest,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }) : hasEntries && dayStatus === AttendanceStatus.Submitted && canForceApproveOrReject ? /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          fullWidth: true,
          name: polyglot.t("General.forceReject"),
          loading: isRejectLoading,
          onClick: forceRejectRequest,
          sizeVariant: "medium",
          colorVariant: "secondary"
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          name: polyglot.t("General.forceApprove"),
          loading: isApproveLoading,
          fullWidth: true,
          onClick: forceApproveRequest,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }) : !hasEntries ? /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "button",
        fullWidth: true,
        name: polyglot.t("ViewNewAttendance.logHours"),
        loading: isRejectLoading,
        onClick: () => {
          setEditMode(true);
        },
        sizeVariant: "medium",
        colorVariant: "secondary"
      }
    ) }) : null
  ] });
};
