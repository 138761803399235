"use strict";
import { ReportSQLOperator } from "@v2/feature/reports/reports.interface";
export const operatorOptionsByType = (type) => {
  if (type === "number")
    return [
      { label: "Is equal to", value: ReportSQLOperator.eq },
      { label: "Is not equal to", value: ReportSQLOperator.not },
      { label: "Is any of", value: ReportSQLOperator.in },
      { label: "Is greater than", value: ReportSQLOperator.gt },
      { label: "Is greater or equal to", value: ReportSQLOperator.gte },
      { label: "Is less than", value: ReportSQLOperator.lt },
      { label: "Is less or equal to", value: ReportSQLOperator.lte },
      { label: "Is between", value: ReportSQLOperator.between },
      { label: "Is known", value: ReportSQLOperator.isKnown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown },
      { label: "Is younger than", value: ReportSQLOperator.youngerThan },
      { label: "Is older than", value: ReportSQLOperator.olderThan }
    ];
  if (type === "string")
    return [
      { label: "Is equal to", value: ReportSQLOperator.eq },
      { label: "Contains any of", value: ReportSQLOperator.contains },
      { label: "Does not contain", value: ReportSQLOperator.notContains },
      { label: "Starts with", value: ReportSQLOperator.startsWith },
      { label: "Ends with", value: ReportSQLOperator.endsWith },
      { label: "Is known", value: ReportSQLOperator.isKnown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown }
    ];
  if (type === "intOption")
    return [
      { label: "Is equal to", value: ReportSQLOperator.eq },
      { label: "Is any of", value: ReportSQLOperator.in },
      { label: "Is known", value: ReportSQLOperator.isKnown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown }
    ];
  if (type === "stringOption")
    return [
      { label: "Is equal to", value: ReportSQLOperator.eq },
      { label: "Is any of", value: ReportSQLOperator.in },
      { label: "Is known", value: ReportSQLOperator.isKnown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown }
    ];
  if (type === "dateonly" || type === "datetime")
    return [
      { label: "Is equal to", value: ReportSQLOperator.eq },
      { label: "Is before", value: ReportSQLOperator.lt },
      { label: "Is after", value: ReportSQLOperator.gt },
      { label: "Is between", value: ReportSQLOperator.between },
      { label: "Is younger than", value: ReportSQLOperator.youngerThan },
      { label: "Is older than", value: ReportSQLOperator.olderThan },
      // { label: 'Is not equal to', value: ReportSQLOperator.not },
      // THIS
      { label: "Is this", value: ReportSQLOperator.this },
      { label: "Is last", value: ReportSQLOperator.last },
      { label: "Is next", value: ReportSQLOperator.next },
      // DATE PART
      { label: "Calendar day of the month is", value: ReportSQLOperator.dayIs },
      { label: "Calendar month is", value: ReportSQLOperator.monthIs },
      { label: "Calendar year is", value: ReportSQLOperator.yearIs },
      { label: "Is known", value: ReportSQLOperator.isKnown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown }
    ];
  if (type === "boolean")
    return [
      { label: "Is", value: ReportSQLOperator.is },
      { label: "Is known", value: ReportSQLOperator.isKnown },
      { label: "Is unknown", value: ReportSQLOperator.isUnknown }
    ];
  return [];
};
