"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ExpenseModal } from "@v2/feature/payments/pages/components/expense-modal.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { ExpenseEndpoints } from "@/api-client/expense.api";
export const ExpenseRequestActionItem = ({
  selectedRow,
  refresh,
  afterClose
}) => {
  const { data: expense } = useApiClient(
    selectedRow ? ExpenseEndpoints.getExpensesById(selectedRow.id, selectedRow.userId) : null,
    { suspense: false }
  );
  const [isViewOpen, setIsViewOpen] = useState(false);
  useEffect(() => {
    setIsViewOpen(Boolean(selectedRow));
  }, [selectedRow]);
  return /* @__PURE__ */ jsx(
    ExpenseModal,
    {
      isOpen: isViewOpen,
      setIsOpen: setIsViewOpen,
      selectedExpense: expense,
      onActionPerformed: refresh,
      currentUserIsAdmin: false,
      onClose: () => {
        setIsViewOpen(false);
      },
      afterClose
    }
  );
};
