"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
export const normaliseYAxis = (series, percentage, unboundedMax, inputYAxisOptions) => {
  var _a, _b;
  const allDataPoints = series.flatMap((s) => s.data);
  const minValue = Math.min(...allDataPoints);
  const maxValue = Math.max(...allDataPoints);
  const range = maxValue - minValue;
  const yAxisOptions = {
    decimalsInFloat: 0
  };
  if (percentage) {
    const maxLimit = unboundedMax ? void 0 : 100;
    if (range <= 1) {
      yAxisOptions.min = 0;
      yAxisOptions.decimalsInFloat = (_a = inputYAxisOptions == null ? void 0 : inputYAxisOptions.decimalsInFloat) != null ? _a : 2;
    } else if (range <= 10) {
      if (maxValue > 90) {
        yAxisOptions.min = Math.floor(Math.max(0, minValue));
        yAxisOptions.max = maxLimit;
      } else {
        yAxisOptions.min = Math.floor(Math.max(0, minValue));
        yAxisOptions.max = Math.ceil(maxValue);
      }
      yAxisOptions.decimalsInFloat = (_b = inputYAxisOptions == null ? void 0 : inputYAxisOptions.decimalsInFloat) != null ? _b : 0;
    } else {
      yAxisOptions.min = 0;
      yAxisOptions.max = maxLimit;
    }
  }
  return __spreadValues(__spreadValues({}, yAxisOptions), inputYAxisOptions);
};
