"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { generatePath, Redirect } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { GROWTH_COMPANY_GOAL_ROUTE, GROWTH_ME_GOAL_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { GoalListItem } from "@/v2/feature/growth/goals/components/goal-list-item.component";
import { GoalMutationModal } from "@/v2/feature/growth/goals/components/goal-mutation-modal.component";
import { GoalStatus } from "@/v2/feature/growth/goals/goal.interface";
import { GoalEndpoints } from "@/v2/feature/growth/goals/goals.api";
import { useGoalsReach } from "@/v2/feature/growth/goals/hooks/use-goals-reach.hook";
import { useInfiniteScroll } from "@/v2/feature/growth/goals/hooks/use-infinite-scroll.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const headerHeightPx = 62;
const filtersSectionHeightPx = 46;
const goalPageSize = 10;
export const GoalsListPage = () => {
  const { polyglot } = usePolyglot();
  const reach = useGoalsReach();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const canCreateGoals = canAccessScopes(user, ["goals:all"]);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [redirectToGoalId, setRedirectToGoalId] = useState(null);
  const [pagedGoals, setPagedGoals] = useState({});
  const { data: paginatedGoals, refresh: refreshGoals, loading: loadingGoals } = useInfiniteScroll(
    GoalEndpoints.getGoals(reach, searchInput, page, goalPageSize, filterString).url
  );
  const allGoals = useMemo(() => {
    return Object.values(pagedGoals).flat();
  }, [pagedGoals]);
  useEffect(() => {
    setPage(1);
  }, [reach, searchInput, filterString]);
  useEffect(() => {
    var _a;
    if (!paginatedGoals) return;
    const bodyPage = (_a = paginatedGoals.page) != null ? _a : 1;
    if (bodyPage === 1) {
      setPagedGoals({ [bodyPage]: paginatedGoals.items });
      return;
    }
    setPagedGoals((prev) => {
      return __spreadProps(__spreadValues({}, prev), { [bodyPage]: paginatedGoals.items });
    });
  }, [paginatedGoals, setPagedGoals]);
  const onLoadNextPage = useCallback(() => {
    if (loadingGoals || !(paginatedGoals == null ? void 0 : paginatedGoals.nextPage)) return;
    setPage(paginatedGoals.nextPage);
  }, [setPage, paginatedGoals, loadingGoals]);
  if (redirectToGoalId) {
    return /* @__PURE__ */ jsx(
      Redirect,
      {
        to: generatePath(reach === ReachType.Company ? GROWTH_COMPANY_GOAL_ROUTE : GROWTH_ME_GOAL_ROUTE, {
          goalId: redirectToGoalId
        })
      }
    );
  }
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("GoalsPage.goals") }),
        actions: canCreateGoals ? /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsDrawerOpen(true), children: polyglot.t("GoalsPage.newGoal") }) : null,
        showAction: Boolean(Array.isArray(allGoals) && allGoals.length > 0),
        sx: { padding: "0 1.5rem", display: "flex", justifyContent: "space-between", width: "100%" }
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHorizontalPadding: true, sx: { overflowY: "hidden" }, children: [
      !loadingGoals && (!allGoals || allGoals && allGoals.length === 0) && !searchInput && !filterString ? /* @__PURE__ */ jsx(
        EmptyStateBox,
        {
          title: polyglot.t(canCreateGoals ? "GoalsPage.createFirstGoal.title" : "GoalsPage.noGoals.title"),
          subtitle: canCreateGoals ? polyglot.t("GoalsPage.noGoals.subtitle") : /* @__PURE__ */ jsxs(Fragment, { children: [
            polyglot.t("GoalsPage.noGoals.subtitle"),
            /* @__PURE__ */ jsx("br", {}),
            /* @__PURE__ */ jsx("br", {}),
            polyglot.t("GoalsPage.noGoals.subtitle2")
          ] }),
          buttonTitle: polyglot.t("GoalsPage.createFirstGoal.buttonTitle"),
          onClick: canCreateGoals ? () => setIsDrawerOpen(true) : void 0,
          style: { marginLeft: spacing.m20 }
        }
      ) : /* @__PURE__ */ jsx(
        GoalsList,
        {
          goals: allGoals != null ? allGoals : [],
          reach,
          searchInput,
          setSearchInput,
          filterString,
          setFilterString,
          onLoadNextPage,
          loadingGoals
        }
      ),
      /* @__PURE__ */ jsx(
        GoalMutationModal,
        {
          mode: "create",
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refresh: (goalId) => __async(void 0, null, function* () {
            setRedirectToGoalId(goalId);
            refreshGoals == null ? void 0 : refreshGoals();
          })
        }
      )
    ] })
  ] });
};
const GoalsList = ({
  goals,
  searchInput,
  setSearchInput,
  filterString,
  setFilterString,
  onLoadNextPage,
  loadingGoals
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const parentRef = useRef(null);
  const listVirtualizer = useVirtualizer({
    count: (_a = goals == null ? void 0 : goals.length) != null ? _a : 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 91,
    overscan: 0
  });
  useEffect(() => {
    const [lastIndex] = [...listVirtualizer.getVirtualIndexes()].reverse();
    if (!goals || !goals.length || loadingGoals) return;
    if (lastIndex < goals.length - 1) return;
    onLoadNextPage == null ? void 0 : onLoadNextPage();
  }, [listVirtualizer, goals, onLoadNextPage, loadingGoals]);
  const GoalStatusFilter = useMemo(
    () => [
      { label: polyglot.t("GoalsPage.filters.planned"), value: GoalStatus.Planned },
      { label: polyglot.t("GoalsPage.filters.active"), value: GoalStatus.Active },
      { label: polyglot.t("GoalsPage.filters.achieved"), value: GoalStatus.Achieved }
    ],
    [polyglot]
  );
  const virtualItems = listVirtualizer.getVirtualItems();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          gap: spacing.s1,
          paddingLeft: spacing.p16,
          paddingRight: spacing.p16,
          paddingBottom: spacing.p16
        },
        children: [
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                setSearchInput(e.target.value);
              },
              debounceSearchWait: 400,
              style: {
                width: "347px"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            CategoryFilters,
            {
              filterTypes: {
                status: GoalStatusFilter
              },
              setFilterString,
              filterString
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        ref: parentRef,
        sx: {
          height: `calc(100vh - ${headerHeightPx}px - ${filtersSectionHeightPx}px)`,
          overflow: "auto"
        },
        children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              height: `${listVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative"
            },
            children: /* @__PURE__ */ jsx(
              "div",
              {
                style: {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${(_c = (_b = virtualItems == null ? void 0 : virtualItems[0]) == null ? void 0 : _b.start) != null ? _c : 0}px)`
                },
                children: listVirtualizer.getVirtualItems().map((virtualItem) => {
                  const goal = goals == null ? void 0 : goals[virtualItem.index];
                  return goal ? /* @__PURE__ */ jsx("div", { "data-index": virtualItem.index, ref: listVirtualizer.measureElement, children: /* @__PURE__ */ jsx(GoalListItem, { goal, showDescription: false }, virtualItem.index) }, virtualItem.index) : null;
                })
              }
            )
          }
        )
      }
    )
  ] });
};
