"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, AlertTitle, Box, Button, Card, CardContent, IconButton, Typography } from "@mui/material";
import { v4 } from "uuid";
import { UploadInput } from "@/component/forms/UploadInput";
import { EntityImportDatagridValidation } from "@/v2/feature/entity-import/components/entity-import-datagrid-validation.component";
import {
  PAYROLL_IMPORT_CSV_ENDPOINT,
  PayrollImportAPI
} from "@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.api";
import { getRowIdsWithErrors, getTotalErrorCount, hasErrors } from "@/v2/util/data-import.util";
import PayrollImportCsvTemplate from "@/zelt-payroll-import-template.csv";
export function PayrollImportCsvPage({
  goBack,
  goNext
}) {
  var _a;
  const [uploadResponse, setUploadResponse] = useState();
  const [isTableEditable, setIsTableEditable] = useState(false);
  const [tableDataAsCsv, setTableDataAsCsv] = useState("");
  const [loading, setLoading] = useState(false);
  const tableColumns = [
    {
      headerName: "First Name",
      field: "firstName",
      editable: isTableEditable,
      minWidth: 90,
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "lastName",
      editable: isTableEditable,
      minWidth: 90,
      flex: 1
    },
    {
      headerName: "Work Email",
      field: "workEmail",
      editable: isTableEditable,
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Tax Code",
      field: "taxCode",
      editable: isTableEditable,
      minWidth: 90,
      flex: 1
    },
    {
      headerName: "NINO",
      field: "niNumber",
      editable: isTableEditable,
      minWidth: 70,
      flex: 1
    },
    {
      headerName: "Sex",
      field: "legalGender",
      editable: isTableEditable,
      minWidth: 110,
      flex: 1
    },
    {
      headerName: "NI Category",
      field: "niTable",
      editable: isTableEditable,
      minWidth: 100,
      flex: 1
    },
    {
      headerName: "Student Loan",
      field: "studentLoan",
      editable: isTableEditable,
      minWidth: 110,
      flex: 1
    },
    {
      headerName: "Postgraduate Loan",
      field: "postgradLoan",
      editable: isTableEditable,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: "Is/Was a director",
      field: "isDirector",
      editable: isTableEditable,
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Directorship Start Date",
      field: "directorFrom",
      editable: isTableEditable,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: "Directorship End Date",
      field: "directorTo",
      editable: isTableEditable,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: "Calculation method",
      field: "week1Month1",
      editable: isTableEditable,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: "Is a new starter",
      field: "openingNotNewStarter",
      editable: isTableEditable,
      minWidth: 120,
      flex: 1
    },
    {
      headerName: "Starter declaration (Only for new starters)",
      field: "starterDeclaration",
      editable: isTableEditable,
      minWidth: 150,
      flex: 1
    },
    {
      headerName: "Previous employer - Gross income (Only for new starters)",
      field: "openingPreviousGross",
      editable: isTableEditable,
      minWidth: 160,
      flex: 1
    },
    {
      headerName: "Previous employer - Income Tax paid (Only for new starters)",
      field: "openingPreviousTax",
      editable: isTableEditable,
      minWidth: 160,
      flex: 1
    }
  ];
  const handleUpload = (result) => {
    result == null ? void 0 : result.errors.map((error) => error.id = v4());
    setUploadResponse(result);
    if (result && !result.errors.length) {
      goNext(result);
    }
  };
  const tryAgain = () => {
    setUploadResponse(void 0);
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { width: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" },
      children: [
        /* @__PURE__ */ jsxs(Box, { textAlign: "center", paddingY: 3, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: "Upload Payroll Information" }),
          /* @__PURE__ */ jsx(Typography, { variant: "body2", children: "Use our Template to see what information is required to upload Payroll information for your employees. Make sure to provide their work email that is saved in Zelt." })
        ] }),
        uploadResponse && uploadResponse.errors.length > 0 && /* @__PURE__ */ jsxs(
          Alert,
          {
            severity: hasErrors(uploadResponse.errors) ? "error" : "success",
            sx: { marginBottom: 3, width: "100%", marginX: "auto" },
            action: hasErrors(uploadResponse.errors) && /* @__PURE__ */ jsx(
              Button,
              {
                color: "inherit",
                size: "small",
                variant: "outlined",
                onClick: () => tryAgain(),
                sx: { marginY: "auto" },
                children: "Try again"
              }
            ),
            children: [
              /* @__PURE__ */ jsxs(AlertTitle, { children: [
                hasErrors(uploadResponse.errors) ? "Check Formatting" : "Import Completed. You can see imported records below",
                " "
              ] }),
              (uploadResponse == null ? void 0 : uploadResponse.importedCount) > 0 && `${((_a = uploadResponse == null ? void 0 : uploadResponse.errors) == null ? void 0 : _a.length) - getTotalErrorCount(uploadResponse.errors)} rows imported successfully. `,
              hasErrors(uploadResponse.errors) && `${getTotalErrorCount(
                uploadResponse.errors
              )} rows could not be imported. Please double-click on the error fields to correct them and then hit Enter to save each field.`
            ]
          }
        ),
        /* @__PURE__ */ jsx(Card, { dir: "ltr", sx: { minWidth: "100%" }, children: /* @__PURE__ */ jsxs(
          CardContent,
          {
            sx: {
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            },
            children: [
              !uploadResponse && !loading && /* @__PURE__ */ jsxs(Box, { width: 790, paddingY: 10, paddingX: 6, children: [
                /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx("a", { href: PayrollImportCsvTemplate, children: /* @__PURE__ */ jsx(Button, { variant: "contained", onClick: () => {
                }, startIcon: /* @__PURE__ */ jsx(DownloadIcon, {}), sx: { my: 3 }, children: "Download Blank CSV Template" }) }) }),
                /* @__PURE__ */ jsx(
                  UploadInput,
                  {
                    onChange: (response) => handleUpload(response),
                    endpoint: PAYROLL_IMPORT_CSV_ENDPOINT
                  }
                )
              ] }),
              uploadResponse && uploadResponse.errors.length > 0 && /* @__PURE__ */ jsxs("div", { style: { width: "100%" }, children: [
                !isTableEditable && hasErrors(uploadResponse.errors) && /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    "aria-label": "settings",
                    onClick: () => setIsTableEditable(!isTableEditable),
                    sx: { display: "flex", marginLeft: "auto" },
                    children: /* @__PURE__ */ jsx(EditIcon, {})
                  }
                ),
                isTableEditable && hasErrors(uploadResponse.errors) && /* @__PURE__ */ jsx(
                  Button,
                  {
                    variant: "contained",
                    onClick: () => __async(this, null, function* () {
                      setLoading(true);
                      setIsTableEditable(false);
                      const response = yield PayrollImportAPI.importPayrollCsvText(tableDataAsCsv);
                      setUploadResponse(void 0);
                      handleUpload(response);
                    }),
                    sx: { display: "flex", marginLeft: "auto" },
                    children: "Save"
                  }
                ),
                /* @__PURE__ */ jsx(
                  EntityImportDatagridValidation,
                  {
                    rows: uploadResponse.errors,
                    columns: tableColumns,
                    setTableDataAsCsv,
                    rowIdsToExport: getRowIdsWithErrors(uploadResponse.errors)
                  }
                )
              ] })
            ]
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { my: 2, display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Button, { onClick: goBack, sx: { mr: 1 }, children: "Back" }) })
      ]
    }
  );
}
