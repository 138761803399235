"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ReviewEntriesPersonalStartModal } from "@/v2/feature/growth/reviews/features/review-personal/review-entry/components/review-entries-personal-start-modal.component";
export const ReviewActionItem = ({
  selectedRow,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (selectedRow) {
      setIsOpen(true);
    }
  }, [setIsOpen, selectedRow]);
  return /* @__PURE__ */ jsx(
    ReviewEntriesPersonalStartModal,
    {
      isOpen,
      setIsOpen,
      afterClose,
      onClose: () => setIsOpen(false),
      selectedEntry: selectedRow.entry
    }
  );
};
