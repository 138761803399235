"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Switch } from "react-router-dom";
import { GoalPage } from "./pages/goal.page";
import { GoalsListPage } from "./pages/goals-list.page";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { GROWTH_ME_GOALS_ROUTE, GROWTH_ME_GOAL_ROUTE } from "@/lib/routes";
export const GoalsMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["goals"],
        path: GROWTH_ME_GOALS_ROUTE,
        component: GoalsListPage,
        exact: true
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["goals"], path: GROWTH_ME_GOAL_ROUTE, component: GoalPage, exact: true })
  ] });
};
