"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { SurveyEntriesPersonalStartModal } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entries-personal/components/survey-entries-personal-start-modal.component";
export const SurveyActionItem = ({
  selectedRow,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (selectedRow) {
      setIsOpen(true);
    }
  }, [setIsOpen, selectedRow]);
  return /* @__PURE__ */ jsx(
    SurveyEntriesPersonalStartModal,
    {
      isOpen,
      setIsOpen,
      afterClose,
      onClose: () => setIsOpen(false),
      selectedEntry: selectedRow == null ? void 0 : selectedRow.entry
    }
  );
};
