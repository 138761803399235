"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { CircularProgressChart } from "@v2/feature/absence/me/policies/policy-breakdown/components/circular-progress-chart.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { round2Digits } from "@v2/util/number.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsencePolicyAllowanceType } from "@/v2/feature/absence/absence.interface";
import { convertMinutesToClockHours } from "@/v2/feature/absence/absence.util";
import { themeColors } from "@/v2/styles/colors.styles";
export const PolicyAnalytics = ({ primaryBalance }) => {
  const { polyglot } = usePolyglot();
  const userTotalAllowance = useMemo(() => {
    var _a, _b, _c, _d;
    if (!((_a = primaryBalance == null ? void 0 : primaryBalance.proRatedAbsenceAllowance) == null ? void 0 : _a.totalProratedAllowance)) return 0;
    return primaryBalance.proRatedAbsenceAllowance.totalProratedAllowance + ((_c = (_b = primaryBalance.carryOver) == null ? void 0 : _b.remaining) != null ? _c : 0) + ((_d = primaryBalance.oneOffAdjustment) != null ? _d : 0);
  }, [primaryBalance]);
  const percentage = useMemo(() => {
    return !(primaryBalance == null ? void 0 : primaryBalance.currentBalance) || !primaryBalance.userAllowance ? 0 : primaryBalance.currentBalance * 100 / userTotalAllowance;
  }, [primaryBalance, userTotalAllowance]);
  const accruedRemainingInMinutes = useMemo(() => {
    var _a, _b, _c;
    if (!primaryBalance) return 0;
    const totalAccruedWithoutPHAccrualUsedOrBooked = (_b = (_a = primaryBalance.proRatedAbsenceAllowance) == null ? void 0 : _a.totalAccruedWithoutPHAccrualUsedOrBooked) != null ? _b : 0;
    return totalAccruedWithoutPHAccrualUsedOrBooked - ((_c = primaryBalance.unitsTaken.history) != null ? _c : 0);
  }, [primaryBalance]);
  const formattedBalanceInDays = useMemo(() => {
    const balance = primaryBalance ? primaryBalance.isAccrualPolicy ? round2Digits(accruedRemainingInMinutes / primaryBalance.currentAverageWorkDayLength) : primaryBalance.currentBalanceInDays : 0;
    return polyglot.t("PolicyAnalytics.numDays", { smart_count: balance != null ? balance : 0 });
  }, [primaryBalance, accruedRemainingInMinutes, polyglot]);
  const formattedBalanceInHours = useMemo(() => {
    const balance = primaryBalance ? primaryBalance.isAccrualPolicy ? accruedRemainingInMinutes : primaryBalance.currentBalance : 0;
    return convertMinutesToClockHours(balance != null ? balance : 0, polyglot);
  }, [primaryBalance, accruedRemainingInMinutes, polyglot]);
  if (!primaryBalance) return null;
  return /* @__PURE__ */ jsx(Fragment, { children: primaryBalance.policyAllowanceType !== AbsencePolicyAllowanceType.Unlimited && primaryBalance.policyAllowanceType !== AbsencePolicyAllowanceType.UnlimitedHourly ? /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
    /* @__PURE__ */ jsx(
      CircularProgressChart,
      {
        value: percentage,
        color: primaryBalance.policyColor,
        size: 168,
        thickness: 3,
        baseColor: themeColors.white
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        },
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: primaryBalance.currentAverageWorkDayLength ? formattedBalanceInDays : formattedBalanceInHours }),
          primaryBalance.currentAverageWorkDayLength && /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: formattedBalanceInHours }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, width: "100px", alignSelf: "center" }, children: primaryBalance.policyName })
        ] })
      }
    )
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
    /* @__PURE__ */ jsx(
      CircularProgressChart,
      {
        value: 100,
        color: primaryBalance.policyColor,
        size: 168,
        thickness: 3,
        baseColor: themeColors.white
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        },
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("PolicyAnalytics.unlimited") }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: primaryBalance.policyName })
        ] })
      }
    )
  ] }) });
};
