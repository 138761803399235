"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { QuestionsDrawer } from "@v2/feature/device/components/device-details/questions-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceAPI, DeviceEndpoints } from "@/v2/feature/device/device.api";
import { spacing } from "@/v2/styles/spacing.styles";
const ContentContainer = ({ children }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children });
const UnlockPinDisplay = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(ContentContainer, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("LockDevice.unlockPinTitle") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m5 }, children: polyglot.t("LockDevice.unlockPinMessage") }),
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("LockDevice.unlockPin") })
  ] });
};
const useActionPerformedToday = (devicePossession) => {
  var _a;
  const { data } = useApiClient(
    DeviceEndpoints.getActivityLogForDeviceByDomainAndActionName(
      devicePossession.deviceId,
      ((_a = devicePossession.device) == null ? void 0 : _a.inHouseMdm) ? "deviceActionInHouseMdm" : "deviceAction",
      "lockDevice"
    ),
    { suspense: false }
  );
  return data != null ? data : false;
};
const LockDeviceDrawerContent = (props) => {
  const { polyglot } = usePolyglot();
  const [confirmationText, setConfirmationText] = useState("");
  const [, setShowLockPinModal] = useState(false);
  const [showMessage] = useMessage();
  const hasActionToday = useActionPerformedToday(props.devicePossession);
  const [isQuestionFromOpened, setIsQuestionFromOpened] = useState(false);
  useEffect(() => {
    if (hasActionToday !== void 0) {
      setIsQuestionFromOpened(hasActionToday);
    }
  }, [hasActionToday]);
  const getLockMessage = () => {
    var _a;
    return !((_a = props.devicePossession.device) == null ? void 0 : _a.inHouseMdm) ? polyglot.t("LockDevice.messages.hexnode") : props.isMobileAppleDevice ? polyglot.t("LockDevice.messages.mobile") : polyglot.t("LockDevice.messages.macOS");
  };
  const handleLockDevice = () => __async(void 0, null, function* () {
    try {
      yield DeviceAPI.lockDevice(props.devicePossession.id, getLockMessage());
      showMessage(polyglot.t("LockDevice.messages.success"), "success");
      if (!props.isMobileAppleDevice) setShowLockPinModal(true);
    } catch (e) {
      showMessage(polyglot.t("LockDevice.messages.error"), "error");
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      QuestionsDrawer,
      {
        title: polyglot.t("LockDevice.action.executedTitle"),
        description: polyglot.t("LockDevice.action.exists"),
        isOpen: isQuestionFromOpened,
        setIsOpen: setIsQuestionFromOpened,
        loading: false,
        action: () => __async(void 0, null, function* () {
          setIsQuestionFromOpened(false);
        }),
        onClose: props.onClose,
        children: !props.isMobileAppleDevice && /* @__PURE__ */ jsx(UnlockPinDisplay, {})
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("LockDevice.title") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Red", children: getLockMessage() }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "confirm",
          placeholder: polyglot.t("LockDevice.confirmPlaceholder"),
          fullWidth: true,
          value: confirmationText,
          type: "text",
          onChange: (event) => setConfirmationText(event.target.value),
          clearText: () => setConfirmationText("")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: confirmationText !== polyglot.t("LockDevice.confirmText"),
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            yield handleLockDevice();
            props.onClose();
          }),
          children: polyglot.t("LockDevice.submitButton")
        }
      ) })
    ] })
  ] });
};
export const LockDeviceDrawer = ({
  isOpen,
  onClose,
  setIsOpen,
  devicePossession,
  isMobileAppleDevice
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
  LockDeviceDrawerContent,
  {
    isOpen,
    onClose,
    setIsOpen,
    devicePossession,
    isMobileAppleDevice
  }
) });
