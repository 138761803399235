"use strict";
export var DomainTypes = /* @__PURE__ */ ((DomainTypes2) => {
  DomainTypes2["Tasks"] = "tasks";
  DomainTypes2["Absence"] = "absence";
  DomainTypes2["Documents"] = "documents";
  DomainTypes2["Contracts"] = "contracts";
  DomainTypes2["Reviews"] = "reviews";
  DomainTypes2["Surveys"] = "surveys";
  DomainTypes2["Attendance"] = "attendance";
  DomainTypes2["Shifts"] = "shifts";
  DomainTypes2["Requests"] = "requests";
  DomainTypes2["CustomBenefitPayments"] = "customBenefitPayments";
  DomainTypes2["Expenses"] = "expenses";
  DomainTypes2["Invoices"] = "invoices";
  DomainTypes2["CustomBenefitRequests"] = "customBenefitRequests";
  DomainTypes2["Devices"] = "devices";
  return DomainTypes2;
})(DomainTypes || {});
export const DomainTypesArray = Object.values(DomainTypes);
export var WidgetTypes = /* @__PURE__ */ ((WidgetTypes2) => {
  WidgetTypes2["USER_PROFILE_SMALL"] = "USER_PROFILE_SMALL";
  WidgetTypes2["USER_TODOS_BIG"] = "USER_TODOS_BIG";
  WidgetTypes2["USER_TODOS_SMALL"] = "USER_TODOS_SMALL";
  WidgetTypes2["USER_CALENDAR_BIG"] = "USER_CALENDAR_BIG";
  WidgetTypes2["USER_CALENDAR_SMALL"] = "USER_CALENDAR_SMALL";
  WidgetTypes2["USER_ATTENDANCE_BIG"] = "USER_ATTENDANCE_BIG";
  WidgetTypes2["USER_SHIFT_SMALL"] = "USER_SHIFT_SMALL";
  WidgetTypes2["USER_TIME_PLANNER_SMALL"] = "USER_TIME_PLANNER_SMALL";
  WidgetTypes2["USER_TIME_PLANNER_BIG"] = "USER_TIME_PLANNER_BIG";
  return WidgetTypes2;
})(WidgetTypes || {});
export const AllWidgetConfiguration = [
  {
    type: "USER_PROFILE_SMALL" /* USER_PROFILE_SMALL */,
    size: "small",
    isDeletable: false,
    isStatic: true,
    isResizable: false,
    settings: null
  },
  {
    type: "USER_TODOS_BIG" /* USER_TODOS_BIG */,
    size: "big",
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null
  },
  {
    type: "USER_TODOS_SMALL" /* USER_TODOS_SMALL */,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: "small"
  },
  {
    type: "USER_CALENDAR_BIG" /* USER_CALENDAR_BIG */,
    size: "big",
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null
  },
  {
    type: "USER_CALENDAR_SMALL" /* USER_CALENDAR_SMALL */,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: "small"
  },
  {
    type: "USER_TIME_PLANNER_SMALL" /* USER_TIME_PLANNER_SMALL */,
    size: "small",
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null
  },
  {
    type: "USER_ATTENDANCE_BIG" /* USER_ATTENDANCE_BIG */,
    size: "big",
    isDeletable: false,
    isStatic: false,
    isResizable: false,
    settings: null
  },
  {
    type: "USER_TIME_PLANNER_BIG" /* USER_TIME_PLANNER_BIG */,
    size: "big",
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null
  }
];
export const AddWidgetConfigurationByType = [
  {
    type: "USER_TODOS_SMALL" /* USER_TODOS_SMALL */,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: "small"
  },
  {
    type: "USER_CALENDAR_SMALL" /* USER_CALENDAR_SMALL */,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: "small"
  },
  {
    type: "USER_TIME_PLANNER_SMALL" /* USER_TIME_PLANNER_SMALL */,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: "small"
  }
];
export const AddWidgetType = [
  "USER_TODOS_SMALL" /* USER_TODOS_SMALL */,
  "USER_CALENDAR_SMALL" /* USER_CALENDAR_SMALL */,
  "USER_TIME_PLANNER_SMALL" /* USER_TIME_PLANNER_SMALL */
];
export var UserNotificationType = /* @__PURE__ */ ((UserNotificationType2) => {
  UserNotificationType2["LastDay"] = "lastDay";
  UserNotificationType2["Birthday"] = "birthday";
  UserNotificationType2["NewJoiner"] = "newJoiner";
  UserNotificationType2["WorkAnniversary"] = "workAnniversary";
  return UserNotificationType2;
})(UserNotificationType || {});
export var KindTypes = /* @__PURE__ */ ((KindTypes2) => {
  KindTypes2["IncompleteTasks"] = "incomplete-tasks";
  KindTypes2["PendingRequest"] = "pending-request";
  KindTypes2["PendingPHCancellation"] = "pending-ph-cancellation";
  KindTypes2["PendingRequests"] = "pending-requests";
  KindTypes2["MissingDocuments"] = "missing-documents";
  KindTypes2["PendingContracts"] = "pending-contracts";
  KindTypes2["PendingReviews"] = "pending-reviews";
  KindTypes2["PendingSurveys"] = "pending-surveys";
  KindTypes2["UnfinishedAttendance"] = "unfinished-attendance";
  KindTypes2["SubmittedAttendance"] = "submitted-attendance";
  KindTypes2["SubmittedRequestForm"] = "submitted-request-form";
  KindTypes2["PendingBenefitPayment"] = "pending-benefit-payment";
  KindTypes2["ExpenseRequest"] = "expense-request";
  KindTypes2["BenefitRequest"] = "benefit-request";
  KindTypes2["InvoiceRequest"] = "invoice-request";
  KindTypes2["DeviceTransit"] = "device-transit";
  return KindTypes2;
})(KindTypes || {});
