"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentFormModal } from "@/v2/feature/document-new/components/new-document-form-modal.component";
import { NewDocumentPreview } from "@/v2/feature/document-new/components/new-document-preview.component";
import { NewDocumentUploadModal } from "@/v2/feature/document-new/components/new-document-upload-modal.component";
import { NewDocumentViewModal } from "@/v2/feature/document-new/components/new-document-view-modal.component";
import { useDocumentActions } from "@/v2/feature/document-new/hooks/use-document-actions.hook";
import { FormTypeEnum } from "@/v2/feature/document-new/interfaces/document-new.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
const ContractTemplateManager = React.lazy(
  () => import("@/v2/feature/templates/features/contract-template-manager/contract-template-manager.component").then(
    (module) => ({
      default: module.ContractTemplateManager
    })
  )
);
export const DocumentActionItem = ({
  selectedRow,
  refresh,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DocumentDrawerContent, { documentActionRow: selectedRow, refresh, afterClose });
};
const DocumentDrawerContent = ({
  documentActionRow,
  refresh,
  afterClose
}) => {
  var _a;
  const [state] = useContext(GlobalContext);
  const { polyglot } = usePolyglot();
  const [selectDocument, setSelectedDocument] = useState(void 0);
  const [showMessage] = useMessage();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [missingFields, setMissingFields] = useState();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [error, setError] = useState(null);
  const [docData, setDocData] = useState(null);
  const fileURLRef = useRef(null);
  const getDocumentById = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoadingData(true);
      const document = yield DocumentNewAPI.getDocumensById(Number(documentActionRow.id));
      setSelectedDocument(document);
      setIsViewOpen(true);
    } catch (error2) {
      showMessage(
        polyglot.t("DocumentActionItem.errorMessages.fetch", { errorMessage: nestErrorMessage(error2) }),
        "error"
      );
      setSelectedDocument(void 0);
    } finally {
      setLoadingData(false);
    }
  }), [polyglot, documentActionRow.id, showMessage]);
  useEffect(() => {
    getDocumentById();
  }, [getDocumentById]);
  const actions = useDocumentActions({
    setLoadingData,
    setIsEditOpen,
    setIsViewOpen,
    setIsUploadOpen,
    setDocumentsRecordsFromServer: refresh,
    fileURLRef,
    setDocData,
    setIsPreviewOpen,
    setError,
    setMissingFields,
    setSelectedDocument,
    setOpenMissingFieldContractModal,
    loggedInUserId: state.user.userId
  });
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current);
      fileURLRef.current = null;
    }
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      NewDocumentViewModal,
      {
        isOpen: isViewOpen,
        setIsOpen: setIsViewOpen,
        onClose: () => {
          setIsViewOpen(false);
        },
        afterClose: () => {
          if (afterClose) afterClose();
          setSelectedDocument(void 0);
        },
        actions,
        document: selectDocument,
        loadingData
      }
    ),
    /* @__PURE__ */ jsx(
      NewDocumentFormModal,
      {
        isOpen: isEditOpen,
        setIsOpen: setIsEditOpen,
        onClose: () => {
          setIsEditOpen(false);
        },
        afterClose: () => {
          if (afterClose) afterClose();
          setSelectedDocument(void 0);
        },
        refresh,
        document: selectDocument,
        formType: FormTypeEnum.upload,
        reach: ReachType.Me
      }
    ),
    /* @__PURE__ */ jsx(
      NewDocumentUploadModal,
      {
        isOpen: isUploadOpen,
        setIsOpen: setIsUploadOpen,
        onClose: () => {
          setIsUploadOpen(false);
        },
        afterClose: () => {
          if (afterClose) afterClose();
          setSelectedDocument(void 0);
        },
        selectedDocument: selectDocument,
        refresh
      }
    ),
    /* @__PURE__ */ jsx(
      NewDocumentPreview,
      {
        docData,
        onClose: closePreview,
        isOpen: isPreviewOpen,
        error,
        title: (_a = docData == null ? void 0 : docData.fileName) != null ? _a : "Document"
      }
    ),
    /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
      ContractTemplateManager,
      {
        openTemplateModal,
        setOpenTemplateModal,
        openMissingFieldContractModal,
        setOpenMissingFieldContractModal,
        missingFields,
        setMissingFields
      }
    ) })
  ] });
};
