"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { identity } from "fp-ts/lib/function";
import { CompensationAPI } from "@/api-client/compensation.api";
import { getUserCompensationSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { payScheduleUnit } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { useProfileFormUpdate } from "@/v2/feature/user/context/user-profile.context";
import {
  PayScheduleEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import {
  CardStructure,
  FieldStructure
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { CompensationForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component";
import { buildColumnsForCustomFields } from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import { translatePaySchedule } from "@/v2/infrastructure/i18n/translate.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { round2Digits } from "@/v2/util/number.util";
const SCOPE = "user.compensation:all";
const skeletonHeight = (polyglot) => calculateSkeletonHeight(getUserCompensationSchema(polyglot));
const iconSize = { width: 14, height: 14 };
const numberFormatter = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
export const CompensationCard = ({
  userId,
  handleSubmit = () => {
  },
  missingFields
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [data, setData] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowModalMode, setRowModalMode] = useState("add");
  const [paySchedulesInRecords, setPaySchedulesInRecords] = useState();
  const setFormCurrentlyEditing = useProfileFormUpdate();
  const [modalInitialValues, setModalInitialValues] = useState(null);
  const getUserCompensation = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    if (!userId) return;
    setLoading(true);
    try {
      const data2 = yield CompensationAPI.findByUserId(userId);
      if (((_a2 = data2 == null ? void 0 : data2.entries) == null ? void 0 : _a2.length) < 1) setShowDetails(false);
      setData(data2);
      setPaySchedulesInRecords(new Set(data2.entries.map((e) => e.paySchedule)));
    } catch (error) {
      showMessage(`${polyglot.t("CompensationCard.errorMessages.load")}: ${error}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, userId, polyglot]);
  useEffect(() => {
    getUserCompensation();
  }, [getUserCompensation]);
  const editCompensationRow = useCallback((rowData) => {
    var _a2, _b2;
    setModalInitialValues(__spreadProps(__spreadValues({}, rowData), {
      salaryPerPaySchedule: (_a2 = rowData == null ? void 0 : rowData.compensationBreakdown) == null ? void 0 : _a2.payScheduleRate,
      annualSalary: (_b2 = rowData == null ? void 0 : rowData.compensationBreakdown) == null ? void 0 : _b2.fixedRates.annual
    }));
    setIsModalOpen(true);
  }, []);
  const addCompensationRow = () => {
    setRowModalMode("add");
    setModalInitialValues(null);
    setIsModalOpen(true);
  };
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("CompensationCard.payType"),
        accessorFn: identity,
        id: "payType",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.payType ? original.payType : "" })
      },
      {
        header: () => polyglot.t("CompensationCard.paySchedule"),
        accessorFn: identity,
        id: "paySchedule",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.paySchedule ? original.paySchedule : "" })
      },
      {
        header: () => polyglot.t("CompensationCard.salaryBasis"),
        accessorFn: identity,
        id: "salaryBasis",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.salaryBasis ? original.salaryBasis : "" })
      },
      {
        header: () => "Rate",
        accessorFn: identity,
        id: "rate",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2, _b2, _c2, _d2;
          return /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.prorateSalaryByFte) && original.nonProratedRate ? numberFormatter.format((_b2 = round2Digits((_a2 = original.nonProratedRate) != null ? _a2 : 0)) != null ? _b2 : 0) : numberFormatter.format((_d2 = round2Digits((_c2 = original.rate) != null ? _c2 : 0)) != null ? _d2 : 0) });
        }
      },
      {
        header: () => polyglot.t("CompensationCard.proRatedRate"),
        accessorFn: identity,
        id: "proRatedRate",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { children: original.rate && Number(original == null ? void 0 : original.rate) > 0 && (original == null ? void 0 : original.prorateSalaryByFte) ? numberFormatter.format((_a2 = round2Digits(original.rate)) != null ? _a2 : 0) : "" });
        }
      },
      {
        header: () => polyglot.t("CompensationCard.units"),
        accessorFn: identity,
        id: "units",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.units ? original.units : "" })
      },
      {
        header: () => polyglot.t("CompensationCard.annualSalaryGross"),
        accessorFn: identity,
        id: "annualSalary",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2, _b2, _c2, _d2;
          return /* @__PURE__ */ jsx("div", { children: (_d2 = numberFormatter.format((_c2 = round2Digits((_b2 = (_a2 = original.compensationBreakdown) == null ? void 0 : _a2.fixedRates.annual) != null ? _b2 : 0)) != null ? _c2 : 0)) != null ? _d2 : "" });
        }
      },
      ...(paySchedulesInRecords == null ? void 0 : paySchedulesInRecords.has(PayScheduleEnum.Weekly)) ? [
        {
          header: () => polyglot.t("CompensationCard.salaryPerWeek"),
          accessorFn: identity,
          id: "salaryPerWeek",
          enableSorting: false,
          cell: ({ row: { original } }) => {
            var _a2;
            if (!original.paySchedule || original.paySchedule !== PayScheduleEnum.Weekly || !original.compensationBreakdown)
              return /* @__PURE__ */ jsx(EmptyCell, {});
            return /* @__PURE__ */ jsx("div", { children: numberFormatter.format((_a2 = round2Digits(original.compensationBreakdown.payScheduleRate)) != null ? _a2 : 0) });
          }
        }
      ] : [],
      ...(paySchedulesInRecords == null ? void 0 : paySchedulesInRecords.has(PayScheduleEnum.Monthly)) ? [
        {
          header: () => polyglot.t("CompensationCard.salaryPerMonth"),
          accessorFn: identity,
          id: "salaryPerMonth",
          enableSorting: false,
          cell: ({ row: { original } }) => {
            var _a2;
            if (!original.paySchedule || original.paySchedule !== PayScheduleEnum.Monthly || !original.compensationBreakdown)
              return /* @__PURE__ */ jsx(EmptyCell, {});
            return /* @__PURE__ */ jsx("div", { children: numberFormatter.format((_a2 = round2Digits(original.compensationBreakdown.payScheduleRate)) != null ? _a2 : 0) });
          }
        }
      ] : [],
      {
        header: () => polyglot.t("CompensationCard.dailyRate"),
        accessorFn: identity,
        id: "fixedRates.daily",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2, _b2, _c2;
          return /* @__PURE__ */ jsx("div", { children: numberFormatter.format((_c2 = round2Digits((_b2 = (_a2 = original == null ? void 0 : original.compensationBreakdown) == null ? void 0 : _a2.fixedRates.daily) != null ? _b2 : 0)) != null ? _c2 : 0) });
        }
      },
      {
        header: () => polyglot.t("CompensationCard.hourlyRate"),
        accessorFn: identity,
        id: "fixedRates.hourly",
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => {
          var _a2, _b2, _c2;
          return /* @__PURE__ */ jsx("div", { children: numberFormatter.format((_c2 = round2Digits((_b2 = (_a2 = original == null ? void 0 : original.compensationBreakdown) == null ? void 0 : _a2.fixedRates.hourly) != null ? _b2 : 0)) != null ? _c2 : 0) });
        }
      },
      ...buildColumnsForCustomFields(data == null ? void 0 : data.entries, { size: 80 }),
      {
        header: () => polyglot.t("CompensationCard.effectiveDate"),
        accessorFn: identity,
        id: "effectiveDate",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        header: () => "",
        accessorFn: identity,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: polyglot.t("CompensationCard.edit"),
            onClick: () => {
              editCompensationRow(original);
              setRowModalMode("edit");
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [paySchedulesInRecords, data == null ? void 0 : data.entries, polyglot, scopesContext, editCompensationRow]
  );
  const effectiveRecord = useMemo(() => data == null ? void 0 : data.effectiveRecord, [data]);
  return !data ? /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      height: skeletonHeight(polyglot),
      sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
    }
  ) : /* @__PURE__ */ jsx(
    CardStructure,
    {
      cardTitle: polyglot.t("CompensationCard.salary"),
      cardScope: [SCOPE],
      showDetails,
      setShowDetails,
      userId,
      addAction: addCompensationRow,
      showMissingInfo: !effectiveRecord,
      cardFieldCustomUpdates: effectiveRecord == null ? void 0 : effectiveRecord.customUpdates,
      cardFieldStubs: [
        "compensation.paySchedule",
        "compensation.salaryBasis",
        "compensation.rate",
        "compensation.currency"
      ],
      cardFieldDetails: effectiveRecord && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.payType"),
            fieldValue: effectiveRecord.payType,
            fieldStub: "compensation.payType"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.paySchedule"),
            fieldValue: translatePaySchedule((_a = effectiveRecord.paySchedule) != null ? _a : "", polyglot),
            fieldStub: "compensation.paySchedule"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.salaryBasis"),
            fieldValue: translatePaySchedule((_b = effectiveRecord.salaryBasis) != null ? _b : "", polyglot),
            fieldStub: "compensation.salaryBasis"
          }
        ),
        Boolean(effectiveRecord && effectiveRecord.nonProratedRate) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.rate"),
            fieldValue: effectiveRecord.prorateSalaryByFte ? numberFormatter.format((_d = round2Digits((_c = effectiveRecord.nonProratedRate) != null ? _c : 0)) != null ? _d : 0) : numberFormatter.format((_f = round2Digits((_e = effectiveRecord.rate) != null ? _e : 0)) != null ? _f : 0),
            fieldStub: "compensation.rate"
          }
        ),
        Boolean(!!(effectiveRecord == null ? void 0 : effectiveRecord.rate) && effectiveRecord.prorateSalaryByFte) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.proRatedRate"),
            fieldValue: numberFormatter.format((_g = round2Digits(effectiveRecord.rate)) != null ? _g : 0),
            fieldStub: "compensation.rate"
          }
        ),
        Boolean(effectiveRecord.paySchedule) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: `${polyglot.t("CompensationCard.salaryPer")} ${payScheduleUnit(
              effectiveRecord.paySchedule
            )} (${polyglot.t("CompensationCard.gross")})`,
            fieldValue: (() => {
              var _a2, _b2;
              if (!((_a2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _a2.payScheduleRate)) return "";
              return numberFormatter.format(
                (_b2 = round2Digits(effectiveRecord.compensationBreakdown.payScheduleRate)) != null ? _b2 : 0
              );
            })(),
            fieldStub: "compensation.rate"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.salaryYearGross"),
            fieldValue: (() => {
              var _a2, _b2, _c2, _d2;
              if (!((_b2 = (_a2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _a2.fixedRates) == null ? void 0 : _b2.annual)) return "";
              return numberFormatter.format(
                (_d2 = round2Digits((_c2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _c2.fixedRates.annual)) != null ? _d2 : 0
              );
            })(),
            fieldStub: "compensation.rate"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.dailyRate"),
            fieldValue: (() => {
              var _a2, _b2, _c2, _d2;
              if (!((_a2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _a2.fixedRates.daily)) return "";
              return numberFormatter.format(
                (_d2 = round2Digits((_c2 = (_b2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _b2.fixedRates.daily) != null ? _c2 : 0)) != null ? _d2 : 0
              );
            })(),
            fieldStub: "compensation.rate"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.hourlyRate"),
            fieldValue: (() => {
              var _a2, _b2, _c2, _d2;
              if (!((_a2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _a2.fixedRates.hourly)) return "";
              return numberFormatter.format(
                (_d2 = round2Digits((_c2 = (_b2 = effectiveRecord == null ? void 0 : effectiveRecord.compensationBreakdown) == null ? void 0 : _b2.fixedRates.hourly) != null ? _c2 : 0)) != null ? _d2 : 0
              );
            })(),
            fieldStub: "compensation.rate"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("CompensationCard.currency"),
            fieldValue: effectiveRecord.currency,
            fieldStub: "compensation.currency"
          }
        ),
        sortCustomFields(effectiveRecord.customUpdates).map(
          (f) => !f.field.isHidden && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: f.field.fieldName, fieldValue: f.value }, f.field.fieldId)
        )
      ] }),
      drawerDetails: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, children: /* @__PURE__ */ jsx(
        CompensationForm,
        {
          initialValues: modalInitialValues,
          userId,
          getUserCompensation,
          rowModalMode,
          handleSubmit,
          setFormCurrentlyEditing,
          setIsModalOpen,
          setLoading,
          loading,
          onClose: () => setIsModalOpen(false)
        }
      ) }) }),
      addButtonTitle: polyglot.t("CompensationCard.new"),
      editAction: effectiveRecord && (() => {
        editCompensationRow(effectiveRecord);
        setRowModalMode("edit");
      }),
      tableRowData: [...data.entries],
      tableColumn: columnData,
      missingInfoIcon: /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user:all"], context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === CustomProfileFormType.Salary)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) })
    }
  );
};
