"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
export const InfiniteScrollTrigger = ({ onReachedEnd }) => {
  const targetRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) return;
      onReachedEnd();
    });
    const targetElem = targetRef.current;
    if (targetElem) observer.observe(targetElem);
    return () => {
      if (targetElem) observer.unobserve(targetElem);
    };
  }, [targetRef.current, onReachedEnd]);
  return /* @__PURE__ */ jsx("div", { ref: targetRef, style: { height: "1px", width: "0px" } });
};
