"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PayrollLocalEndpoints {
  static getUserPayrollMembershipList() {
    return {
      url: "/apiv2/users/payroll/list"
    };
  }
  static getUKPayrunAttendanceEntriesWithoutPaycode(payrunId) {
    return { url: `/apiv2/payroll/payruns/${payrunId}/attendance-entries-without-paycode` };
  }
  static getPayrunPaymentToPayrollIdMapping() {
    return {
      url: "/apiv2/payroll/payruns/payrun-payments-to-payroll-mapping"
    };
  }
  static getUKPayrunPayCodes(payrunId) {
    return { url: `/apiv2/payroll/payruns/${payrunId}/paycodes-uk` };
  }
  static getPayrunDetails(payrollId, taxYear, payPeriod, periodNumber) {
    return {
      url: `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/local/detail?payrollId=${payrollId}`
    };
  }
  static getPayslipsForUser(userId) {
    return { url: `/apiv2/users/${userId}/payslips` };
  }
}
export class PayrollLocalApi {
  static getLocalPayRun(payrollId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/local?payrollId=${payrollId}`)).data;
    });
  }
  static getPrePayrunProratedSalaryDetail(payrollId, taxYear, payPeriod, periodNumber, entryId, userId) {
    return __async(this, null, function* () {
      return (yield axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/${payrollId}/${entryId}/${userId}/pre-payrun/prorated-salary-detail`
      )).data;
    });
  }
  static getInPayrunProratedSalaryDetail(entryId, userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${entryId}/${userId}/in-payrun/prorated-salary-detail`)).data;
    });
  }
  static findLocalPayRunEntries(payrollId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/local/entries?payrollId=${payrollId}`
      )).data;
    });
  }
  /**
   * Fetch the list of saved payrun entries for a user
   */
  static getAllLocalPayRunEntriesForUser(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${userId}/entries/local`)).data;
    });
  }
  static getPayrunPayslipsDetails(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/payslips`)).data;
    });
  }
  static getPayrunAccountingRecord(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/accounting`)).data;
    });
  }
  static getPayrunExternalAccountingData(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/accounting/external-journal-body`)).data;
    });
  }
  static getPayrunExternalAccountingDataByPeriod(taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/accounting/external-journal-body`)).data;
    });
  }
  static getPayrunPaymentsRecord(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/payments`)).data;
    });
  }
  static createPayrunBankPaymentsForPayrun(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payrunId}/payments`)).data;
    });
  }
  static markPayrunPaymentsAsComplete(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payrunId}/payments/mark-as-complete`)).data;
    });
  }
  static submitAccountingJournal(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payrunId}/accounting`)).data;
    });
  }
  static markAccountingJournalAsSent(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payrunId}/accounting/mark-as-sent`)).data;
    });
  }
  static getPensionContributionStatusForPayrun(payrunId, pensionSchemeId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/pension/${pensionSchemeId}/contribution-status`)).data;
    });
  }
  static markPensionContributionAsSent(payrunId, pensionSchemeId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payrunId}/pension/${pensionSchemeId}/mark-as-sent`)).data;
    });
  }
  static createPayslips(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payrunId}/payslips`)).data;
    });
  }
  static reopenPayRun(payrollId, taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/reopen?payrollId=${payrollId}`);
    });
  }
  static deletePayRun(payrollId, taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}?payrollId=${payrollId}`);
    });
  }
  static getCompanyPayRuns() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns`)).data;
    });
  }
  static completePayrun(payrunId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payroll/payruns/${payrunId}/complete`);
    });
  }
  static getRefreshedPayRun(payrunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/refresh`)).data;
    });
  }
  static fetchFPS(payRunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payRunId}/fps-submission`)).data;
    });
  }
  static deletePayroll(payrollId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/payroll?payrollId=${payrollId}`);
    });
  }
  static getPayRunsAsSuperAdmin(companyId, payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/superadmin/${companyId}?payrollId=${payrollId}`)).data;
    });
  }
  static getLocalPayRunByIdAsSuperAdmin(companyId, payrunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/superadmin/${companyId}/${payrunId}`)).data;
    });
  }
}
