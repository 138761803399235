"use strict";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
export const UserContractKinds = (polyglot) => {
  return [
    { value: "Full-time", label: polyglot.t("UserContractKinds.fullTime") },
    { value: "Part-time", label: polyglot.t("UserContractKinds.partTime") },
    { value: "Shifts", label: polyglot.t("UserContractKinds.shifts") }
  ];
};
export const UserContractTypes = (polyglot) => {
  return [
    {
      value: UserContractTypeEnum.Payrolled,
      label: polyglot.t("UserContractTypes.payrolled")
    },
    {
      value: UserContractTypeEnum.NonPayrolled,
      label: polyglot.t("UserContractTypes.nonpayrolled")
    }
  ];
};
export const TimeUnitTypes = (polyglot) => {
  return [
    {
      value: "Days",
      label: "Days"
    },
    {
      value: "Weeks",
      label: "Weeks"
    },
    {
      value: "Months",
      label: "Months"
    }
  ];
};
