"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ReactComponent as CalendarIcon } from "@/images/icons/calendar.svg";
import { ReactComponent as MessageIcon } from "@/images/message.svg";
import { GrowthFactorsGroup } from "@/v2/feature/growth/goals/components/growth-factors-group.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { OwnerChip } from "@/v2/feature/growth/goals/components/owner-chip.component";
import { StatusChip } from "@/v2/feature/growth/goals/components/status-chip.component";
import { KeyResultProgressType } from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { GrowthChip } from "@/v2/feature/growth/shared/components/growth-chip.component";
import { LocalDate } from "@/v2/util/local-date";
export const KeyResultListItem = ({ keyResult, hoverState, onClick, sx }) => {
  const isPercentageBased = keyResult.progressType === KeyResultProgressType.Percentage;
  return /* @__PURE__ */ jsx(
    "div",
    {
      onClick: () => {
        if (onClick) {
          onClick(keyResult);
        }
      },
      children: /* @__PURE__ */ jsx(
        ListItem,
        {
          hoverState,
          progressBar: {
            progress: keyResult.lastProgress,
            progressType: isPercentageBased ? "percentage" : "number",
            progressTarget: isPercentageBased ? 100 : keyResult.progressTarget
          },
          details: {
            title: keyResult.name,
            description: keyResult.description ? keyResult.description : /* @__PURE__ */ jsx("br", {}),
            chips: /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(
                OwnerChip,
                {
                  ownerType: keyResult.ownerType,
                  ownerIds: keyResult.ownerIds,
                  departmentId: keyResult.departmentId
                }
              ),
              keyResult.dueDate ? /* @__PURE__ */ jsx(
                GrowthChip,
                {
                  options: {
                    text: new LocalDate(keyResult.dueDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }),
                    textColor: "black",
                    icon: /* @__PURE__ */ jsx(CalendarIcon, { width: "12px" }),
                    border: "middleGrey2",
                    backgroundColor: "white"
                  }
                }
              ) : null,
              keyResult.progressItemsCount > 0 ? /* @__PURE__ */ jsx(
                GrowthChip,
                {
                  options: {
                    text: keyResult.progressItemsCount,
                    textColor: "black",
                    icon: /* @__PURE__ */ jsx(MessageIcon, { width: "15px" }),
                    border: "middleGrey2",
                    backgroundColor: "white",
                    iconSx: { display: "flex" }
                  }
                }
              ) : null,
              /* @__PURE__ */ jsx(GrowthFactorsGroup, { factorIds: keyResult.factorIds })
            ] })
          },
          endSection: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(StatusChip, { status: keyResult.status }) }),
          sx
        }
      )
    }
  );
};
