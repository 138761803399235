"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@v2/components/typography/typography.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { UserCell } from "../table/user-cell.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const MultiUserAvatar = ({
  userIds,
  showLimit = 4,
  textVariant = "caption",
  textColor = "DarkGrey",
  avatarSize = "xxsmall",
  avatarNameVariant = "caption"
}) => {
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const avatars = useMemo(() => {
    const avatars2 = [];
    const limit = Math.min(showLimit, userIds.length);
    if (userIds.length === 1) {
      return /* @__PURE__ */ jsx(UserCell, { userId: userIds[0], avatarSize, nameVariant: avatarNameVariant }, userIds[0]);
    }
    for (let index = 0; index < limit; index += 1) {
      const userId = userIds[index];
      avatars2.push(/* @__PURE__ */ jsx(UserAvatar, { userId, size: avatarSize, showName: false }, userId));
    }
    return avatars2;
  }, [avatarSize, showLimit, userIds, avatarNameVariant]);
  const remainingUsers = useMemo(() => {
    var _a, _b, _c, _d;
    const names = [];
    for (let index = showLimit; index < userIds.length; index += 1) {
      const user = getCachedUserById(userIds[index]);
      if (user) names.push(polyglot.t((_d = (_c = user.displayName) != null ? _c : `${(_a = user.firstName) != null ? _a : ""} ${(_b = user.lastName) != null ? _b : ""}`) != null ? _d : ""));
    }
    return names;
  }, [polyglot, getCachedUserById, userIds, showLimit]);
  return /* @__PURE__ */ jsxs("div", { style: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
    avatars,
    remainingUsers.length > 0 && /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        title: /* @__PURE__ */ jsx(Stack, { gap: "4px", children: remainingUsers.map((userName) => /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "GreyLight", children: userName }, userName)) }),
        placement: "top",
        children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: ` +${remainingUsers.length}` }) })
      }
    )
  ] });
};
