"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  getGrowthConfig,
  getMoneyConfig,
  getPeopleConfig,
  getReportsConfig,
  getTechConfig,
  getTimeConfig
} from "@/v2/feature/app-layout/features/v2/sidebar-config.util";
export function menuReducer(state, action) {
  switch (action.type) {
    case "SET_SECOND_MENU_VISIBILITY":
      return __spreadProps(__spreadValues({}, state), { showSecondMenu: action.payload });
    case "SET_ACTIVE_PARENT":
      return __spreadProps(__spreadValues({}, state), { activeParent: action.payload });
    case "SET_ACTIVE_SUBMENU_PARENT":
      return __spreadProps(__spreadValues({}, state), { activeSubMenuParent: action.payload });
    case "SET_ACTIVE_ROLE":
      return __spreadProps(__spreadValues({}, state), { activeRole: action.payload });
    default:
      return state;
  }
}
export const getSecondMenuItems = (activeRole, activeParent, user, polyglot, showHiddenFeatures = false) => {
  if (!activeRole || !user) return void 0;
  return activeParent === "people" ? getPeopleConfig(user, activeRole, polyglot) : activeParent === "time" ? getTimeConfig(user, activeRole, polyglot) : activeParent === "money" ? getMoneyConfig(user, activeRole, polyglot) : activeParent === "report" ? getReportsConfig(user, activeRole, polyglot, showHiddenFeatures) : activeParent === "growth" ? getGrowthConfig(user, activeRole, polyglot, showHiddenFeatures) : activeParent === "tech" ? getTechConfig(user, activeRole, polyglot) : void 0;
};
export function findSubItemByStub(subItems, stub) {
  return subItems && subItems.find((a) => a.stub === stub);
}
export function findChildByPath(subMenu) {
  return subMenu && subMenu.find((a) => a.path && !a.isHidden);
}
export function findSubMenuByPath(subMenu) {
  const firstSubMenu = subMenu && subMenu.find((a) => !a.isHidden && a.hasChildren);
  return firstSubMenu && firstSubMenu.subMenu && firstSubMenu.subMenu.find((a) => a.path && !a.isHidden);
}
export function decomposePath(path) {
  const parts = path.split("/");
  return {
    domain: parts[1] || "",
    role: parts[2] ? parts[2] : "",
    subDomain: parts[3] || "",
    subMenu: parts[4] || ""
  };
}
export const navigateToFirstChildOrParent = (parent, subMenu, routedSecondMenuItems, routerHistory) => {
  if (parent && parent.hasChildren && parent.subMenu && !parent.isHidden) {
    const child = findSubItemByStub(parent.subMenu, subMenu) || findChildByPath(parent.subMenu);
    if (child && child.path && !child.isHidden) {
      routerHistory.push(child.path);
    } else {
      const firstChild = parent.subMenu.find((a) => a.path && !a.isHidden);
      if (firstChild && firstChild.path) {
        routerHistory.push(firstChild.path);
      }
    }
  } else if (parent && parent.path && !parent.isHidden) {
    routerHistory.push(parent.path);
  } else {
    const firstParent = findChildByPath(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems);
    if (firstParent && firstParent.path) {
      routerHistory.push(firstParent.path);
    } else {
      const firstSubMenu = findSubMenuByPath(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems);
      if (firstSubMenu && firstSubMenu.path) routerHistory.push(firstSubMenu.path);
    }
  }
};
