"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { SurveyQuestionsBiggestImpact } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-insights/survey-questions-biggest-impact.component";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Status } from "@/images/reviews/Status.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ChartDisplay } from "@/v2/components/charts/components/chart-display.component";
import { ChartPolarAreaChartjs } from "@/v2/components/charts/components/chart-polar-area-chartjs.component";
import { ChartWrapper } from "@/v2/components/charts/components/chart-wrapper.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { impactExportReport } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail.util";
import { TitleStatusComponent } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { PRIMARY_CHART_COLOR } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { captureExcel, capturePdf } from "@/v2/util/export-reports.util";
export const SurveyCycleDetailInsightsPage = ({
  growthFactors,
  surveyCycle,
  cycleLoading,
  surveyResult,
  reach,
  filtersAndTypesOptions,
  setFilterString,
  filterString
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : polyglot.t("SurveyCycleDetailInsights.company");
  const { data: surveyInsights, isLoading: loadingInsights } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleInsights(surveyCycle.id, reach),
    {
      suspense: false
    }
  );
  const reportName = useMemo(() => `${surveyCycle.internalName}-insights`, [surveyCycle.internalName]);
  if (!surveyCycle || !surveyInsights) return /* @__PURE__ */ jsx(Fragment, {});
  const generateExcel = () => {
    const { participation, surveyScore, factorScore } = surveyInsights;
    const data = [];
    const addBoldHeader = (text) => {
      data.push([{ v: text, s: { font: { bold: true } } }]);
    };
    addBoldHeader(polyglot.t("SurveyCycleDetailInsights.participationRateTitle"));
    data.push([polyglot.t("SurveyCycleDetailInsights.participationRateSubtitle")]);
    data.push([
      polyglot.t("SurveyCycleDetailInsights.participationLabel"),
      participation.average.toFixed(2),
      polyglot.t("SurveyCycleDetailInsights.participationValueFormat", {
        totalParticipants: participation.totalParticipants,
        totalEntries: participation.totalEntries
      })
    ]);
    data.push([]);
    addBoldHeader(polyglot.t("SurveyCycleDetailInsights.surveyScoreTitle"));
    data.push([polyglot.t("SurveyCycleDetailInsights.surveyScoreSubtitle")]);
    data.push([polyglot.t("SurveyCycleDetailInsights.surveyScoreTitle"), (surveyScore.score / 100).toFixed(2)]);
    data.push([]);
    addBoldHeader(polyglot.t("SurveyCycleDetailInsights.factorScoreTitle"));
    data.push([polyglot.t("SurveyCycleDetailInsights.factorScoreSubtitle")]);
    data.push(
      ...factorScore.categories.map((category, index) => [category, (factorScore.series[index] / 100).toFixed(2)])
    );
    data.push([]);
    const impactData = impactExportReport(surveyResult, company_name);
    data.push(...impactData);
    captureExcel(data, reportName);
  };
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { surveyCycle }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: Boolean(loadingInsights) || cycleLoading,
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                handler: generateExcel,
                label: polyglot.t("SurveyCycleDetailInsights.excelReportLabel")
              },
              {
                handler: () => capturePdf(reportName),
                label: polyglot.t("SurveyCycleDetailInsights.pdfReportLabel")
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: polyglot.t("SurveyCycleDetailInsights.exportButton"),
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ],
      children: /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          id: reportName,
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: /* @__PURE__ */ jsx(
                SubsectionTitle,
                {
                  title: polyglot.t("SurveyCycleDetailInsights.insightsTitle"),
                  subtitle: polyglot.t("SurveyCycleDetailInsights.insightsSubtitle", {
                    surveyCycleDisplayName: surveyCycle.displayName
                  })
                }
              ),
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(CycleInsights, { surveyInsights })
                }
              ]
            },
            {
              contentWidth: "100%",
              headerWidth: "100%",
              hidden: !surveyResult,
              title: /* @__PURE__ */ jsx(
                SubsectionTitle,
                {
                  title: polyglot.t("SurveyCycleDetailInsights.biggestImpactTitle"),
                  subtitle: polyglot.t("SurveyCycleDetailInsights.biggestImpactSubtitle"),
                  tooltipText: polyglot.t("SurveyCycleDetailInsights.biggestImpactTooltip")
                }
              ),
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    SurveyQuestionsBiggestImpact,
                    {
                      surveyResult,
                      growthFactors,
                      filtersAndTypesOptions: filtersAndTypesOptions || [],
                      setFilterString,
                      filterString,
                      surveyCycle
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    }
  );
};
const CycleInsights = ({ surveyInsights }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 2, paddingY: 2, children: [
    /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 3, children: /* @__PURE__ */ jsx(
      ChartDisplay,
      {
        title: polyglot.t("SurveyCycleDetailInsights.participationRateTitle"),
        subtitle: polyglot.t("SurveyCycleDetailInsights.participationRateSubtitle"),
        chart: /* @__PURE__ */ jsx(
          ChartWrapper,
          {
            type: "radial",
            chartData: {
              type: "radial",
              series: [surveyInsights.participation.average],
              categories: [polyglot.t("SurveyCycleDetailInsights.participationRateTitle")]
            },
            options: {
              colors: [PRIMARY_CHART_COLOR[2]]
            }
          }
        ),
        hasDownload: true,
        caption: polyglot.t("SurveyCycleDetailInsights.respondValueFormat", {
          totalParticipants: surveyInsights.participation.totalParticipants,
          totalEntries: surveyInsights.participation.totalEntries
        })
      }
    ) }),
    /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 3, children: /* @__PURE__ */ jsx(
      ChartDisplay,
      {
        title: polyglot.t("SurveyCycleDetailInsights.surveyScoreTitle"),
        subtitle: polyglot.t("SurveyCycleDetailInsights.surveyScoreSubtitleShort"),
        hasTooltip: true,
        tooltipText: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: polyglot.t("SurveyCycleDetailInsights.surveyScoreTooltip") }),
        chart: /* @__PURE__ */ jsx(
          ChartWrapper,
          {
            type: "radial",
            chartData: {
              type: "radial",
              series: surveyInsights.surveyScore.answersLowerThanThreshold ? [] : [surveyInsights.surveyScore.score],
              categories: [polyglot.t("SurveyCycleDetailInsights.surveyScoreTitle")]
            },
            options: {
              colors: [PRIMARY_CHART_COLOR[2]],
              noDataTitle: polyglot.t("SurveyCycleDetailInsights.notSufficientData")
            }
          }
        ),
        hasDownload: true
      }
    ) }),
    /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 12, lg: 6, children: /* @__PURE__ */ jsx(
      ChartDisplay,
      {
        title: polyglot.t("SurveyCycleDetailInsights.factorScoreTitle"),
        subtitle: polyglot.t("SurveyCycleDetailInsights.factorScoreSubtitle"),
        wideChart: true,
        chart: /* @__PURE__ */ jsx(
          ChartPolarAreaChartjs,
          {
            series: surveyInsights.factorScore.series,
            categories: surveyInsights.factorScore.categories,
            noData: surveyInsights.surveyScore.answersLowerThanThreshold,
            noDataTitle: polyglot.t("SurveyCycleDetailInsights.notSufficientData")
          }
        ),
        hasDownload: true
      }
    ) })
  ] });
};
const SubsectionTitle = ({
  title,
  tooltipText,
  subtitle
}) => /* @__PURE__ */ jsxs(Box, { children: [
  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: title }),
    tooltipText && /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: tooltipText }),
        placement: "right",
        children: /* @__PURE__ */ jsx(Status, __spreadValues({}, iconSize))
      }
    )
  ] }),
  /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: subtitle })
] });
