"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { KeyResultStatus } from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { GoalStatus } from "@/v2/feature/growth/goals/goal.interface";
export const StatusChip = ({ status }) => {
  const styling = useMemo(() => {
    if ([GoalStatus.Active, KeyResultStatus.Active].includes(status)) {
      return { bgColor: "LightGreen", textColor: "DarkGreen", borderColor: "darkGreen" };
    }
    if ([GoalStatus.Achieved, KeyResultStatus.Achieved].includes(status)) {
      return { bgColor: "LightViolet2", textColor: "Violet2", borderColor: "violet2" };
    }
    return { bgColor: "white", textColor: "black2", borderColor: "black2" };
  }, [status]);
  return /* @__PURE__ */ jsx(
    ChipComponent,
    {
      name: status,
      textVariant: "caption2",
      backgroundColor: styling.bgColor,
      textColor: styling.textColor,
      border: styling.borderColor
    }
  );
};
