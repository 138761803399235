"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getReviewerTypesChips } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ResultsOverviewReviewScoreTable = ({
  results,
  resultsLoading,
  reviewScore
}) => {
  const [showAll, setShowAll] = useState(false);
  const rowData = useMemo(() => {
    const initialData = results ? [{ reviewerId: results.revieweeId, average: results.reviewAvgScore, type: null, entryState: null }] : [];
    return [...initialData, ...reviewScore || []];
  }, [results, reviewScore]);
  const displayedRowData = useMemo(() => {
    return showAll ? rowData : rowData.slice(0, 4);
  }, [rowData, showAll]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "reviewerId",
        enableSorting: false,
        cell: ({
          row: {
            original: { reviewerId }
          }
        }) => {
          var _a;
          return ((_a = results == null ? void 0 : results.visibilitySettings) == null ? void 0 : _a.hidePeerAuthor) && !reviewerId ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Author hidden" }) : /* @__PURE__ */ jsx(UserCell, { userId: reviewerId });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: ({
          row: {
            original: { type }
          }
        }) => type ? /* @__PURE__ */ jsx("div", { children: getReviewerTypesChips(false)[type] }) : /* @__PURE__ */ jsx(Fragment, {})
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "average",
        enableSorting: false,
        cell: ({
          row: {
            original: { average, type }
          }
        }) => average ? /* @__PURE__ */ jsxs(Typography, { variant: type ? "caption" : "title3", children: [
          average,
          "%"
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ],
    [results]
  );
  const customRowStyle = (row) => {
    if (!row.original.type) return { backgroundColor: themeColors.TableHover };
    return {};
  };
  return /* @__PURE__ */ jsxs("div", { className: "hide-for-capture", children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: displayedRowData != null ? displayedRowData : [],
        columnData: tableColumns,
        loading: resultsLoading,
        paginationSx: { px: spacing.px16, boxSizing: "border-box" },
        customRowStyle,
        hidePagination: true,
        hideHeader: true
      }
    ),
    rowData.length > 4 && /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          gap: spacing.sm,
          textAlign: "center",
          marginTop: spacing.s1,
          px: spacing.s1
        },
        children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "text",
            sizeVariant: "link",
            onClick: () => setShowAll((prev) => !prev),
            style: themeFonts.title4,
            children: showAll ? "Show less" : `+${rowData.length - 4} more`
          }
        )
      }
    )
  ] });
};
