"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { UserPayslips } from "@/component/dashboard/userDetails/payroll/UserPayslips";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { PAYROLL_ME_ROUTE, PAYROLL_ME_SUMMARY_ROUTE } from "@/lib/routes";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
export const PayrollMeRouter = ({ userOverviewConfig }) => {
  var _a;
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, path: PAYROLL_ME_SUMMARY_ROUTE, scopes: ["payroll:read"], context: scopesContext, children: /* @__PURE__ */ jsx(UserPayslips, { userId, userOverviewConfig }) }),
    ((_a = globalState.user) == null ? void 0 : _a.contractType) === UserContractTypeEnum.Payrolled && /* @__PURE__ */ jsx(Redirect, { from: PAYROLL_ME_ROUTE, to: PAYROLL_ME_SUMMARY_ROUTE })
  ] });
};
