"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { debounce, keyBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as LoaderIcon } from "@/images/side-bar-icons/Loader.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REVIEWS_ME_INVITE_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { NotFoundBlock } from "@/v2/components/not-found-block.component";
import { ScrollToBottomButton } from "@/v2/components/scroll-to-bottom-button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReviewAnswerAPI } from "@/v2/feature/growth/reviews/api-client/review-answer.api";
import { ReviewEntryEndpoints } from "@/v2/feature/growth/reviews/api-client/review-entry.api";
import { useProgress } from "@/v2/feature/growth/reviews/features/review-answer/hooks/use-progress.hook";
import { ManagerResultSection } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/manager-result-section.component";
import { MultipleChoiceQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/multiple-choice-question.component";
import { OpenEndedQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/open-ended-question.components";
import { ScaleQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/scale-question.component";
import { SingleAnswerQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/single-answer-question.component";
import {
  getReviewDueDate,
  getReviewType
} from "@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { EntryState } from "@/v2/feature/growth/reviews/interfaces/review-entry.interface";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
import { useScrollHandler } from "@/v2/hook/use-scroll-handler.hook";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewAnswerSubmissionPage = () => {
  var _a;
  const params = useParams();
  const { entryId, cycleId } = params;
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [answerArray, setAnswerArray] = useState(null);
  const [answers, setAnswers] = useState(void 0);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showMessage] = useMessage();
  const { data: entryDetail, isValidating: loadingEntryDetail, error: entryError } = useApiClient(
    ReviewEntryEndpoints.getReviewEntryDetail(entryId, cycleId),
    {
      suspense: false
    }
  );
  const { getScopesContext, hasScopes } = useScopes();
  const { containerRef, showScrollButton, scrollToBottom } = useScrollHandler();
  const { cycle, sections, questions, entry, reviewedBy } = useMemo(() => {
    return entryDetail != null ? entryDetail : { cycle: void 0, sections: [], questions: [], entry: void 0, reviewedBy: [] };
  }, [entryDetail]);
  const questionsLookup = useMemo(() => keyBy(questions != null ? questions : [], "id"), [questions]);
  const reviewType = useMemo(() => entry ? getReviewType(entry) : void 0, [entry]);
  const progressData = useProgress(entryDetail == null ? void 0 : entryDetail.questions, answerArray);
  const hasReviewsManagerScopes = useMemo(
    () => {
      var _a2;
      return entryDetail && ((_a2 = entryDetail == null ? void 0 : entryDetail.entry) == null ? void 0 : _a2.revieweeId) ? hasScopes(["reviews:manager"], getScopesContext({ userId: entryDetail == null ? void 0 : entryDetail.entry.revieweeId })) : false;
    },
    [entryDetail, getScopesContext, hasScopes]
  );
  const isSubmitable = useMemo(() => {
    if (!questions || !answerArray) {
      return false;
    }
    const allQuestionsAnswered = questions.every((q) => {
      var _a2, _b;
      const answer = answerArray.find((a) => a.questionId === q.id);
      if (!answer) return false;
      if (q.type === "openEnded") {
        return answer && answer.comment && isHTMLValid(answer.comment);
      } else {
        return answer && answer.answer && ((_b = (_a2 = answer.answer) == null ? void 0 : _a2.trim()) == null ? void 0 : _b.length) > 0;
      }
    });
    const allCommentsValid = questions.every((question) => {
      if (question.isCommentRequired) {
        const answer = answerArray.find((a) => a.questionId === question.id);
        return answer && answer.comment && isHTMLValid(answer.comment);
      }
      return true;
    });
    return allQuestionsAnswered && allCommentsValid;
  }, [answerArray, questions]);
  const debouncedAutoSave = useMemo(
    () => debounce((answersToSave) => __async(void 0, null, function* () {
      if (!answersToSave || answersToSave.length === 0 || !entry) return;
      try {
        setLoading(true);
        if ((entryDetail == null ? void 0 : entryDetail.entry.entryState) === EntryState.Submitted && isSubmitable && answersToSave && entry) {
          yield ReviewAnswerAPI.submitAnswers(answersToSave, entryId, cycleId, entry.revieweeId);
        } else if ((entryDetail == null ? void 0 : entryDetail.entry.entryState) !== EntryState.Submitted) {
          yield ReviewAnswerAPI.saveAnswersAsDraft(answersToSave, entryId, cycleId, entry.revieweeId);
        }
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setTimeout(() => setLoading(false), 800);
      }
    }), 2e3),
    [entry, entryId, cycleId, entryDetail, isSubmitable, showMessage]
  );
  useEffect(() => {
    if (answerArray) {
      debouncedAutoSave(answerArray);
    }
    return () => {
      debouncedAutoSave.cancel();
    };
  }, [answerArray, debouncedAutoSave]);
  const getAnswersData = useCallback(() => __async(void 0, null, function* () {
    try {
      const response = yield ReviewAnswerAPI.getAnswer(entryId, cycleId);
      setAnswers(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  }), [entryId, cycleId, showMessage]);
  useEffect(() => {
    getAnswersData();
  }, [getAnswersData]);
  useEffect(() => {
    if (answers) {
      setAnswerArray(answers);
    }
  }, [answers]);
  useEffect(() => {
    if (entryDetail && entryDetail.cycle && entryDetail.cycle.state === CycleState.Completed) {
      routerHistory.push(REVIEWS_ME_INVITE_ROUTE);
      showMessage("This cycle has already been completed", "info");
    }
  }, [entryDetail, showMessage, routerHistory]);
  const handleAnswerChange = (questionId, updateObject, entry2) => {
    setAnswerArray((prevAnswers) => {
      const updatedAnswers = (prevAnswers == null ? void 0 : prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          return __spreadProps(__spreadValues({}, answer), { answer: updateObject.updatedAnswer, comment: updateObject.comment });
        }
        return answer;
      })) || [];
      if (!(prevAnswers == null ? void 0 : prevAnswers.some((answer) => answer.questionId === questionId))) {
        updatedAnswers.push({
          id: v4(),
          cycleId,
          entryId,
          reviewId: entry2.reviewId,
          reviewerId: entry2.reviewerUpwardId || entry2.reviewerManagerId || entry2.reviewerPeerId || entry2.reviewerSelfId,
          answerType: getReviewType(entry2),
          revieweeId: entry2.revieweeId,
          comment: updateObject.comment,
          answer: updateObject.updatedAnswer,
          questionId
        });
      }
      return updatedAnswers;
    });
  };
  const handleSaveDraft = () => __async(void 0, null, function* () {
    try {
      if (answerArray && entry) {
        debouncedAutoSave.cancel();
        yield ReviewAnswerAPI.saveAnswersAsDraft(answerArray, entryId, cycleId, entry.revieweeId);
        yield getAnswersData();
        showMessage("Successfully saved as draft", "success");
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  const handleSubmit = () => __async(void 0, null, function* () {
    try {
      if (isSubmitable && answerArray && entry) {
        debouncedAutoSave.cancel();
        yield ReviewAnswerAPI.submitAnswers(answerArray, entryId, cycleId, entry.revieweeId);
        routerHistory.push(REVIEWS_ME_INVITE_ROUTE);
        showMessage("Successfully submitted the answers", "success");
      } else {
        setShowError(true);
        showMessage("Please answer all the questions before submitting", "info");
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  if (!entryDetail && entryError && entryError.status === 403)
    return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) });
  if (!loadingEntryDetail && !entry)
    return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(NotFoundBlock, { title: "Oops", subtitle: "Entry details not found" }) });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: ((_a = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _a.displayName) || (entryDetail == null ? void 0 : entryDetail.cycle.internalName) }),
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s2 }, children: [
          loading ? /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                gap: spacing.sm,
                minWidth: "90px",
                justifyContent: "flex-start"
              },
              children: [
                /* @__PURE__ */ jsx(LoaderIcon, __spreadProps(__spreadValues({}, iconSize), { className: "button-loader-spin-small" })),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReviewAnswerSubmissionPage.saving") })
              ]
            }
          ) : /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                gap: spacing.sm,
                minWidth: "90px",
                justifyContent: "flex-start"
              },
              children: [
                /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReviewAnswerSubmissionPage.autosaved") })
              ]
            }
          ),
          (entryDetail == null ? void 0 : entryDetail.entry.entryState) !== EntryState.Submitted && /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", onClick: () => handleSaveDraft(), children: polyglot.t("ReviewAnswerSubmissionPage.save") }),
          /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => handleSubmit(), children: (entryDetail == null ? void 0 : entryDetail.entry.entryState) === EntryState.Submitted ? "Resubmit" : "Submit" })
        ] }),
        showAction: Boolean((cycle == null ? void 0 : cycle.state) !== CycleState.Completed),
        showBack: true,
        backPath: REVIEWS_ME_INVITE_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(
      LinearProgress,
      {
        value: (progressData == null ? void 0 : progressData.progress) || 0,
        variant: "determinate",
        sx: {
          "& .MuiLinearProgress-bar": {
            backgroundColor: themeColors.Green
          },
          backgroundColor: themeColors.Background
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ContentWrapper,
      {
        loading: loadingEntryDetail,
        ref: containerRef,
        hideFooter: true,
        sx: {
          overflowY: "auto",
          maxHeight: "calc(100vh - 110px)"
        },
        children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              gap: spacing.s2,
              paddingX: { xs: spacing.s1, md: spacing.s4 }
            },
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    overflowY: "hidden",
                    flexDirection: "column",
                    gap: spacing.g16,
                    maxWidth: "600px",
                    width: "100%",
                    margin: "0 auto",
                    boxSizing: "border-box",
                    marginTop: spacing.s4,
                    padding: spacing.s2
                  },
                  children: [
                    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8, py: spacing.s2 }, children: [
                      entry && cycle && /* @__PURE__ */ jsx(
                        ViewItem,
                        {
                          label: polyglot.t("ReviewAnswerSubmissionPage.dueDate"),
                          value: getReviewDueDate(__spreadProps(__spreadValues({}, entry), { cycle })).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" })
                        }
                      ),
                      (reviewType === ReviewerTypes.Manager || reviewType === ReviewerTypes.Upward || reviewType === ReviewerTypes.Peer) && entry && entry.revieweeId && /* @__PURE__ */ jsx(
                        ViewItem,
                        {
                          label: polyglot.t("ReviewAnswerSubmissionPage.youAreReviewing"),
                          value: /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId })
                        }
                      ),
                      reviewType === ReviewerTypes.Manager && entry && reviewedBy && reviewedBy.length > 0 && hasReviewsManagerScopes && /* @__PURE__ */ jsx(
                        ViewItem,
                        {
                          label: polyglot.t("ReviewAnswerSubmissionPage.reviewedBy"),
                          value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: reviewedBy }),
                          subValue: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setShowResult(true), colorVariant: "secondary", sizeVariant: "small", children: polyglot.t("ReviewAnswerSubmissionPage.seeAllResults") })
                        }
                      )
                    ] }),
                    entryDetail == null ? void 0 : entryDetail.cycle.order.map((item, idx) => /* @__PURE__ */ jsx(
                      RenderQuestionOrSection,
                      {
                        isFirst: idx === 0,
                        item,
                        questionsLookup,
                        handleAnswerChange,
                        reviewType,
                        sections,
                        cycle,
                        entry,
                        answerArray,
                        showError
                      },
                      `answer-${idx}`
                    )),
                    /* @__PURE__ */ jsx(ScrollToBottomButton, { show: showScrollButton, onClick: scrollToBottom })
                  ]
                }
              ),
              showResult && entry && entry.revieweeId && /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    overflowY: "hidden",
                    flexDirection: "column",
                    maxWidth: "600px",
                    width: "100%",
                    height: "100%",
                    alignItems: { xs: "center", md: "flex-start" },
                    justifyContent: "flex-end",
                    boxSizing: "border-box",
                    marginTop: spacing.s4,
                    padding: spacing.s2
                  },
                  children: /* @__PURE__ */ jsx(ManagerResultSection, { setShowResult, revieweeId: entry.revieweeId, cycleId })
                }
              )
            ]
          }
        )
      }
    )
  ] });
};
const RenderQuestionOrSection = ({
  item,
  isFirst,
  questionsLookup,
  handleAnswerChange,
  reviewType,
  sections,
  cycle,
  entry,
  answerArray,
  showError
}) => {
  const renderQuestion = (questionId) => {
    const question = questionsLookup && questionsLookup[questionId];
    if (!question || !cycle) return null;
    return /* @__PURE__ */ jsx(
      QuestionComponent,
      {
        question,
        reviewType,
        answerArray,
        handleAnswerChange,
        entry,
        cycle,
        showError
      },
      question.id
    );
  };
  const renderSection = (sectionId) => {
    var _a;
    const section = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section || !cycle) return null;
    const orderSection = cycle == null ? void 0 : cycle.order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a = orderSection.questions) != null ? _a : []).map((questionId) => questionsLookup[questionId]).filter((question) => Boolean(question));
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: section.name }),
      orderedQuestions.map((q) => /* @__PURE__ */ jsx(
        QuestionComponent,
        {
          question: q,
          reviewType,
          answerArray,
          handleAnswerChange,
          entry,
          cycle,
          showError
        },
        q.id
      ))
    ] });
  };
  const renderQuestionOrSection = () => {
    var _a;
    if (item.type === "question" && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === "section" && ((_a = item.questions) != null ? _a : []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return /* @__PURE__ */ jsx(Box, { sx: { marginTop: isFirst ? "0px" : spacing.m48 }, children: renderSection(item.id) });
    } else return /* @__PURE__ */ jsx(Fragment, {});
  };
  return /* @__PURE__ */ jsx(Fragment, { children: renderQuestionOrSection() });
};
const QuestionComponent = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  cycle,
  showError
}) => {
  const { polyglot } = usePolyglot();
  const questionText = useMemo(() => {
    if (!reviewType || !question) return "";
    if (reviewType === ReviewerTypes.Self) return question.questionSelf;
    return question.questionMain;
  }, [reviewType, question]);
  const showHiddenInformation = useMemo(() => {
    if (!cycle || !question || reviewType === ReviewerTypes.Self) return false;
    return true;
  }, [cycle, question, reviewType]);
  const showHiddenInformationTest = useMemo(() => {
    if (!cycle || !question || reviewType === ReviewerTypes.Self || !entry) return /* @__PURE__ */ jsx(Fragment, {});
    const questionVisibility = question.visibilitySettings.hideManagerResult && reviewType === ReviewerTypes.Manager || question.visibilitySettings.hidePeerResult && reviewType === ReviewerTypes.Peer || question.visibilitySettings.hideUpwardResult && reviewType === ReviewerTypes.Upward;
    if (questionVisibility) {
      return /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.sm, width: "auto" }, children: [
        polyglot.t("ReviewAnswerSubmissionPage.yourAnswerWillNotBeSharedWith"),
        " ",
        /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId })
      ] });
    }
    if (cycle.visibilitySettings.hidePeerAuthor && reviewType === ReviewerTypes.Peer) {
      return /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.sm, width: "auto" }, children: [
        /* @__PURE__ */ jsx("span", { children: polyglot.t("ReviewAnswerSubmissionPage.yourAnswerWillBeSharedWith") }),
        /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId }) }),
        /* @__PURE__ */ jsx("span", { children: polyglot.t("ReviewAnswerSubmissionPage.withoutShowingYourName") })
      ] });
    }
    return /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
      polyglot.t("ReviewAnswerSubmissionPage.yourAnswerWillBeSharedWith"),
      " ",
      /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId })
    ] });
  }, [cycle, question, reviewType, entry, polyglot]);
  const getQuestionByType = (question2) => {
    switch (question2.type) {
      case QuestionType.SingleAnswer:
        return /* @__PURE__ */ jsx(
          SingleAnswerQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.OpenEnded:
        return /* @__PURE__ */ jsx(
          OpenEndedQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.MultipleAnswer:
        return /* @__PURE__ */ jsx(
          MultipleChoiceQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.ScaleQuestion:
        return /* @__PURE__ */ jsx(
          ScaleQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      default:
        return null;
    }
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.s2,
        padding: spacing.p16,
        border: borders.background,
        borderRadius: radius.br8
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
        showHiddenInformation && /* @__PURE__ */ jsx(
          ChipComponent,
          {
            name: showHiddenInformationTest,
            backgroundColor: "white",
            border: "middle",
            style: { width: "fit-content" }
          }
        ),
        getQuestionByType(question)
      ]
    }
  );
};
const ViewItem = ({
  label,
  value,
  subValue
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "inline-grid",
        gridTemplateColumns: "2fr 2fr",
        rowGap: spacing.g24,
        columnGap: spacing.g4,
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: label }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value }),
          subValue && /* @__PURE__ */ jsx(Fragment, { children: subValue })
        ] })
      ]
    }
  );
};
