"use strict";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ReachType } from "@/v2/interfaces/general.interface";
export const useGoalsReach = () => {
  const location = useLocation();
  const reach = useMemo(() => {
    if (location.pathname.includes("/growth/company/")) {
      return ReachType.Company;
    }
    return ReachType.Me;
  }, [location.pathname]);
  return reach;
};
