"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { AbsenceEmpty } from "@v2/feature/absence/company/policies/pages/absence-empty.page";
import { AbsenceDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-drawer.section";
import { AbsenceViewDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page";
import { AbsenceTable, getInitialFilterString } from "@v2/feature/absence/sections/absence-table.section";
import { AbsenceUserFilterFeaturesUpdater } from "@v2/feature/absence/subfeatures/settings/components/absence-user-filter-feature-updater.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Reject.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { iconSize } from "@/v2/styles/menu.styles";
export const AbsenceRequestsPage = ({ absencePolicies, reach, absencePoliciesLoading }) => {
  const { polyglot } = usePolyglot();
  const { hasScopes, getScopesContext } = useScopes();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { user } = globalState;
  const context = getScopesContext(user);
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState(false);
  const [isViewAbsenceDrawerOpen, setIsViewAbsenceDrawerOpen] = useState(false);
  const [absences, setAbsences] = useState([]);
  const [drawerAbsence, setDrawerAbsence] = useState(void 0);
  const [viewAbsence, setViewAbsence] = useState(void 0);
  const { trackPage } = useJune();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [showMessage] = useMessage();
  const isAdmin = reach === "company" && hasScopes(["absence:all"], context);
  const [selectionModel, setSelectionModel] = useState([]);
  const initialFilterString = useMemo(
    () => {
      var _a, _b, _c, _d, _e, _f, _g;
      return getInitialFilterString(
        (_g = isAdmin ? (_c = (_b = (_a = user == null ? void 0 : user.features) == null ? void 0 : _a.timeAway) == null ? void 0 : _b.requestsTable) == null ? void 0 : _c.companyFilters : (_f = (_e = (_d = user == null ? void 0 : user.features) == null ? void 0 : _d.timeAway) == null ? void 0 : _e.requestsTable) == null ? void 0 : _f.teamFilters) != null ? _g : "Calendar=current",
        absencePolicies
      );
    },
    [user, absencePolicies, isAdmin]
  );
  const [filterString, setFilterString] = useState(initialFilterString);
  const [searchInput, setSearchInput] = useState("");
  const setAbsenceRecordsFromServer = useCallback(() => __async(void 0, null, function* () {
    try {
      const absencesResponse = isAdmin ? yield AbsenceAPI.fetchAllAbsences(pagination.pageIndex, pagination.pageSize, filterString, searchInput) : yield AbsenceAPI.fetchManagerTreeAbsences(
        pagination.pageIndex,
        pagination.pageSize,
        filterString,
        searchInput
      );
      setAbsences(absencesResponse.items);
      setTotalPages(absencesResponse.totalPages);
      setTotalItems(absencesResponse.totalItems);
      setDrawerAbsence(void 0);
      setIsViewAbsenceDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceRequestsPage.errorMessages.fetch", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, isAdmin, pagination.pageIndex, pagination.pageSize, filterString, searchInput, showMessage]);
  const removeRequestHelper = useCallback(
    (absence) => __async(void 0, null, function* () {
      if (absence == null ? void 0 : absence.absenceId) {
        try {
          yield AbsenceAPI.deleteAbsenceRecord(absence.absenceId);
          showMessage(polyglot.t("AbsenceRequestsPage.successMessages.remove"), "success");
          yield setAbsenceRecordsFromServer();
        } catch (error) {
          showMessage(
            polyglot.t("AbsenceRequestsPage.errorMessages.remove", { nestErrorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      }
    }),
    [polyglot, setAbsenceRecordsFromServer, showMessage]
  );
  const decideAbsence = useCallback(
    (absenceId, decision, rejectionNotes) => __async(void 0, null, function* () {
      let updatedAbsence = void 0;
      try {
        if (decision === AbsenceStatus.Approved) {
          const updatedAbsences = yield AbsenceAPI.approveAllAbsences([absenceId]);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t("AbsenceRequestsPage.successMessages.approve"), "success");
        } else {
          const updatedAbsences = yield AbsenceAPI.rejectAllAbsences([absenceId], rejectionNotes);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t("AbsenceRequestsPage.successMessages.reject"), "success");
        }
        yield setAbsenceRecordsFromServer();
        const alertsForAbsences = yield AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences }
        });
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceRequestsPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
      return updatedAbsence;
    }),
    [polyglot, globalDispatch, setAbsenceRecordsFromServer, showMessage, user.userId]
  );
  const handleApprove = useCallback((a) => decideAbsence(a.absenceId, AbsenceStatus.Approved), [
    decideAbsence
  ]);
  const handleReject = useCallback(
    (a, rejectionNotes) => decideAbsence(a.absenceId, AbsenceStatus.Rejected, rejectionNotes),
    [decideAbsence]
  );
  const handleRejectCancellation = useCallback(
    (a) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.rejectTimeCancellationRequests([a.absenceId], a.userId);
        showMessage(polyglot.t("AbsenceRequestsPage.successMessages.rejectCancellation"), "success");
        yield setAbsenceRecordsFromServer();
        const alertsForAbsences = yield AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences }
        });
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceRequestsPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage, setAbsenceRecordsFromServer, user.userId, globalDispatch]
  );
  const onForceApproval = useCallback(
    (a, status) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.forceApprovalByAbsencesIds([a.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved ? "AbsenceRequestsPage.successMessages.approve" : "AbsenceRequestsPage.successMessages.reject"
          ),
          "success"
        );
        yield setAbsenceRecordsFromServer();
        const alertsForAbsences = yield AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences }
        });
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceRequestsPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage, setAbsenceRecordsFromServer, user.userId, globalDispatch]
  );
  const handleRowClicked = useCallback((original) => {
    setViewAbsence(original);
    setIsViewAbsenceDrawerOpen(true);
  }, []);
  useEffect(() => {
    (function() {
      return __async(this, null, function* () {
        setLoadingData(true);
        try {
          yield setAbsenceRecordsFromServer();
        } catch (error) {
          showMessage(
            polyglot.t("AbsenceRequestsPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
            "error"
          );
        } finally {
          setLoadingData(false);
        }
      });
    })();
  }, [polyglot, showMessage, setAbsenceRecordsFromServer]);
  const handleApproveAll = () => __async(void 0, null, function* () {
    try {
      yield AbsenceAPI.approveAllAbsences(selectionModel);
      setSelectionModel([]);
      yield setAbsenceRecordsFromServer();
      showMessage(polyglot.t("AbsenceRequestsPage.successMessages.approveAll"), "success");
    } catch (error) {
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
    }
  });
  const handleRejectAll = () => __async(void 0, null, function* () {
    try {
      yield AbsenceAPI.rejectAllAbsences(selectionModel);
      setSelectionModel([]);
      yield setAbsenceRecordsFromServer();
      showMessage(polyglot.t("AbsenceRequestsPage.successMessages.rejectAll"), "success");
    } catch (error) {
      showMessage(
        polyglot.t("AbsenceRequestsPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const getAbsenceActions = () => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
        handler: () => handleApproveAll(),
        label: polyglot.t("AbsenceRequestsPage.approveAll"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Cancel, __spreadValues({}, iconSize)),
        handler: () => handleRejectAll(),
        label: polyglot.t("AbsenceRequestsPage.rejectAll"),
        disabled: false
      }
    ];
  };
  useEffect(() => {
    trackPage("Time Company requests page");
  }, [trackPage]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", color: "DarkGrey", children: polyglot.t("AbsenceRequestsPage.requests") }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ScopesControl, { scopes: ["absence"], context, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => {
              setIsAbsenceDrawerOpen(true);
              setDrawerAbsence(void 0);
            },
            children: polyglot.t("AbsenceRequestsPage.newRequest")
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: absencePoliciesLoading, sx: { overflow: "hidden" }, children: [
      absencePolicies && absencePolicies.length > 0 ? /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(
          AbsenceTable,
          {
            onRowClick: handleRowClicked,
            rows: absences,
            showUserColumn: true,
            loading: loadingData,
            onEdit: (absence) => {
              setDrawerAbsence(absence);
              setIsAbsenceDrawerOpen(true);
            },
            onDelete: removeRequestHelper,
            onApprove: handleApprove,
            onReject: handleReject,
            onRejectCancellation: handleRejectCancellation,
            onForceApproval,
            newRequestButton: selectionModel.length > 0 && /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: getAbsenceActions(),
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: polyglot.t("AbsenceRequestsPage.actions"),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                }
              }
            ),
            setSelectionModel,
            selectionModel,
            pagination,
            setPagination,
            totalPages,
            totalItems,
            searchInput,
            setDebouncedSearchInput: (value) => {
              setSearchInput(!value ? "" : value);
              setPagination((prev) => ({
                pageIndex: 1,
                pageSize: prev.pageSize
              }));
            },
            filterString,
            setFilterString,
            view: reach,
            userId: void 0,
            stickyHeader: true
          }
        ),
        isAbsenceDrawerOpen && /* @__PURE__ */ jsx(
          AbsenceDrawer,
          {
            isOpen: isAbsenceDrawerOpen,
            onClose: () => {
              setIsAbsenceDrawerOpen(false);
              setDrawerAbsence(void 0);
            },
            refresh: setAbsenceRecordsFromServer,
            absence: drawerAbsence,
            setIsAbsenceDrawerOpen,
            reach
          }
        ),
        viewAbsence && isViewAbsenceDrawerOpen && !isAbsenceDrawerOpen && /* @__PURE__ */ jsx(
          AbsenceViewDrawer,
          {
            isOpen: isViewAbsenceDrawerOpen,
            absence: viewAbsence,
            setAbsence: setViewAbsence,
            afterClose: () => setViewAbsence(void 0),
            setIsAbsenceDrawerViewOpen: setIsViewAbsenceDrawerOpen,
            handleApprove,
            handleReject,
            onDelete: removeRequestHelper,
            onRejectCancellation: handleRejectCancellation,
            onForceApproval,
            onEdit: (absence) => {
              setDrawerAbsence(absence);
              setIsAbsenceDrawerOpen(true);
              setIsViewAbsenceDrawerOpen(false);
            },
            refresh: setAbsenceRecordsFromServer,
            showCalendarLink: true
          }
        )
      ] }) : /* @__PURE__ */ jsx(AbsenceEmpty, {}),
      /* @__PURE__ */ jsx(
        AbsenceUserFilterFeaturesUpdater,
        {
          filterString,
          initialFilterString,
          globalDispatch,
          view: isAdmin ? "company" : "team"
        }
      )
    ] })
  ] });
};
