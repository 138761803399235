"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { QuestionsDrawer } from "@v2/feature/device/components/device-details/questions-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceAPI, DeviceEndpoints } from "@/v2/feature/device/device.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const useActionPerformedToday = (devicePossession) => {
  var _a;
  const { data } = useApiClient(
    DeviceEndpoints.getActivityLogForDeviceByDomainAndActionName(
      devicePossession.deviceId,
      ((_a = devicePossession.device) == null ? void 0 : _a.inHouseMdm) ? "deviceActionInHouseMdm" : "deviceAction",
      "wipeDevice"
    ),
    { suspense: false }
  );
  return data != null ? data : false;
};
const WipeDeviceForm = ({ onSubmit, disabled }) => {
  const { polyglot } = usePolyglot();
  const [confirmationText, setConfirmationText] = useState("");
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "confirm",
        placeholder: polyglot.t("WipeDevice.confirmPlaceholder", {
          confirmText: polyglot.t("WipeDevice.confirmText")
        }),
        fullWidth: true,
        value: confirmationText,
        type: "text",
        onChange: (event) => setConfirmationText(event.target.value),
        clearText: () => setConfirmationText("")
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        disabled: disabled || confirmationText !== polyglot.t("WipeDevice.confirmText"),
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "secondary",
        onClick: onSubmit,
        children: polyglot.t("WipeDevice.submitButton")
      }
    ) })
  ] });
};
const WipeDeviceDrawerContent = (props) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const hasActionToday = useActionPerformedToday(props.devicePossession);
  const [isQuestionFromOpened, setIsQuestionFromOpened] = useState(hasActionToday != null ? hasActionToday : false);
  useEffect(() => {
    if (hasActionToday !== void 0) {
      setIsQuestionFromOpened(hasActionToday);
    }
  }, [hasActionToday]);
  const handleWipeDevice = () => __async(void 0, null, function* () {
    var _a;
    if (!((_a = props.devicePossession) == null ? void 0 : _a.id)) {
      showMessage(polyglot.t("WipeDevice.messages.error"), "error");
      return;
    }
    try {
      yield DeviceAPI.wipeDevice(props.devicePossession.id);
      showMessage(polyglot.t("WipeDevice.messages.success"), "success");
      yield props.onClose();
    } catch (error) {
      showMessage(polyglot.t("WipeDevice.messages.error"), "error");
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(
      QuestionsDrawer,
      {
        title: polyglot.t("WipeDevice.action.executedTitle"),
        description: polyglot.t("WipeDevice.action.exists"),
        isOpen: isQuestionFromOpened,
        setIsOpen: setIsQuestionFromOpened,
        loading: false,
        action: () => __async(void 0, null, function* () {
          setIsQuestionFromOpened(false);
        }),
        onClose: props.onClose
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("WipeDevice.title") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Red }, children: polyglot.t("WipeDevice.messages.details") }),
    /* @__PURE__ */ jsx(WipeDeviceForm, { onSubmit: handleWipeDevice, disabled: false })
  ] });
};
export const WipeDeviceDrawer = ({ isOpen, onClose, devicePossession, setIsOpen }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  WipeDeviceDrawerContent,
  {
    devicePossession,
    onClose,
    isOpen,
    setIsOpen
  }
) });
