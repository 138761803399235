"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { keyBy } from "lodash";
export const sortCustomFields = (customFields) => {
  return customFields.sort(
    (a, b) => a.field.fieldName.localeCompare(b.field.fieldName, void 0, { sensitivity: "base" })
  );
};
export const sortSelectOptions = (customFieldOptions) => {
  const sortedOptions = [...customFieldOptions].sort((a, b) => {
    var _a, _b, _c, _d, _e, _f;
    return Number((_c = (_b = (_a = a.key) == null ? void 0 : _a.match(/\d+$/)) == null ? void 0 : _b[0]) != null ? _c : "0") - Number((_f = (_e = (_d = b.key) == null ? void 0 : _d.match(/\d+$/)) == null ? void 0 : _e[0]) != null ? _f : "0");
  });
  for (let i = 0; i < sortedOptions.length; i++) {
    sortedOptions[i] = __spreadProps(__spreadValues({}, sortedOptions[i]), { key: `Answer ${i + 1}` });
  }
  return sortedOptions;
};
export const getMultipleValue = (localValue, options) => {
  const valueKey = keyBy(options, "value");
  return localValue.split(",").map((value) => {
    const option = valueKey[value];
    return __spreadValues({}, option);
  });
};
