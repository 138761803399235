"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import ReactApexChart from "react-apexcharts";
import {
  DEFAULT_CHART_FONT,
  DEFAULT_CHART_HEIGHT,
  DEFAULT_CHART_WIDTH
} from "@/v2/components/charts/chart.interface";
import { normaliseYAxis } from "@/v2/components/charts/utils/chart.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import "@/v2/components/charts/styles/apex-charts.styles.css";
export function ChartBar({ series, categories, categoriesType = "category", options: inputOptions }) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
  const { polyglot } = usePolyglot();
  const defaultOptions = {
    percentage: {
      enable: false
    },
    horizontal: false,
    stacked: false,
    yAxisOptions: void 0,
    tooltip: {
      enabled: true
    },
    legend: {
      show: series.length > 1,
      align: "right",
      position: "top"
    }
  };
  const options = __spreadProps(__spreadValues(__spreadValues({}, defaultOptions), inputOptions), {
    height: (_a = inputOptions == null ? void 0 : inputOptions.height) != null ? _a : DEFAULT_CHART_HEIGHT,
    width: (_b = inputOptions == null ? void 0 : inputOptions.width) != null ? _b : DEFAULT_CHART_WIDTH,
    colors: ((_c = inputOptions == null ? void 0 : inputOptions.colors) == null ? void 0 : _c.length) ? inputOptions.colors : [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    noDataTitle: (_d = inputOptions == null ? void 0 : inputOptions.noDataTitle) != null ? _d : polyglot.t("Charts.noData")
  });
  function isSeriesDataArray(series2) {
    return Array.isArray(series2) && series2.length > 0 && "data" in series2[0];
  }
  const yAxisConfig = isSeriesDataArray(series) ? normaliseYAxis(
    series,
    (_f = (_e = options.percentage) == null ? void 0 : _e.enable) != null ? _f : false,
    (_h = (_g = options.percentage) == null ? void 0 : _g.unboundedMax) != null ? _h : false,
    options.yAxisOptions
  ) : normaliseYAxis(
    [{ data: series, name: "" }],
    (_j = (_i = options.percentage) == null ? void 0 : _i.enable) != null ? _j : false,
    (_l = (_k = options.percentage) == null ? void 0 : _k.unboundedMax) != null ? _l : false,
    options.yAxisOptions
  );
  const chartOptions = {
    chart: {
      fontFamily: DEFAULT_CHART_FONT,
      toolbar: {
        show: false
      },
      stacked: options.stacked
    },
    plotOptions: {
      bar: {
        horizontal: options.horizontal,
        borderRadius: 5,
        borderRadiusApplication: "end"
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: categoriesType,
      categories,
      labels: {
        show: categories.length > 0,
        style: {
          colors: Array(categories.length).fill(themeColors.Grey),
          fontWeight: 400,
          fontSize: "12px"
        }
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: __spreadValues({
      tickAmount: 5,
      labels: {
        style: {
          fontSize: "12px"
        }
      },
      crosshairs: {
        show: false
      }
    }, yAxisConfig),
    tooltip: {
      enabled: (_m = options.tooltip) == null ? void 0 : _m.enabled,
      onDatasetHover: {
        highlightDataSeries: false
      },
      style: { fontWeight: 400, fontSize: "12px" },
      custom: ((_o = (_n = options.tooltip) == null ? void 0 : _n.custom) != null ? _o : series.length === 1) ? function({ series: series2, seriesIndex, dataPointIndex }) {
        return `<div class="arrow_box">${series2[seriesIndex][dataPointIndex]}${options.percentage ? "%" : ""}</div>`;
      } : void 0
    },
    legend: {
      show: (_p = options.legend) == null ? void 0 : _p.show,
      horizontalAlign: (_q = options.legend) == null ? void 0 : _q.align,
      position: (_r = options.legend) == null ? void 0 : _r.position,
      fontWeight: 400,
      fontSize: "12px",
      labels: { colors: [themeColors.DarkGrey] },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        radius: 16
      }
    },
    stroke: {
      show: true,
      width: 6,
      colors: ["transparent"]
    },
    colors: options.colors,
    noData: { text: options.noDataTitle },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    }
  };
  return /* @__PURE__ */ jsx(ReactApexChart, { type: "bar", series, options: chartOptions, height: options.height, width: options.width });
}
