"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_SCHEDULE_CAP, getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import { UserShiftHandlerDrawerContent } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler-drawer-content.component";
import { UserShiftHandlerWidgetContent } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler-widget-content.component";
import { useTimeout } from "@v2/feature/dashboard/features/sections/user-shift/use-timeout.hook";
import {
  endAttendanceShift,
  endAttendanceShiftEntry,
  extractTimeFromShiftEntries,
  getCurrentPosition,
  getCurrentShiftEntry,
  getDateDayUtil,
  getDistance,
  getRelevantShiftDayFromSchedule,
  getShiftLoggedHours,
  getTheSelectedDaySchedule,
  isInAcceptedProximityUtil,
  isLessThanEarlyCapMinutesUntilStart,
  startAttendanceShift
} from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { extractTimeOnlyFromDateTimeString } from "@v2/util/date-format.util";
import { LocalDate } from "@v2/util/local-date";
import { SiteEndpoints } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
export const UserShiftHandler = ({
  attendanceSchedule,
  shift,
  userSiteId,
  currentWeekAttendanceEntries,
  refresh,
  mode,
  selectedDate = new LocalDate().toDateString(),
  readOnly = false
}) => {
  var _a, _b;
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isFetchingLocation, setIsFetchingLocation] = useState(true);
  const [isStartingShiftEntry, setIsStartingShiftEntry] = useState(false);
  const [isEndingShiftEntry, setIsEndingShiftEntry] = useState(false);
  const [isEndingDay, setIsEndingDay] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  useTimeout(() => {
    setIsFetchingLocation(false);
  }, 3e3);
  useEffect(() => {
    getCurrentPosition(navigator, setIsFetchingLocation, setCurrentPosition);
  }, []);
  const distance = useMemo(() => {
    return getDistance(attendanceSchedule, currentPosition, userSiteId, sites);
  }, [currentPosition, userSiteId, sites, attendanceSchedule]);
  const isInAcceptedProximity = useMemo(() => {
    return isInAcceptedProximityUtil(attendanceSchedule, distance);
  }, [distance, attendanceSchedule]);
  const todaysDay = useMemo(() => getDateDayUtil(), []);
  const selectedDay = useMemo(() => getDateDayUtil(selectedDate), [selectedDate]);
  const relevantDay = useMemo(
    () => getRelevantShiftDayFromSchedule(attendanceSchedule, todaysDay, currentWeekAttendanceEntries, shift),
    [attendanceSchedule, todaysDay, currentWeekAttendanceEntries, shift]
  );
  const relevantDayWeekIndex = useMemo(
    () => getIndexOfWeekInSchedule(
      attendanceSchedule.startDateOfFirstWeek,
      relevantDay.weekDate,
      attendanceSchedule.noOfWeeks
    ),
    [relevantDay, attendanceSchedule]
  );
  const shiftOrScheduledShift = useMemo(() => {
    return Boolean(
      shift || attendanceSchedule && attendanceSchedule[relevantDay.weekDay] && attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex]
    );
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, shift]);
  const isScheduledForToday = useMemo(() => {
    return Boolean(
      attendanceSchedule && attendanceSchedule[relevantDay.weekDay] && attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex] && selectedDay === relevantDay.weekDay
    );
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, selectedDay]);
  const theFollowingScheduledDay = useMemo(
    () => getTheSelectedDaySchedule(attendanceSchedule, relevantDay.weekDay, relevantDay.weekDate),
    [relevantDay, attendanceSchedule]
  );
  const relevantDate = useMemo(() => {
    return new LocalDate().getDate();
  }, []);
  const theSelectedDaySchedule = useMemo(
    () => getTheSelectedDaySchedule(attendanceSchedule, selectedDay, selectedDate),
    [selectedDate, selectedDay, attendanceSchedule]
  );
  const isDayStarted = useMemo(() => Boolean(shift && !shift.isEnded), [shift]);
  const isShiftEntryStarted = useMemo(
    () => Boolean(
      isDayStarted && shift && !shift.isEnded && shift.shiftEntries.at(-1) && !shift.shiftEntries.at(-1).endHour
    ),
    [shift, isDayStarted]
  );
  const inProgressShift = useMemo(() => {
    var _a2;
    return shift && !shift.isEnded ? (_a2 = shift.shiftEntries.at(-1)) != null ? _a2 : null : null;
  }, [shift]);
  const currentEntry = useMemo(() => {
    return getCurrentShiftEntry(theSelectedDaySchedule);
  }, [theSelectedDaySchedule]);
  const lessThanEarlyCapMinutesUntilStart = useMemo(() => {
    if (new LocalDate().toDateString() !== new LocalDate(relevantDate).toDateString()) return true;
    return isLessThanEarlyCapMinutesUntilStart(
      attendanceSchedule,
      shiftOrScheduledShift,
      isShiftEntryStarted,
      currentEntry
    );
  }, [shiftOrScheduledShift, currentEntry, isShiftEntryStarted, attendanceSchedule, relevantDate]);
  const currentType = useMemo(() => {
    var _a2;
    if (!currentEntry) return null;
    return (_a2 = attendanceSchedule.attendanceTypesAllowed.find((type) => type.id === currentEntry.typeId)) != null ? _a2 : null;
  }, [attendanceSchedule, currentEntry]);
  const time = useMemo(() => extractTimeFromShiftEntries(shift), [shift]);
  const isDayEnded = useMemo(() => Boolean(shift == null ? void 0 : shift.isEnded), [shift == null ? void 0 : shift.isEnded]);
  const startShiftEntry = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    if (!currentEntry) {
      showMessage("Cannot find the current shift entry", "error");
      return;
    }
    if (!(currentPosition == null ? void 0 : currentPosition.coords) && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t("AttendanceDomain.AttendanceShift.couldNotGetLocation"), "error");
      return;
    }
    setIsStartingShiftEntry(true);
    yield startAttendanceShift(
      currentEntry.id,
      isInAcceptedProximity,
      distance,
      attendanceSchedule.useGeolocation ? (_a2 = currentPosition == null ? void 0 : currentPosition.coords.longitude) != null ? _a2 : null : null,
      attendanceSchedule.useGeolocation ? (_b2 = currentPosition == null ? void 0 : currentPosition.coords.latitude) != null ? _b2 : null : null,
      showMessage,
      refresh,
      polyglot,
      todaysDay !== relevantDay.weekDay
    );
    setIsStartingShiftEntry(false);
  }), [
    currentEntry,
    attendanceSchedule.useGeolocation,
    currentPosition,
    isInAcceptedProximity,
    distance,
    showMessage,
    refresh,
    polyglot,
    todaysDay,
    relevantDay
  ]);
  const endShiftEntry = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2;
    if (!inProgressShift) {
      showMessage("Cannot find the current shift entry", "error");
      return;
    }
    if (!(currentPosition == null ? void 0 : currentPosition.coords) && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t("AttendanceDomain.AttendanceShift.couldNotGetLocation"), "error");
      return;
    }
    setIsEndingShiftEntry(true);
    yield endAttendanceShiftEntry(
      inProgressShift.entryId,
      attendanceSchedule.useGeolocation ? (_a2 = currentPosition == null ? void 0 : currentPosition.coords.longitude) != null ? _a2 : null : null,
      attendanceSchedule.useGeolocation ? (_b2 = currentPosition == null ? void 0 : currentPosition.coords.latitude) != null ? _b2 : null : null,
      showMessage,
      refresh,
      polyglot,
      todaysDay !== relevantDay.weekDay
    );
    setIsEndingShiftEntry(false);
  }), [
    inProgressShift,
    attendanceSchedule.useGeolocation,
    currentPosition,
    refresh,
    showMessage,
    polyglot,
    todaysDay,
    relevantDay
  ]);
  const endDay = useCallback(() => __async(void 0, null, function* () {
    if (!(theSelectedDaySchedule == null ? void 0 : theSelectedDaySchedule.daySchedule) || theSelectedDaySchedule.daySchedule.length === 0) return;
    setIsEndingDay(true);
    yield endAttendanceShift(selectedDate, showMessage, refresh, polyglot);
    setIsEndingDay(false);
  }), [theSelectedDaySchedule, selectedDate, showMessage, refresh, polyglot]);
  const shiftLoggedHours = useMemo(
    () => attendanceSchedule ? getShiftLoggedHours(theFollowingScheduledDay, attendanceSchedule.isFlexible, polyglot) : [],
    [theFollowingScheduledDay, polyglot, attendanceSchedule]
  );
  const shiftSelectedLoggedHours = useMemo(
    () => attendanceSchedule ? getShiftLoggedHours(theSelectedDaySchedule, attendanceSchedule.isFlexible, polyglot) : [],
    [polyglot, theSelectedDaySchedule, attendanceSchedule]
  );
  const canCompleteDay = useMemo(() => {
    if (!(theSelectedDaySchedule == null ? void 0 : theSelectedDaySchedule.daySchedule) || theSelectedDaySchedule.daySchedule.length === 0) return false;
    if (!shift || !shift.shiftEntries || shift.shiftEntries.length === 0) return false;
    const lastScheduledEntry = theSelectedDaySchedule.daySchedule.at(-1);
    const lastShiftEntry = shift.shiftEntries.at(-1);
    if (!lastShiftEntry.endHour) return false;
    const now = extractTimeOnlyFromDateTimeString();
    const expectedStart = extractTimeOnlyFromDateTimeString(lastScheduledEntry.from);
    const expectedEnd = extractTimeOnlyFromDateTimeString(lastScheduledEntry.to);
    const shiftEnd = extractTimeOnlyFromDateTimeString(lastShiftEntry.endHour);
    if (now > expectedEnd) return true;
    if (lastShiftEntry.attendanceTypeId !== lastScheduledEntry.typeId) return false;
    return shiftEnd > expectedStart;
  }, [shift, theSelectedDaySchedule]);
  return mode === "widget" ? /* @__PURE__ */ jsx(
    UserShiftHandlerWidgetContent,
    {
      clockInEarlyCapMinutes: (_a = attendanceSchedule.clockInEarlyCapMinutes) != null ? _a : DEFAULT_SCHEDULE_CAP,
      theFollowingScheduledDay,
      isShiftEntryStarted,
      isDayStarted,
      isDayEnded,
      time,
      shiftLoggedHours,
      isFetchingLocation,
      lessThanEarlyCapMinutesUntilStart,
      startShiftEntry,
      endShiftEntry,
      isStartingShiftEntry,
      isEndingShiftEntry,
      relevantDate,
      readOnly,
      shiftOrScheduledShift,
      currentType,
      endDay,
      isLastEntryLogged: canCompleteDay,
      isEndingDay
    }
  ) : /* @__PURE__ */ jsx(
    UserShiftHandlerDrawerContent,
    {
      clockInEarlyCapMinutes: (_b = attendanceSchedule.clockInEarlyCapMinutes) != null ? _b : DEFAULT_SCHEDULE_CAP,
      theFollowingScheduledDay,
      isShiftEntryStarted,
      isDayStarted,
      isDayEnded,
      time,
      shiftLoggedHours,
      isStartingShiftEntry,
      isFetchingLocation,
      lessThanEarlyCapMinutesUntilStart,
      startShiftEntry,
      endShiftEntry,
      isEndingShiftEntry,
      isEndingDay,
      isScheduledForToday,
      selectedDate,
      shiftSelectedLoggedHours,
      endDay,
      isLastEntryLogged: canCompleteDay,
      currentType
    }
  );
};
