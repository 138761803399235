"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserShiftHandler } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import _, { keyBy } from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import { v4 } from "uuid";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as LargeSize } from "@/images/dashboard-icons/LargeSize.svg";
import { ReactComponent as Minus } from "@/images/dashboard-icons/Minus.svg";
import { ReactComponent as Plus } from "@/images/dashboard-icons/Plus.svg";
import { ReactComponent as SmallSize } from "@/images/dashboard-icons/SmallSize.svg";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { DashboardLayoutLoader } from "@/v2/feature/dashboard/components/dashboard-layout-loader.component";
import { DashboardAPI, DashboardEndpoints } from "@/v2/feature/dashboard/dashboard.api";
import "@/v2/feature/dashboard/dashboard.scss";
import { editGridLayout, getStringArrayInOrder } from "@/v2/feature/dashboard/dashboard.util";
import "@/v2/feature/dashboard/features/dashboard-edit-mode.scss";
import { UserAttendance } from "@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.section";
import { UserCalendarVersionBig } from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-big.section";
import { UserCalendarVersionSmall } from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-small.section";
import { DashboardUserProfile } from "@/v2/feature/dashboard/features/sections/user-profile-widget/dashboard-user-profile.section";
import { UserTimePlannerBig } from "@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-big.section";
import { UserTimePlannerSmall } from "@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-small.section";
import { UserTodosVersionBig } from "@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-big.section";
import { UserTodosVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-small.section";
import {
  AddWidgetConfigurationByType,
  AddWidgetType,
  AllWidgetConfiguration,
  WidgetTypes
} from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { iconSize } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
const ResponsiveGridLayout = WidthProvider(Responsive);
export const DashboardEditMode = ({
  setEdit,
  refreshWidgetData,
  userConfig,
  refreshConfig,
  configLoading,
  configValidating
}) => {
  const { polyglot } = usePolyglot();
  const [showDebounce, setShowDebounce] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowDebounce(true);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return /* @__PURE__ */ jsx(
    "div",
    {
      onClick: (e) => __async(void 0, null, function* () {
        setEdit(false);
        yield refreshWidgetData == null ? void 0 : refreshWidgetData();
        e.stopPropagation();
      }),
      style: {
        transition: "all 0.3s ease",
        transitionProperty: "opacity, top",
        opacity: showDebounce ? 1 : 0,
        top: showDebounce ? 0 : 500,
        position: "absolute",
        left: 0,
        background: "#2F2F2F66",
        width: "100%",
        height: "100vh",
        zIndex: 3,
        overflowY: "auto",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)"
      },
      children: /* @__PURE__ */ jsx(
        "div",
        {
          style: {
            marginTop: "20px",
            position: "relative"
          },
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [
                /* @__PURE__ */ jsx("div", { className: "grid-width", style: { display: "flex", justifyContent: "flex-start" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.white, marginLeft: "20px" }, children: polyglot.t("DashboardEditMode.edit") }) }),
                /* @__PURE__ */ jsx(DashboardLayoutLoader, { userConfig })
              ] }),
              children: /* @__PURE__ */ jsx(
                DashboardEditLayout,
                {
                  userConfig,
                  refreshConfig,
                  configLoading,
                  configValidating
                }
              )
            }
          )
        }
      )
    }
  );
};
const DashboardEditLayout = ({
  userConfig,
  refreshConfig,
  configLoading,
  configValidating
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const { data: userWidget, mutate: refreshAllWidgetData } = useApiClient(DashboardEndpoints.getAllWidgets());
  const [isUpdating, setIsUpdating] = useState(false);
  const refreshWidgetData = useCallback(() => __async(void 0, null, function* () {
    if (refreshAllWidgetData) yield refreshAllWidgetData();
  }), [refreshAllWidgetData]);
  const { order, preference } = userConfig != null ? userConfig : {};
  const { data: allWidgetData } = userWidget != null ? userWidget : {};
  const allWidgetDataLookup = useMemo(() => {
    return keyBy(allWidgetData, "type");
  }, [allWidgetData]);
  let editPreferenceIdLookupKey = useMemo(() => {
    return keyBy(preference, "id");
  }, [preference]);
  const addPreferenceIdLookup = useMemo(() => {
    return keyBy(AddWidgetConfigurationByType, "type");
  }, []);
  const allPreferenceIdLookup = useMemo(() => {
    return keyBy(AllWidgetConfiguration, "type");
  }, []);
  const [globalState] = useContext(GlobalContext);
  const user = globalState.user;
  const initialRows = useMemo(
    () => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
      return [
        ...allWidgetDataLookup[WidgetTypes.USER_PROFILE_SMALL] ? [
          {
            type: WidgetTypes.USER_PROFILE_SMALL,
            content: /* @__PURE__ */ jsx(
              DashboardUserProfile,
              {
                user,
                widgetData: (_a = allWidgetDataLookup[WidgetTypes.USER_PROFILE_SMALL]) == null ? void 0 : _a.profile,
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_TODOS_BIG] ? [
          {
            type: WidgetTypes.USER_TODOS_BIG,
            content: /* @__PURE__ */ jsx(
              UserTodosVersionBig,
              {
                todos: (_b = allWidgetDataLookup[WidgetTypes.USER_TODOS_BIG]) == null ? void 0 : _b.todosBig,
                refreshtodoList: refreshWidgetData,
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_TODOS_SMALL] ? [
          {
            type: WidgetTypes.USER_TODOS_SMALL,
            content: /* @__PURE__ */ jsx(
              UserTodosVersionSmall,
              {
                todos: (_c = allWidgetDataLookup[WidgetTypes.USER_TODOS_SMALL]) == null ? void 0 : _c.todosSmall,
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_CALENDAR_BIG] ? [
          {
            type: WidgetTypes.USER_CALENDAR_BIG,
            content: /* @__PURE__ */ jsx(
              UserCalendarVersionBig,
              {
                calendarBig: (_d = allWidgetDataLookup[WidgetTypes.USER_CALENDAR_BIG]) == null ? void 0 : _d.calendarBig,
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL] ? [
          {
            type: WidgetTypes.USER_CALENDAR_SMALL,
            content: /* @__PURE__ */ jsx(
              UserCalendarVersionSmall,
              {
                calendarSmall: (_e = allWidgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL]) == null ? void 0 : _e.calendarSmall,
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG] ? [
          {
            type: WidgetTypes.USER_ATTENDANCE_BIG,
            content: /* @__PURE__ */ jsx(
              UserAttendance,
              {
                attendanceData: (_f = allWidgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG]) == null ? void 0 : _f.attendance,
                readOnly: true,
                refreshWidgetData
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] ? [
          {
            type: WidgetTypes.USER_SHIFT_SMALL,
            content: /* @__PURE__ */ jsx(
              UserShiftHandler,
              {
                attendanceSchedule: (_h = (_g = allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _g.shift) == null ? void 0 : _h.attendanceSchedule,
                shift: (_j = (_i = allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _i.shift) == null ? void 0 : _j.todayShift,
                userSiteId: (_m = (_l = (_k = allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _k.shift) == null ? void 0 : _l.userSite) == null ? void 0 : _m.id,
                currentWeekAttendanceEntries: (_o = (_n = allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _n.shift) == null ? void 0 : _o.currentWeekAttendanceEntries,
                refresh: refreshWidgetData,
                mode: "widget",
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL] ? [
          {
            type: WidgetTypes.USER_TIME_PLANNER_SMALL,
            content: /* @__PURE__ */ jsx(
              UserTimePlannerSmall,
              {
                userBalance: (_p = allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL]) == null ? void 0 : _p.balancesSmall,
                readOnly: true
              }
            )
          }
        ] : [],
        ...allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG] ? [
          {
            type: WidgetTypes.USER_TIME_PLANNER_BIG,
            content: /* @__PURE__ */ jsx(
              UserTimePlannerBig,
              {
                userBalance: (_q = allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG]) == null ? void 0 : _q.balancesBig,
                readOnly: true
              }
            )
          }
        ] : []
      ];
    },
    [allWidgetDataLookup, user, refreshWidgetData]
  );
  const [editLayouts, setEditLayouts] = useState({ lg: editGridLayout(order, editPreferenceIdLookupKey) });
  const [editPreferenceIdLookup, setEditPreferenceIdLookup] = useState(editPreferenceIdLookupKey);
  const generateAddLayout = useCallback(() => {
    let x = 0;
    let y = 0;
    let countY = 0;
    return AddWidgetType.map((widget) => {
      const widgetDetails = addPreferenceIdLookup[widget];
      const w = (widgetDetails == null ? void 0 : widgetDetails.size) === "small" ? 4 : 8;
      if (x + w > 12) {
        x = 0;
        y += countY;
        countY = 0;
      }
      const layout = {
        x,
        y,
        w,
        h: 1,
        i: widget,
        static: widgetDetails.isStatic,
        isBounded: true,
        isResizable: false
      };
      x += w;
      countY += w;
      return layout;
    });
  }, [addPreferenceIdLookup]);
  const addLayouts = useMemo(() => {
    return { lg: generateAddLayout() };
  }, [generateAddLayout]);
  const onLayoutChange = useCallback(
    (currentLayout) => __async(void 0, null, function* () {
      try {
        setIsUpdating(true);
        const newOrder = getStringArrayInOrder(currentLayout);
        const newPrefence = Object.keys(editPreferenceIdLookup).map((key) => {
          return __spreadValues({}, editPreferenceIdLookup[key]);
        });
        const update = { order: newOrder, preference: newPrefence };
        setEditLayouts({ lg: editGridLayout(newOrder, keyBy(newPrefence, "id")) });
        yield DashboardAPI.updateWidgetSettings(update);
        refreshConfig == null ? void 0 : refreshConfig();
      } catch (error) {
        showMessage(
          polyglot.t("DashboardEditLayout.errorMessages.update", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setIsUpdating(false);
      }
    }),
    [polyglot, editPreferenceIdLookup, refreshConfig, showMessage]
  );
  const generateEditDOM = () => {
    return _.map(editLayouts.lg, function(l, i) {
      var _a;
      const widget = editPreferenceIdLookup[l.i];
      const type = widget.type;
      const component = (_a = initialRows.find((row) => row.type === type)) == null ? void 0 : _a.content;
      return /* @__PURE__ */ jsx(
        "div",
        {
          id: `edit-widget-${i}`,
          style: {
            position: "relative"
          },
          onClick: (e) => {
            e.stopPropagation();
          },
          children: /* @__PURE__ */ jsxs("div", { children: [
            /* @__PURE__ */ jsxs(
              "div",
              {
                style: {
                  position: "absolute",
                  top: 0,
                  right: -10,
                  zIndex: 2
                },
                children: [
                  /* @__PURE__ */ jsx(
                    "button",
                    {
                      className: "widget-short-button",
                      style: {
                        background: themeColors.white,
                        visibility: widget.isResizable ? "visible" : "hidden"
                      },
                      disabled: configLoading || configValidating || isUpdating,
                      onClick: () => {
                        if (type.includes("BIG")) {
                          editPreferenceIdLookup[l.i] = __spreadValues(__spreadValues({}, widget), allPreferenceIdLookup[type.replace("BIG", "SMALL")]);
                          setEditPreferenceIdLookup(editPreferenceIdLookup);
                          const newLayout = { lg: editGridLayout(order, editPreferenceIdLookup) };
                          setEditLayouts(newLayout);
                        }
                        if (type.includes("SMALL")) {
                          editPreferenceIdLookup[l.i] = __spreadValues(__spreadValues({}, widget), allPreferenceIdLookup[type.replace("SMALL", "BIG")]);
                          setEditPreferenceIdLookup(editPreferenceIdLookup);
                          const newLayout = { lg: editGridLayout(order, editPreferenceIdLookup) };
                          setEditLayouts(newLayout);
                        }
                      },
                      children: type.includes("BIG") ? /* @__PURE__ */ jsx(SmallSize, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })) : /* @__PURE__ */ jsx(LargeSize, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey }))
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    "button",
                    {
                      className: "widget-short-button",
                      style: {
                        background: themeColors.white,
                        visibility: widget.isDeletable ? "visible" : "hidden",
                        marginTop: spacing.m10
                      },
                      disabled: configLoading || configValidating || isUpdating,
                      onClick: () => {
                        const newEditPreferenceIdLookup = __spreadValues({}, editPreferenceIdLookup);
                        delete newEditPreferenceIdLookup[l.i];
                        setEditPreferenceIdLookup(newEditPreferenceIdLookup);
                        const newLayout = editLayouts.lg.filter((item) => item.i !== l.i);
                        setEditLayouts({ lg: newLayout });
                      },
                      children: /* @__PURE__ */ jsx(Minus, __spreadValues({}, iconSize))
                    }
                  )
                ]
              }
            ),
            component
          ] })
        },
        l.i
      );
    });
  };
  const generateAddDOM = () => {
    return _.map(addLayouts.lg, function(l, i) {
      var _a;
      const widget = addPreferenceIdLookup[l.i];
      const component = (_a = initialRows.find((row) => row.type === widget.type)) == null ? void 0 : _a.content;
      return /* @__PURE__ */ jsx(
        "div",
        {
          id: `add-widget-${i}`,
          style: {
            position: "relative"
          },
          onClick: (e) => {
            e.stopPropagation();
          },
          children: /* @__PURE__ */ jsxs("div", { children: [
            /* @__PURE__ */ jsx(
              "button",
              {
                className: "widget-short-button",
                style: {
                  position: "absolute",
                  visibility: widget.isResizable ? "visible" : "hidden",
                  top: 0,
                  right: -10,
                  background: themeColors.Green,
                  fill: themeColors.white,
                  zIndex: 2
                },
                disabled: configLoading || configValidating || isUpdating,
                onClick: () => __async(this, null, function* () {
                  const widgetToAdd = __spreadValues({ id: v4() }, widget);
                  const widgetLayout = {
                    x: 0,
                    y: Math.max(...editLayouts.lg.map((layout) => layout.y)) + 1,
                    w: (widgetToAdd == null ? void 0 : widgetToAdd.size) === "small" ? 4 : 8,
                    h: 1,
                    i: widgetToAdd.id,
                    static: widgetToAdd.isStatic,
                    isBounded: true,
                    isResizable: false
                  };
                  setEditPreferenceIdLookup((prevLookup) => __spreadProps(__spreadValues({}, prevLookup), {
                    [widgetToAdd.id]: {
                      id: widgetToAdd.id,
                      type: widgetToAdd.type,
                      size: widget == null ? void 0 : widget.size,
                      isStatic: widget.isStatic,
                      isResizable: widget.isResizable,
                      isDeletable: widget.isDeletable
                    }
                  }));
                  setEditLayouts((prevLayouts) => __spreadProps(__spreadValues({}, prevLayouts), {
                    lg: [...prevLayouts.lg, widgetLayout]
                  }));
                }),
                children: /* @__PURE__ */ jsx(Plus, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.white }))
              }
            ),
            component
          ] })
        },
        l.i
      );
    });
  };
  return /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx("div", { className: "grid-width", style: { display: "flex", justifyContent: "flex-start" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.white, marginLeft: "20px" }, children: polyglot.t("DashboardEditLayout.edit") }) }),
    /* @__PURE__ */ jsx(
      ResponsiveGridLayout,
      {
        className: "layout grid-width",
        layouts: editLayouts,
        breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        cols: { lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 },
        measureBeforeMount: true,
        rowHeight: 280,
        useCSSTransforms: false,
        isDroppable: true,
        onLayoutChange,
        margin: [30, 30],
        children: generateEditDOM()
      }
    ),
    /* @__PURE__ */ jsx("div", { className: "grid-width", style: { display: "flex", justifyContent: "flex-start" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.white, marginLeft: "20px" }, children: polyglot.t("DashboardEditLayout.add") }) }),
    /* @__PURE__ */ jsx(
      ResponsiveGridLayout,
      {
        className: "layout grid-width",
        layouts: { lg: generateAddLayout() },
        breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        cols: { lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 },
        measureBeforeMount: false,
        rowHeight: 280,
        isDraggable: false,
        isResizable: false,
        useCSSTransforms: true,
        preventCollision: true,
        margin: [30, 30],
        children: generateAddDOM()
      }
    )
  ] });
};
