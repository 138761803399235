"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE } from "@/lib/routes";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import {
  companySignatureRequired,
  recipientSignatureRequired,
  signatoriesRequiredForContract
} from "@/v2/feature/document-new/document-new.util";
import { stringToDownloadFile, stringToPreviewFile } from "@/v2/feature/documents/documents.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const useDocumentActions = ({
  setLoadingData,
  setIsEditOpen,
  setIsUploadOpen,
  setIsViewOpen,
  setDocumentsRecordsFromServer,
  fileURLRef,
  setDocData,
  setIsPreviewOpen,
  setError,
  setMissingFields,
  setSelectedDocument,
  setOpenMissingFieldContractModal,
  loggedInUserId
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const onEdit = useCallback(
    (document) => {
      setIsEditOpen == null ? void 0 : setIsEditOpen(true);
      setSelectedDocument(document);
    },
    [setIsEditOpen, setSelectedDocument]
  );
  const onDelete = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        yield DocumentNewAPI.deleteDocument(document.id);
        showMessage("Successfully deleted the document", "success");
        setLoadingData == null ? void 0 : setLoadingData(true);
        setDocumentsRecordsFromServer().finally(() => {
          setLoadingData == null ? void 0 : setLoadingData(false);
        });
        setIsViewOpen == null ? void 0 : setIsViewOpen(false);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [setDocumentsRecordsFromServer, polyglot, setIsViewOpen, showMessage, setLoadingData]
  );
  const onUpload = useCallback(
    (document) => {
      setIsUploadOpen == null ? void 0 : setIsUploadOpen(true);
      setSelectedDocument(document);
    },
    [setIsUploadOpen, setSelectedDocument]
  );
  const onCancel = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        yield DocumentNewAPI.cancelDocument(document.id);
        showMessage("Successfully cancelled the request", "success");
        setLoadingData == null ? void 0 : setLoadingData(true);
        setDocumentsRecordsFromServer().finally(() => {
          setLoadingData == null ? void 0 : setLoadingData(false);
        });
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [polyglot, showMessage, setDocumentsRecordsFromServer, setLoadingData]
  );
  const onDownload = useCallback(
    (document, type) => __async(void 0, null, function* () {
      try {
        const response = yield DocumentNewAPI.fetchFileDataById(document.id, type);
        stringToDownloadFile(response.data.data, response.contentType, response.extension, document.name);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [polyglot, showMessage]
  );
  const onPreview = useCallback(
    (document, type) => __async(void 0, null, function* () {
      try {
        const response = yield DocumentNewAPI.fetchFileDataById(document.id, type);
        const docDataResponse = yield stringToPreviewFile(
          response.data.data,
          response.contentType,
          response.extension,
          document.name
        );
        setDocData(docDataResponse);
        if (docDataResponse && docDataResponse.uri) fileURLRef.current = docDataResponse.uri;
        setIsPreviewOpen(true);
        setError(null);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [polyglot, showMessage, fileURLRef, setDocData, setIsPreviewOpen, setError]
  );
  const onRemind = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        yield DocumentNewAPI.sendReminder(document.id);
        showMessage("Successfully sent a reminder", "success");
        setIsViewOpen == null ? void 0 : setIsViewOpen(false);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [polyglot, setIsViewOpen, showMessage]
  );
  const onSign = useCallback(
    (document) => __async(void 0, null, function* () {
      var _a, _b, _c, _d, _e, _f;
      try {
        if (!document.contractId || !document.contract) return;
        const { contract } = document;
        const signatoriesRequired = ((_a = document.contract) == null ? void 0 : _a.templateText) ? signatoriesRequiredForContract(document.contract.templateText) : null;
        const isRecipient = loggedInUserId === contract.recipient;
        const isCompanySignatory = loggedInUserId === contract.companySignatory;
        const hasContract = document.contractId;
        if (hasContract && isRecipient) {
          const missingFields = yield ContractAPI.getEmployeeFieldsForContractById(document.contractId);
          const hasMissingFields = (_b = Object.values(missingFields)) == null ? void 0 : _b.some((v) => v === true);
          if (hasMissingFields) {
            setMissingFields(missingFields);
            setSelectedDocument(document);
            setOpenMissingFieldContractModal(true);
          }
          if (recipientSignatureRequired(document, signatoriesRequired) && !contract.recipientSignatureTimestamp) {
            routerHistory.push(
              generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
                templateId: contract.templateId,
                contractId: contract.id,
                userId: contract.recipient
              })
            );
          }
        } else if (contract.id && isCompanySignatory) {
          if (companySignatureRequired(document, signatoriesRequired) && !contract.companySignatureTimestamp) {
            const stateToPassThrough = {
              companySignatoryUserId: (_c = document.contract) == null ? void 0 : _c.companySignatory
            };
            routerHistory.push(
              generatePath(TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, {
                templateId: (_d = document.contract) == null ? void 0 : _d.templateId,
                contractId: (_e = document.contract) == null ? void 0 : _e.id,
                userId: (_f = document.contract) == null ? void 0 : _f.companySignatory
              }),
              stateToPassThrough
            );
          }
        }
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [
      polyglot,
      showMessage,
      routerHistory,
      loggedInUserId,
      setMissingFields,
      setSelectedDocument,
      setOpenMissingFieldContractModal
    ]
  );
  return { onEdit, onDelete, onCancel, onDownload, onPreview, onRemind, onSign, onUpload };
};
