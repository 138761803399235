"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewAPI } from "@/v2/feature/growth/reviews/api-client/review.api";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { EntryState } from "@/v2/feature/growth/reviews/interfaces/review-entry.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PublishReviewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  results,
  refreshResults
}) => {
  if (!results) return null;
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(PublishReviewModalContent, { results, refreshResults, onClose }) });
};
const PublishReviewModalContent = ({
  results,
  refreshResults,
  onClose
}) => {
  var _a, _b;
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const handleShareResult = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield ReviewAPI.publishReviewResult(results == null ? void 0 : results.reviewId, results.cycleId);
      refreshResults == null ? void 0 : refreshResults();
      onClose();
      showMessage(`${polyglot.t("PublishReviewModalContent.successMessages.publish")}`, "success");
    } catch (error) {
      showMessage(
        `${polyglot.t("PublishReviewModalContent.errorMessages.publish")}. ${nestErrorMessage(error)}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [results, onClose, refreshResults, showMessage, polyglot]);
  const notSubmittedEntries = useMemo(
    () => results.reviewerScoreSummary.filter((r) => r.entryState !== EntryState.Submitted),
    [results]
  );
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PublishReviewModalContent.title", {
      name: (_b = (_a = getCachedUserById(results.revieweeId)) == null ? void 0 : _a.firstName) != null ? _b : ""
    }) }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      polyglot.t("PublishReviewModalContent.description"),
      " "
    ] }),
    notSubmittedEntries.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { marginTop: spacing.s1 }, children: polyglot.t("PublishReviewModalContent.pendingReviewsDiscarded") }),
      notSubmittedEntries.map(
        (entry) => {
          var _a2;
          return !entry.entryState && entry.type === ReviewerTypes.Peer && results.visibilitySettings.hidePeerAuthor ? /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            "\u2022 ",
            polyglot.t("PublishReviewModalContent.hiddenAuthors")
          ] }) : /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            "\u2022 ",
            (_a2 = getCachedUserById(entry.reviewerId)) == null ? void 0 : _a2.displayName
          ] });
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PublishReviewModalContent.commentsReview") }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          onClick: () => handleShareResult(),
          colorVariant: "primary",
          name: polyglot.t("PublishReviewModalContent.publishButton"),
          loading,
          fullWidth: true
        }
      )
    ] })
  ] });
};
