"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Switch } from "react-router-dom";
import { useGoalsReach } from "./hooks/use-goals-reach.hook";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import {
  GROWTH_COMPANY_GOALS_ROUTE,
  GROWTH_COMPANY_GOAL_ROUTE,
  GROWTH_ME_GOALS_ROUTE,
  GROWTH_ME_GOAL_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { GoalsCompanyRouter } from "@/v2/feature/growth/goals/goals-company.router";
import { GoalsMeRouter } from "@/v2/feature/growth/goals/goals-me.router";
import { ReachType } from "@/v2/interfaces/general.interface";
export const GoalsRouter = () => {
  const goalsReach = useGoalsReach();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["goals:all", "goals"], path: [GROWTH_COMPANY_GOAL_ROUTE, GROWTH_ME_GOAL_ROUTE], children: /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Goals",
        pageConfig: [],
        backPath: goalsReach === ReachType.Company ? GROWTH_COMPANY_GOALS_ROUTE : GROWTH_ME_GOALS_ROUTE,
        showBack: true,
        type: "Domain"
      }
    ) }),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["goals:all"], path: GROWTH_COMPANY_GOALS_ROUTE, children: /* @__PURE__ */ jsx(GoalsCompanyRouter, {}) }),
      /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["goals"], path: [GROWTH_ME_GOALS_ROUTE], children: /* @__PURE__ */ jsx(GoalsMeRouter, {}) })
    ] })
  ] });
};
