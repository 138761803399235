"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { ContractorInvoiceModal } from "@v2/feature/payments/pages/components/contractor-invoice-modal.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { ContractorInvoiceEndpoints } from "@/api-client/contractor-invoice-api";
import useScopes from "@/hooks/scopes.hook";
export const InvoiceRequestActionItem = ({
  selectedRow,
  refresh,
  afterClose
}) => {
  const { data: invoice } = useApiClient(
    selectedRow ? ContractorInvoiceEndpoints.getUserInvoiceById(selectedRow.userId, selectedRow.id) : null,
    { suspense: false }
  );
  const { hasScopes, getScopesContext } = useScopes();
  const hasAdminScope = useMemo(() => {
    return invoice ? hasScopes(["invoices:all"], getScopesContext({ userId: invoice.from })) : false;
  }, [invoice, hasScopes, getScopesContext]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  useEffect(() => {
    setIsViewOpen(Boolean(selectedRow));
  }, [selectedRow]);
  return invoice ? /* @__PURE__ */ jsx(
    ContractorInvoiceModal,
    {
      isOpen: isViewOpen,
      setIsOpen: setIsViewOpen,
      selectedInvoice: invoice,
      onClose: () => {
        setIsViewOpen(false);
      },
      afterClose,
      onActionPerformed: refresh,
      currentUserIsAdmin: hasAdminScope
    }
  ) : null;
};
