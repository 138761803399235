"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { keyBy } from "lodash";
import { GoalOwnerType } from "../goal.interface";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { ReactComponent as CaseIcon } from "@/images/icons/case.svg";
import { ChipComponentWithIcon } from "@/v2/components/chip/chip-with-icon.component";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { KeyResultOwnerType } from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const ownerChipStyles = {
  gap: "4px",
  paddingTop: 0,
  paddingBottom: 0
};
export const OwnerChip = ({ ownerIds, ownerType, multiUserAvatar, departmentId = null }) => {
  const { polyglot } = usePolyglot();
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartmentsOptions(), {
    suspense: false,
    dedupingInterval: 3e4
  });
  const departmentsById = useMemo(() => {
    return keyBy(departments, "id");
  }, [departments]);
  const isDepartmentOwned = useMemo(
    () => [GoalOwnerType.Department, KeyResultOwnerType.Department].includes(ownerType),
    [ownerType]
  );
  const departmentName = useMemo(() => {
    var _a;
    return departmentId ? (_a = departmentsById[departmentId]) == null ? void 0 : _a.name : "n/a";
  }, [
    departmentId,
    departmentsById
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    [
      GoalOwnerType.Department,
      GoalOwnerType.Company,
      KeyResultOwnerType.Company,
      KeyResultOwnerType.Department
    ].includes(ownerType) ? /* @__PURE__ */ jsx(
      ChipComponentWithIcon,
      {
        name: isDepartmentOwned ? departmentName : polyglot.t("GoalOwnerChip.company"),
        backgroundColor: "white",
        border: "middleGrey2",
        icon: /* @__PURE__ */ jsx(CaseIcon, { width: "16px" }),
        chipStyles: ownerChipStyles,
        textVariant: "title4",
        textColor: "TextPrimary"
      }
    ) : null,
    [GoalOwnerType.Individual, KeyResultOwnerType.Individual].includes(ownerType) ? /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: /* @__PURE__ */ jsx(
          MultiUserAvatar,
          __spreadValues({
            userIds: ownerIds,
            avatarSize: "xs16",
            textVariant: "captionSmall",
            avatarNameVariant: "captionBold"
          }, multiUserAvatar)
        ),
        backgroundColor: "white",
        border: "middleGrey2"
      }
    ) : null
  ] });
};
