"use strict";
export var PayScheduleEnum = /* @__PURE__ */ ((PayScheduleEnum2) => {
  PayScheduleEnum2["Monthly"] = "Monthly";
  PayScheduleEnum2["Weekly"] = "Weekly";
  return PayScheduleEnum2;
})(PayScheduleEnum || {});
export var SalaryBasisEnum = /* @__PURE__ */ ((SalaryBasisEnum2) => {
  SalaryBasisEnum2["Annual"] = "Annual";
  SalaryBasisEnum2["Monthly"] = "Monthly";
  SalaryBasisEnum2["Weekly"] = "Weekly";
  SalaryBasisEnum2["Daily"] = "Daily";
  SalaryBasisEnum2["Hourly"] = "Hourly";
  return SalaryBasisEnum2;
})(SalaryBasisEnum || {});
export var PayTypeEnum = /* @__PURE__ */ ((PayTypeEnum2) => {
  PayTypeEnum2["Salaried"] = "Salaried";
  PayTypeEnum2["Hourly"] = "Hourly";
  return PayTypeEnum2;
})(PayTypeEnum || {});
