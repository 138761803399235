"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { CalculationMethodMonth1, CalculationMethodStandard } from "@/lib/payroll";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { toTitleCase } from "@/v2/util/string.util";
const iconSize = { width: 14, height: 14 };
const identity = (row) => row;
const PAYROLL_ALL_SCOPE = ["payroll:all"];
export const useTaxUKTable = ({ onEditRowClick, payroll, userId }) => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const formatStudentLoans = useCallback((record) => {
    const combinedLoans = [];
    if (record.studentLoan) combinedLoans.push(`${record.studentLoan}`);
    if (record.postgradLoan) combinedLoans.push(`Postgraduate`);
    return combinedLoans.length > 0 ? combinedLoans.join(", ") : void 0;
  }, []);
  const formatDirectorshipStint = useCallback((record) => {
    let directorshipDates = "";
    try {
      if ((record == null ? void 0 : record.directorFrom) && (record == null ? void 0 : record.directorTo))
        directorshipDates = `(${dateAPItoDisplay(record == null ? void 0 : record.directorFrom)} - ${dateAPItoDisplay(record == null ? void 0 : record.directorTo)})`;
      if ((record == null ? void 0 : record.directorFrom) && !(record == null ? void 0 : record.directorTo))
        directorshipDates = `(${dateAPItoDisplay(record == null ? void 0 : record.directorFrom)} - ?)`;
    } catch (error) {
      console.error("Error formatting directorship dates:", error);
      directorshipDates = "(Invalid Dates)";
    }
    return directorshipDates;
  }, []);
  const columnData = useMemo(
    () => [
      {
        header: () => "Payroll ID",
        accessorFn: identity,
        id: "payroll-id",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.payrollId })
      },
      {
        header: () => "Tax code",
        accessorFn: identity,
        id: "tax-code",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.taxCode })
      },
      {
        header: () => "Calculation method",
        accessorFn: identity,
        id: "week1",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.week1Month1 ? CalculationMethodMonth1 : CalculationMethodStandard })
      },
      {
        header: () => "Starter declaration",
        accessorFn: identity,
        id: "starter-declaration",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.starterDeclaration })
      },
      {
        header: () => "NI category",
        accessorFn: identity,
        id: "ni-category",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.niTable })
      },
      {
        header: () => "NINO",
        accessorFn: identity,
        id: "nino",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.niNumber })
      },
      {
        header: () => "Student loan",
        accessorFn: identity,
        id: "student-loan",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: formatStudentLoans(original) })
      },
      {
        header: () => "Sex",
        accessorFn: identity,
        id: "legal-gender",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: toTitleCase((_a = original.legalGender) != null ? _a : "") });
        }
      },
      {
        header: () => "Directorship",
        accessorFn: identity,
        id: "directorship",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.isDirector ? `Yes ${formatDirectorshipStint(original)}` : "No" })
      },
      {
        header: () => "Last edited by",
        accessorFn: identity,
        id: "last-edit-user",
        enableSorting: false,
        cell: ({ row: { original } }) => original.updatedBy ? /* @__PURE__ */ jsx(UserAvatar, { userId: original.updatedBy, size: "xxsmall" }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Last edited on",
        accessorFn: identity,
        id: "last-edit-date",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(DateLabelComponent, { date: original.updatedAt })
      },
      {
        header: () => "Reason",
        accessorFn: identity,
        id: "change-reason",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(Typography, { title: (_a = original.changeReason) != null ? _a : "", sx: __spreadProps(__spreadValues({}, themeFonts.caption), { whiteSpace: "nowrap" }), children: original.changeReason });
        }
      },
      {
        header: () => "Effective date",
        accessorFn: identity,
        id: "effective-date",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        header: () => "",
        accessorFn: identity,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(ScopesControl, { scopes: PAYROLL_ALL_SCOPE, context: scopesContext, children: /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: "edit",
            onClick: () => {
              onEditRowClick(original.record);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [formatDirectorshipStint, formatStudentLoans, onEditRowClick, scopesContext]
  );
  if (!payroll) return [void 0, void 0];
  const tableRowData = payroll.entries.reduce((result, entry) => {
    const { payrollValues, changeReason, effectiveDate, payrollId, updatedAt, updatedBy } = entry;
    if (payrollValues) {
      result.push(__spreadValues({
        record: entry,
        changeReason,
        effectiveDate,
        payrollId,
        updatedAt,
        updatedBy
      }, payrollValues));
    }
    return result;
  }, []);
  return [tableRowData, columnData];
};
