"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { RefreshIconButton } from "@v2/feature/reports-analytics/components/refresh-icon-button.component";
import { PeopleAnalyticsAPI } from "@v2/feature/reports-analytics/features/people-analytics/people-analytics.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { ReactComponent as Loader } from "@/images/side-bar-icons/Loader.svg";
import { ChartDisplay } from "@/v2/components/charts/components/chart-display.component";
import { ChartWrapper } from "@/v2/components/charts/components/chart-wrapper.component";
import { ChipComponentWithIcon } from "@/v2/components/chip/chip-with-icon.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import {
  PeopleAnalyticsChart,
  PeopleAnalyticsType
} from "@/v2/feature/reports-analytics/features/people-analytics/people-analytics.interface";
import { useDynamicUpdate } from "@/v2/feature/reports-analytics/hooks/use-dynamic-update.hook";
import {
  useSequentialDataLoader
} from "@/v2/feature/reports-analytics/hooks/use-sequential-data-loader.hook";
import { formatDays } from "@/v2/feature/reports-analytics/reports-analytics.util";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PeopleAnalyticsPage = () => {
  var _a, _b, _c, _d, _e, _f, _g;
  const fetchErrorPolyglotKey = "PeopleAnalyticsPage.errorMessages.fetch";
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();
  const { data, loadData, refreshCache, clearData } = useSequentialDataLoader();
  const fetchGroups = useMemo(
    () => [
      [{ key: "cacheCreationDate", fetchFunction: PeopleAnalyticsAPI.getCacheCreationDate }],
      [
        {
          key: "avgTurnoverRateChart",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.TURNOVER, PeopleAnalyticsType.AVG)
        },
        {
          key: "avgInternalMobilityChart",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.INTERNAL_MOBILITY, PeopleAnalyticsType.AVG)
        },
        {
          key: "avgRetentionRateChart",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.RETENTION, PeopleAnalyticsType.AVG)
        }
      ],
      [
        {
          key: "avgTenure",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.TENURE, PeopleAnalyticsType.AVG)
        },
        {
          key: "workforceMetricsOverTime",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.WORKFORCE_METRICS, PeopleAnalyticsType.OVER_TIME)
        },
        {
          key: "turnoverRateOverTime",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.TURNOVER, PeopleAnalyticsType.OVER_TIME)
        }
      ],
      [
        {
          key: "internalMobilityOverTime",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.INTERNAL_MOBILITY, PeopleAnalyticsType.OVER_TIME)
        },
        {
          key: "retentionRateOverTime",
          fetchFunction: () => PeopleAnalyticsAPI.getChartData(PeopleAnalyticsChart.RETENTION, PeopleAnalyticsType.OVER_TIME)
        }
      ]
    ],
    []
  );
  useEffect(() => {
    loadData(fetchGroups, fetchErrorPolyglotKey);
  }, [loadData, fetchGroups, fetchErrorPolyglotKey]);
  const refreshData = useCallback(() => __async(void 0, null, function* () {
    clearData();
    yield refreshCache(PeopleAnalyticsAPI.refreshCache, fetchErrorPolyglotKey);
    loadData(fetchGroups, fetchErrorPolyglotKey);
  }), [clearData, refreshCache, loadData, fetchGroups, fetchErrorPolyglotKey]);
  const currentTimeLabel = useDynamicUpdate(data.cacheCreationDate);
  const avgTenureString = useMemo(() => formatDays(data.avgTenure, polyglot), [data.avgTenure, polyglot]);
  useEffect(() => {
    trackPage("People insights");
  }, []);
  const iconSize = { width: 12, height: 12 };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: polyglot.t("PeopleAnalyticsPage.title") }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, hideFooter: true, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: spacing.g4, mb: spacing.m16 }, children: [
        /* @__PURE__ */ jsx(
          ChipComponentWithIcon,
          {
            name: currentTimeLabel ? `${polyglot.t("PeopleAnalyticsPage.lastUpdated")}: ${currentTimeLabel}` : `${polyglot.t("General.loading")}...`,
            icon: data.cacheCreationDate ? /* @__PURE__ */ jsx(CheckCircle, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Loader, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            border: "background"
          }
        ),
        /* @__PURE__ */ jsx(RefreshIconButton, { effectiveDate: data.cacheCreationDate, onClick: refreshData })
      ] }),
      /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 2, children: [
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 3, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.turnoverRate"),
            subtitle: polyglot.t("PeopleAnalyticsPage.turnoverRateDesc"),
            hasTooltip: true,
            tooltipText: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: polyglot.t("PeopleAnalyticsPage.turnoverRateTooltip") }),
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_a = data.avgTurnoverRateChart) == null ? void 0 : _a.type,
                chartData: data.avgTurnoverRateChart,
                options: {
                  colors: [PRIMARY_CHART_COLOR[3]]
                }
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 3, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.internalMobility"),
            subtitle: polyglot.t("PeopleAnalyticsPage.internalMobilityDesc"),
            hasTooltip: true,
            tooltipText: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: polyglot.t("PeopleAnalyticsPage.internalMobilityTooltip") }),
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_b = data.avgInternalMobilityChart) == null ? void 0 : _b.type,
                chartData: data.avgInternalMobilityChart,
                options: {
                  colors: [PRIMARY_CHART_COLOR[4]]
                }
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 3, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.retentionRate"),
            subtitle: polyglot.t("PeopleAnalyticsPage.retentionRateDesc"),
            hasTooltip: true,
            tooltipText: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: polyglot.t("PeopleAnalyticsPage.retentionRateTooltip") }),
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_c = data.avgRetentionRateChart) == null ? void 0 : _c.type,
                chartData: data.avgRetentionRateChart,
                options: {
                  colors: [PRIMARY_CHART_COLOR[5]]
                }
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 3, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.averageTenure"),
            subtitle: polyglot.t("PeopleAnalyticsPage.averageTenureDesc"),
            hasTooltip: true,
            tooltipText: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: polyglot.t("PeopleAnalyticsPage.averageTenureTooltip") }),
            chart: avgTenureString ? /* @__PURE__ */ jsx(
              Box,
              {
                style: {
                  fontSize: "32px",
                  fontWeight: 300
                },
                children: avgTenureString
              }
            ) : /* @__PURE__ */ jsx(Box, { sx: { width: "100%", height: "100%" }, children: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
              }
            ) })
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 6, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.headcountOverTime"),
            subtitle: polyglot.t("PeopleAnalyticsPage.headcountOverTimeDesc"),
            wideChart: true,
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_d = data.workforceMetricsOverTime) == null ? void 0 : _d.type,
                chartData: data.workforceMetricsOverTime,
                options: {
                  colors: [PRIMARY_CHART_COLOR[0], PRIMARY_CHART_COLOR[1], SECONDARY_CHART_COLOR[0]]
                }
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 6, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.turnoverOverTime"),
            subtitle: polyglot.t("PeopleAnalyticsPage.turnoverOverTimeDesc"),
            wideChart: true,
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_e = data.turnoverRateOverTime) == null ? void 0 : _e.type,
                chartData: data.turnoverRateOverTime,
                options: {
                  colors: [PRIMARY_CHART_COLOR[3]],
                  percentage: {
                    enable: true
                  }
                }
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 6, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.internalMobilityOverTime"),
            subtitle: polyglot.t("PeopleAnalyticsPage.internalMobilityOverTimeDesc"),
            wideChart: true,
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_f = data.internalMobilityOverTime) == null ? void 0 : _f.type,
                chartData: data.internalMobilityOverTime,
                options: {
                  colors: [PRIMARY_CHART_COLOR[4]],
                  percentage: {
                    enable: true,
                    unboundedMax: true
                  }
                }
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 6, children: /* @__PURE__ */ jsx(
          ChartDisplay,
          {
            title: polyglot.t("PeopleAnalyticsPage.retentionOverTime"),
            subtitle: polyglot.t("PeopleAnalyticsPage.retentionOverTimeDesc"),
            wideChart: true,
            chart: /* @__PURE__ */ jsx(
              ChartWrapper,
              {
                type: (_g = data.retentionRateOverTime) == null ? void 0 : _g.type,
                chartData: data.retentionRateOverTime,
                options: {
                  colors: [PRIMARY_CHART_COLOR[5]],
                  percentage: {
                    enable: true
                  }
                }
              }
            )
          }
        ) })
      ] })
    ] })
  ] });
};
