"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Divider } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ReportsAlertMissingScopes } from "@v2/feature/reports/reports-advanced/components/reports-alert-missing-scopes.component";
import {
  FilterTypeOption,
  ReportSQLOperator
} from "@v2/feature/reports/reports.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { capitalize } from "lodash";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
export var ThisFilterOptions = /* @__PURE__ */ ((ThisFilterOptions2) => {
  ThisFilterOptions2["Day"] = "D";
  ThisFilterOptions2["Week"] = "W";
  ThisFilterOptions2["Month"] = "M";
  ThisFilterOptions2["Quarter"] = "Q";
  ThisFilterOptions2["Year"] = "Y";
  ThisFilterOptions2["WeekSoFar"] = "WsF";
  ThisFilterOptions2["MonthSoFar"] = "MsF";
  ThisFilterOptions2["QuarterSoFar"] = "QsF";
  ThisFilterOptions2["YearSoFar"] = "YsF";
  return ThisFilterOptions2;
})(ThisFilterOptions || {});
export var LastFilterOptions = /* @__PURE__ */ ((LastFilterOptions2) => {
  LastFilterOptions2["Last1D"] = "D1";
  LastFilterOptions2["Last2D"] = "D2";
  LastFilterOptions2["Last7D"] = "D7";
  LastFilterOptions2["Last14D"] = "D14";
  LastFilterOptions2["Last30D"] = "D30";
  LastFilterOptions2["Last60D"] = "D60";
  LastFilterOptions2["Last90D"] = "D90";
  LastFilterOptions2["Last365D"] = "D365";
  LastFilterOptions2["Week"] = "W";
  LastFilterOptions2["Month"] = "M";
  LastFilterOptions2["Quarter"] = "Q";
  LastFilterOptions2["Year"] = "Y";
  return LastFilterOptions2;
})(LastFilterOptions || {});
export var NextFilterOptions = /* @__PURE__ */ ((NextFilterOptions2) => {
  NextFilterOptions2["Next1D"] = "D1";
  NextFilterOptions2["Next2D"] = "D2";
  NextFilterOptions2["Next7D"] = "D7";
  NextFilterOptions2["Next14D"] = "D14";
  NextFilterOptions2["Next30D"] = "D30";
  NextFilterOptions2["Next60D"] = "D60";
  NextFilterOptions2["Next90D"] = "D90";
  NextFilterOptions2["Next365D"] = "D365";
  NextFilterOptions2["Week"] = "W";
  NextFilterOptions2["Month"] = "M";
  NextFilterOptions2["Quarter"] = "Q";
  NextFilterOptions2["Year"] = "Y";
  return NextFilterOptions2;
})(NextFilterOptions || {});
export const FiltersListSection = ({
  selectedFilters,
  setSelectedFilters,
  editFilter,
  reportFilters
}) => {
  const filters = useMemo(() => {
    return Object.keys(selectedFilters).flatMap((stub) => {
      return Object.keys(selectedFilters[stub]).flatMap((filterKey) => {
        var _a;
        return ((_a = selectedFilters[stub][filterKey]) != null ? _a : []).map((filterValue) => {
          var _a2, _b, _c, _d, _e, _f, _g, _h, _i;
          const filterCat = reportFilters.find((f) => f.stub === stub);
          if (!(filterCat == null ? void 0 : filterCat.filters) || !filterCat.filters[filterKey])
            return {
              stub,
              filterKey,
              category: (_a2 = filterCat == null ? void 0 : filterCat.category) != null ? _a2 : "",
              label: `Undefined filter (${(_b = filterCat == null ? void 0 : filterCat.category) != null ? _b : "Please delete this filter and try again"})`,
              op: filterValue.op,
              value: filterValue.value,
              valueLabels: [""],
              type: FilterTypeOption.string,
              disabled: Boolean(filterValue.isMandatory),
              missesScopes: true
            };
          const valueLabels = (
            // if option && op is '='
            (filterCat == null ? void 0 : filterCat.filters[filterKey].type) && [FilterTypeOption.intOption, FilterTypeOption.stringOption].includes(filterCat == null ? void 0 : filterCat.filters[filterKey].type) && filterValue.op === ReportSQLOperator.eq ? [
              (_f = (_e = (_d = (_c = filterCat == null ? void 0 : filterCat.filters[filterKey]) == null ? void 0 : _c.options) == null ? void 0 : _d.find((option) => option.value === filterValue.value)) == null ? void 0 : _e.label) != null ? _f : String(filterValue.value)
            ] : (
              // if operator is between
              filterValue.op === ReportSQLOperator.between || filterValue.op === ReportSQLOperator.contains || filterValue.op === ReportSQLOperator.notContains ? filterValue.value.map(String) : (
                // else if the value is object (op = 'in')
                typeof filterValue.value === "object" ? filterValue.value.map(
                  (v) => {
                    var _a3, _b2, _c2, _d2;
                    return capitalize(
                      (_d2 = (_c2 = (_b2 = (_a3 = filterCat == null ? void 0 : filterCat.filters[filterKey]) == null ? void 0 : _a3.options) == null ? void 0 : _b2.find((option) => option.value === v)) == null ? void 0 : _c2.label) != null ? _d2 : String(v)
                    );
                  }
                ) : []
              )
            )
          );
          return {
            stub,
            filterKey,
            category: (_g = filterCat == null ? void 0 : filterCat.category) != null ? _g : "",
            label: (_h = filterCat == null ? void 0 : filterCat.filters[filterKey].label) != null ? _h : "",
            op: filterValue.op,
            value: filterValue.value,
            valueLabels,
            type: (_i = filterCat == null ? void 0 : filterCat.filters[filterKey].type) != null ? _i : typeof filterValue.value === "number" ? FilterTypeOption.number : FilterTypeOption.string,
            disabled: Boolean(filterValue.isMandatory)
          };
        });
      });
    });
  }, [reportFilters, selectedFilters]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.s1,
        overflowY: "hidden"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Applied" }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "100%",
              overflowY: "auto",
              scrollbarWidth: "thin",
              paddingRight: "4px"
            },
            children: filters.map((filter, index) => /* @__PURE__ */ jsx(
              FilterDisplay,
              {
                filter,
                setSelectedFilters,
                editFilter,
                isLast: index === filters.length - 1
              },
              `${index}`
            ))
          }
        )
      ]
    }
  );
};
const FilterDisplay = ({ filter, setSelectedFilters, editFilter, isLast }) => {
  const { polyglot } = usePolyglot();
  const [isOverFilter, setIsOverFilter] = useState(false);
  const [isOverClose, setIsOverClose] = useState(false);
  function unselectFilter(filter2) {
    if (filter2.disabled) return;
    setSelectedFilters((prev) => {
      const copy = __spreadValues({}, prev);
      const newFilters = copy[filter2.stub][filter2.filterKey].filter(
        (f) => f.value !== filter2.value || f.op !== filter2.op
      );
      if (newFilters.length > 0) {
        copy[filter2.stub][filter2.filterKey] = newFilters;
      } else {
        delete copy[filter2.stub][filter2.filterKey];
      }
      if (Object.keys(copy[filter2.stub]).length === 0) delete copy[filter2.stub];
      return copy;
    });
  }
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          bgcolor: themeColors.Background,
          borderRadius: 1,
          display: "flex",
          flexDirection: "column"
        },
        onMouseEnter: () => setIsOverFilter(true),
        onMouseLeave: () => setIsOverFilter(false),
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", gap: 0.5 }, children: [
            /* @__PURE__ */ jsx(Box, { sx: { p: 0.5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: filter.category }) }),
            isOverFilter && !filter.disabled && /* @__PURE__ */ jsx(
              Box,
              {
                sx: { cursor: "pointer", p: 0.5 },
                onClick: !filter.disabled ? () => unselectFilter(filter) : void 0,
                onMouseEnter: !filter.disabled ? () => setIsOverClose(true) : void 0,
                onMouseLeave: !filter.disabled ? () => setIsOverClose(false) : void 0,
                children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: isOverClose ? themeColors.black : themeColors.Grey }))
              }
            )
          ] }),
          /* @__PURE__ */ jsxs(
            Box,
            {
              onClick: !filter.disabled ? () => editFilter({
                stub: filter.stub,
                filterKey: filter.filterKey,
                op: filter.op,
                value: filter.value,
                type: "edit"
              }) : void 0,
              sx: {
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                p: 0.5,
                cursor: !filter.disabled ? "pointer" : void 0,
                ":hover": {
                  background: themeColors.GreyHover,
                  borderRadius: 1
                }
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getAppliedFilterLabel(filter, polyglot) }),
                filter.missesScopes && /* @__PURE__ */ jsx(ReportsAlertMissingScopes, { tooltip: "This filter is unavailable due to insufficient permissions." })
              ]
            }
          )
        ]
      }
    ),
    !isLast && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-around", gap: 2, px: 3 }, children: [
      /* @__PURE__ */ jsx(Divider, { sx: { flex: 1 } }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "and" }),
      /* @__PURE__ */ jsx(Divider, { sx: { flex: 1 } })
    ] })
  ] });
};
const getDefaultLabel = (filter) => `${filter.label} ${filter.op} ${filter.value}`;
const ThisFilterLabels = {
  ["D" /* Day */]: (filter) => `${filter.label} is today`,
  ["W" /* Week */]: (filter) => `${filter.label} is in this week`,
  ["WsF" /* WeekSoFar */]: (filter) => `${filter.label} is in this week so far`,
  ["M" /* Month */]: (filter) => `${filter.label} is in this month`,
  ["MsF" /* MonthSoFar */]: (filter) => `${filter.label} is in this month so far`,
  ["Q" /* Quarter */]: (filter) => `${filter.label} is in this quarter`,
  ["QsF" /* QuarterSoFar */]: (filter) => `${filter.label} is in this quarter so far`,
  ["Y" /* Year */]: (filter) => `${filter.label} is in this year`,
  ["YsF" /* YearSoFar */]: (filter) => `${filter.label} is in this year so far`
};
const LastFilterLabels = {
  ["D1" /* Last1D */]: (filter) => `${filter.label} is yesterday`,
  ["D2" /* Last2D */]: (filter) => `${filter.label} is in last 2 days`,
  ["D7" /* Last7D */]: (filter) => `${filter.label} is in last 7 days`,
  ["D14" /* Last14D */]: (filter) => `${filter.label} is in last 14 days`,
  ["D30" /* Last30D */]: (filter) => `${filter.label} is in last 30 days`,
  ["D60" /* Last60D */]: (filter) => `${filter.label} is in last 60 days`,
  ["D90" /* Last90D */]: (filter) => `${filter.label} is in last 90 days`,
  ["D365" /* Last365D */]: (filter) => `${filter.label} is in last 365 days`,
  ["W" /* Week */]: (filter) => `${filter.label} is in last week`,
  ["M" /* Month */]: (filter) => `${filter.label} is in last month`,
  ["Q" /* Quarter */]: (filter) => `${filter.label} is in last quarter`,
  ["Y" /* Year */]: (filter) => `${filter.label} is in last year`
};
const NextFilterLabels = {
  ["D1" /* Next1D */]: (filter) => `${filter.label} is tomorrow`,
  ["D2" /* Next2D */]: (filter) => `${filter.label} is in next 2 days`,
  ["D7" /* Next7D */]: (filter) => `${filter.label} is in next 7 days`,
  ["D14" /* Next14D */]: (filter) => `${filter.label} is in next 14 days`,
  ["D30" /* Next30D */]: (filter) => `${filter.label} is in next 30 days`,
  ["D60" /* Next60D */]: (filter) => `${filter.label} is in next 60 days`,
  ["D90" /* Next90D */]: (filter) => `${filter.label} is in next 90 days`,
  ["D365" /* Next365D */]: (filter) => `${filter.label} is in next 365 days`,
  ["W" /* Week */]: (filter) => `${filter.label} is in next week`,
  ["M" /* Month */]: (filter) => `${filter.label} is in next month`,
  ["Q" /* Quarter */]: (filter) => `${filter.label} is in next quarter`,
  ["Y" /* Year */]: (filter) => `${filter.label} is in next year`
};
const AppliedFilterLabelSchema = {
  [ReportSQLOperator.this]: (filter) => {
    if (typeof filter.value !== "string") return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    const labelFunc = ThisFilterLabels[filter.value];
    if (!labelFunc) return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    return labelFunc(filter);
  },
  [ReportSQLOperator.last]: (filter) => {
    if (typeof filter.value !== "string") return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    const labelFunc = LastFilterLabels[filter.value];
    if (!labelFunc) return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    return labelFunc(filter);
  },
  [ReportSQLOperator.next]: (filter) => {
    if (typeof filter.value !== "string") return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    const labelFunc = NextFilterLabels[filter.value];
    if (!labelFunc) return `${filter.label} ${filter.op} ${filter.value.toString()}`;
    return labelFunc(filter);
  },
  [ReportSQLOperator.eq]: (filter) => {
    if ([FilterTypeOption.intOption, FilterTypeOption.stringOption].includes(filter.type) && filter.valueLabels[0])
      return `${filter.label} ${filter.op} ${filter.valueLabels[0]}`;
    return `${filter.label} ${filter.op} ${filter.value}`;
  },
  [ReportSQLOperator.gte]: (filter) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.lte]: (filter) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.gt]: (filter) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.lt]: (filter) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.not]: (filter) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.is]: (filter) => `${filter.label} ${filter.op} ${filter.value}`,
  [ReportSQLOperator.between]: (filter) => `${filter.label} is between ${filter.valueLabels.join(" and ")}`,
  [ReportSQLOperator.isKnown]: (filter) => `${filter.label} is known`,
  [ReportSQLOperator.isUnknown]: (filter) => `${filter.label} is unknown`,
  [ReportSQLOperator.in]: (filter) => {
    if (filter.valueLabels.length === 0) return `${filter.label} is any of (?)`;
    if (filter.valueLabels.length === 1) return `${filter.label} is ${filter.valueLabels[0]}`;
    if (filter.valueLabels.length < 4) return `${filter.label} is any of ${filter.valueLabels.join(", ")}`;
    return `${filter.label} is ${filter.valueLabels.slice(0, 2).join(", ")} or ${filter.valueLabels.length - 2} others`;
  },
  [ReportSQLOperator.contains]: (filter) => {
    if (Array.isArray(filter.value)) {
      const displayS = filter.value.map((v) => `"${v}"`).join(", ");
      if (filter.value.length === 1) return `${filter.label} contains ${displayS}`;
      return `${filter.label} contains any of ${displayS}`;
    }
    return `${filter.label} contains "${filter.value}"`;
  },
  [ReportSQLOperator.notContains]: (filter) => {
    if (Array.isArray(filter.value)) {
      const displayS = filter.value.map((v) => `"${v}"`).join(", ");
      if (filter.value.length === 1) return `${filter.label} does not contain ${displayS}`;
      return `${filter.label} does not contain any of ${displayS}`;
    }
    return `${filter.label} does not contain "${filter.value}"`;
  },
  [ReportSQLOperator.startsWith]: (filter) => `${filter.label} starts with "${filter.value}"`,
  [ReportSQLOperator.endsWith]: (filter) => `${filter.label} ends with ${filter.value}`,
  // DATE PART
  [ReportSQLOperator.dayIs]: (filter) => `${filter.label}'s day is ${Number(filter.value)}`,
  [ReportSQLOperator.monthIs]: (filter, polyglot) => `${filter.label}'s month is ${getMonthName(Number(filter.value), polyglot)}`,
  [ReportSQLOperator.yearIs]: (filter) => `${filter.label}'s year is ${Number(filter.value)}`,
  [ReportSQLOperator.olderThan]: (filter) => `${filter.label} is at least ${filter.valueLabels[1]} day(s) older than ${filter.valueLabels[0]}`,
  [ReportSQLOperator.youngerThan]: (filter) => `${filter.label} is at least ${filter.valueLabels[1]} day(s) younger than ${filter.valueLabels[0]}`
};
function getAppliedFilterLabel(filter, polyglot) {
  var _a;
  const getLabelFunction = (_a = AppliedFilterLabelSchema[filter.op]) != null ? _a : getDefaultLabel;
  return getLabelFunction(filter, polyglot);
}
function getMonthName(monthNumber, polyglot) {
  const months = {
    1: polyglot.t("getMonthOptions.january"),
    2: polyglot.t("getMonthOptions.february"),
    3: polyglot.t("getMonthOptions.march"),
    4: polyglot.t("getMonthOptions.april"),
    5: polyglot.t("getMonthOptions.may"),
    6: polyglot.t("getMonthOptions.june"),
    7: polyglot.t("getMonthOptions.july"),
    8: polyglot.t("getMonthOptions.august"),
    9: polyglot.t("getMonthOptions.september"),
    10: polyglot.t("getMonthOptions.october"),
    11: polyglot.t("getMonthOptions.november"),
    12: polyglot.t("getMonthOptions.december")
  };
  return months[monthNumber] || "Invalid month";
}
