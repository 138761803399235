"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { TrackTimeDrawer } from "@/v2/feature/attendance/company/components/track-time-drawer.component";
export const AttendanceActionItem = ({
  selectedRow,
  refresh,
  onClose,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(
    AttendanceActionItemContent,
    {
      selectedRow,
      refresh,
      onClose,
      afterClose
    }
  );
};
const AttendanceActionItemContent = ({
  selectedRow,
  refresh,
  onClose,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (selectedRow) {
      setIsOpen(true);
    }
  }, [setIsOpen, selectedRow]);
  return /* @__PURE__ */ jsx(
    TrackTimeDrawer,
    {
      isOpen,
      setIsOpen,
      userId: selectedRow.userId,
      refresh: () => __async(void 0, null, function* () {
        return yield refresh();
      }),
      onClose: () => {
        if (onClose) onClose();
        setIsOpen(false);
      },
      afterClose,
      logDate: selectedRow.logDate,
      mode: "view",
      view: "user"
    }
  );
};
