"use strict";
import { useEffect, useRef, useState } from "react";
export const useScrollHandler = () => {
  const containerRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const checkForScroll = () => {
    if (containerRef.current) {
      const isContentOverflowing = containerRef.current.scrollHeight > window.innerHeight;
      setShowScrollButton(isContentOverflowing);
    }
  };
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setShowScrollButton(scrollTop + clientHeight < scrollHeight);
    }
  };
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth"
      });
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    setTimeout(() => checkForScroll(), 1500);
    window.addEventListener("resize", checkForScroll);
    return () => {
      window.removeEventListener("resize", checkForScroll);
    };
  }, []);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  });
  return { containerRef, showScrollButton, scrollToBottom };
};
