"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
export const useInfiniteScroll = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(() => __async(void 0, null, function* () {
    if (!url) return;
    try {
      setLoading(true);
      const response = yield axios.get(url);
      setData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }), [url]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const refresh = useCallback(() => {
    fetchData();
  }, [fetchData]);
  return {
    data,
    loading,
    refresh
  };
};
