"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { isProduction } from "@/v2/infrastructure/firebase/identity-platform.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
const THIRTY_MINUTES_MS = 30 * 60 * 1e3;
const ONE_MINUTE_MS = 60 * 1e3;
export const RefreshIconButton = ({
  effectiveDate = "",
  refreshInterval = THIRTY_MINUTES_MS,
  onClick
}) => {
  const { polyglot } = usePolyglot();
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const title = useMemo(() => {
    if (!isProduction) return polyglot.t("RefreshLabel.unlimitedRefresh");
    return remainingMinutes > 0 ? polyglot.t("RefreshLabel.youCanRefreshInXMinutes", { smart_count: remainingMinutes }) : "";
  }, [remainingMinutes, polyglot]);
  const calculateRemainingMinutes = useCallback((effectiveDate2, refreshInterval2) => {
    const currentTime = (/* @__PURE__ */ new Date()).getTime();
    const effectiveTime = new Date(effectiveDate2).getTime();
    const timeDifference = refreshInterval2 - (currentTime - effectiveTime);
    return Math.round(timeDifference / ONE_MINUTE_MS);
  }, []);
  useEffect(() => {
    if (!isProduction) return;
    const updateRemainingMinutes = () => {
      const newRemainingMinutes = calculateRemainingMinutes(effectiveDate, refreshInterval);
      setRemainingMinutes(newRemainingMinutes);
      if (newRemainingMinutes === 0) clearInterval(intervalId);
    };
    updateRemainingMinutes();
    const intervalId = setInterval(updateRemainingMinutes, ONE_MINUTE_MS);
    return () => clearInterval(intervalId);
  }, [effectiveDate, refreshInterval, calculateRemainingMinutes]);
  return /* @__PURE__ */ jsx(StyledTooltip, { title, children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
    IconButton,
    {
      "aria-label": polyglot.t("RefreshLabel.refresh"),
      sx: __spreadValues({}, tableIconButtonSx),
      disabled: remainingMinutes > 0,
      onClick,
      children: /* @__PURE__ */ jsx(Reload, __spreadValues({}, actionIconSize))
    }
  ) }) });
};
