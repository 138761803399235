"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { Avatar } from "@mui/material";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
const sizes = {
  xxlarge: { width: 135, height: 135 },
  x100: { width: 100, height: 100 },
  xlarge: { width: 80, height: 80 },
  large: { width: 66, height: 66 },
  medium: { width: 40, height: 40 },
  small: { width: 30, height: 30 },
  small28: { width: 28, height: 28 },
  xsmall: { width: 24, height: 24 },
  xxsmall: { width: 20, height: 20 },
  xs16: { width: 16, height: 16 },
  xxxsmall: { width: 14, height: 14 }
};
const fontSizeByAvatarSize = {
  xxlarge: themeFonts.title2,
  x100: themeFonts.title2,
  xlarge: themeFonts.title2,
  large: themeFonts.title2,
  medium: themeFonts.captionSmall,
  small: themeFonts.captionSmall,
  small28: themeFonts.captionSmall,
  xsmall: themeFonts.bodyTiny,
  xs16: themeFonts.bodyTiny,
  xxsmall: themeFonts.bodyTiny,
  xxxsmall: themeFonts.bodyTiny
};
export const CustomAvatarComponent = ({
  title,
  size = "small",
  showTooltip,
  symbol,
  src
}) => {
  const displayText = symbol || title.charAt(0).toUpperCase() || "?";
  const stringToColor = (text) => {
    if (!text) return "white";
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `#${(hash & 16777215 | 1543503872).toString(16).substr(0, 6)}5c`;
  };
  return /* @__PURE__ */ jsx(StyledTooltip, { title, disabled: !showTooltip, children: /* @__PURE__ */ jsx(
    Avatar,
    {
      sx: __spreadProps(__spreadValues({}, sizes[size]), {
        fontSize: fontSizeByAvatarSize[size],
        bgcolor: !src ? stringToColor(title) : "transparent"
      }),
      src,
      children: !src && displayText
    }
  ) });
};
